import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Div from 'components/Div';
import Form from 'components/Form';
import Table from 'components/Table';
import Tag from 'components/Tag';
import Title from 'components/Title';
import { getAuthContext } from 'contexts/auth';
import { getOrderContext } from 'contexts/order';
import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { timeSince } from 'utils/helpers';
import Req from 'utils/Request';
import { tableOrderStatuses } from 'utils/tableModels';
import CompaintPopup from '../Process/CompaintPopup';
import ProcessDetail from '../Process/ProcessDetail';
import SuggestionPopup from '../Process/SuggestionPopup';
import CancelRequestPopup from '../Process/CancelRequestPopup';

import EnquiryPopup from '../Process/EnquiryPopup';
import Box from 'components/Box';
import axios from 'axios';
import CheckStore from './CheckStore';

const DashboardIndex = () => {
    const date = new Date();
    const { custPhone } = React.useContext(getOrderContext);
    const [searchParams] = useSearchParams();
    const [processData, setProcessData] = React.useState([]);
    const [reportData, setReportData] = React.useState([]);
    const [mounted, setMounted] = React.useState(false);
    const [showCheckStore, setShowCheckStore] = React.useState(false);
    const [filters, setFilters] = React.useState({
        phone: custPhone,
        checkSeq: '',
        startDate: `${new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8, 0).toISOString()}`,
        endDate: `${new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59).toISOString()}`,
        gteTotalsTotalDue: 200000,
    });
    const [pagination, setPagination] = React.useState({
        limit: 10,
        offset: 0,
    });
    const [forwardPagination, setForwardPagination] = React.useState({
        size: 10,
        number: 0,
    });
    const [storeInfo, setStoreInfo] = React.useState('');
    const { user } = React.useContext(getAuthContext);
    const [popupInstance, setPopupInstance] = React.useState(null);
    const [complaintInstance, setComplaintInstance] = React.useState(null);
    const [suggestionInstance, setSuggestionInstance] = React.useState(null);
    const [enquiryInstance, setEnquiryInstance] = React.useState(null);
    const [cancelInstance, setCancelInstance] = React.useState(null);
    const showProcess = searchParams.get('showProcess');

    React.useEffect(() => {
        setMounted(true);
        loadOrderStatus();
    }, []);
    React.useEffect(() => {
        if (mounted) {
            setPagination({ limit: 10, offset: 0 });
            setForwardPagination({ size: 10, number: 0 });
        }
    }, [filters]);
    React.useEffect(() => {
        if (user) fetchProcessData();
    }, [user]);
    React.useEffect(() => {
        setForwardPagination({
            size: pagination.limit,
            number: pagination.offset / 10 + 1,
        });
    }, [pagination]);
    React.useEffect(() => {
        if (user) fetchProcessData();
    }, [forwardPagination]);

    const fetchProcessData = async () => {
        if (!forwardPagination.number > 0) return null;
        const result = await Req({
            url: '/order/filterOrders',
            body: { ...filters, page: { ...forwardPagination } },
            hideSuccess: true,
        });
        setProcessData({
            rows: result.data.data.data,
            count: result.data.data.total,
        });
        if (showProcess) {
            const foundOne = result.data.data.data.find((d) => d.checkSeq === showProcess);
            if (foundOne) setPopupInstance({ ...foundOne });
        }
    };
    const loadOrderStatus = async () => {
        const result = await Req({
            url: '/dashboard/get',
            hideSuccess: true,
        });
        setReportData(result.data.data);
    };
    return (
        <Container>
            <Title size={20} style={{ marginBottom: 50 }}>
                Баталгаажуулах
            </Title>
            <Div c="filter_wrap">
                <Form
                    listenChange={setFilters}
                    instance={filters}
                    styles={filterFormStyle}
                    onSubmit={(e) => e.preventDefault()}
                    model={{
                        phone: { label: 'Утас', type: 'Text' },
                        checkNum: { label: 'Захиалгын дугаар', type: 'Text' },
                        startDate: {
                            label: 'Эхлэх огноо',
                            type: 'SelectDatetime',
                        },
                        endDate: {
                            label: 'Дуусах огноо',
                            type: 'SelectDatetime',
                        },
                    }}
                />
                <Button bg="blue" onClick={fetchProcessData}>
                    <AiOutlineSearch />
                    Хайх
                </Button>
            </Div>
            <Table
                data={processData}
                model={{
                    custom2: {
                        label: 'Үүсгэсэн',
                        jsx: ({ thisInstance }) => <Tag color="green">{timeSince(thisInstance.createdAt)}-ийн өмнө</Tag>,
                    },
                    ...tableOrderStatuses,
                    custom: {
                        label: '',
                        sticky: true,
                        jsx: ({ thisInstance }) => (
                            <Button onClick={() => setPopupInstance(thisInstance)} color="black" style={{ marginLeft: 'auto' }}>
                                Явц харах
                            </Button>
                        ),
                    },
                }}
                paginationSetting={pagination}
                setPaginationSetting={setPagination}
            />
            {popupInstance && (
                <Dialog title={`Захиалгын дугаар: ${popupInstance.checkNum}`} onClose={() => setPopupInstance(null)}>
                    <PopupWrapper>
                        <Div c="helper_buttons">
                            <Button
                                bg="blue"
                                onClick={() =>
                                    setSuggestionInstance({
                                        custPhone: popupInstance.custPhone,
                                        orderNumber: popupInstance.checkSeq,
                                        store: storeInfo,
                                        operator: user.username,
                                    })
                                }
                            >
                                Санал бүртгэх
                            </Button>
                            <Button
                                bg="blue"
                                onClick={() =>
                                    setComplaintInstance({
                                        custPhone: popupInstance.custPhone,
                                        orderNumber: popupInstance.checkSeq,
                                        store: storeInfo,
                                        operator: user.username,
                                    })
                                }
                            >
                                Гомдол бүртгэх
                            </Button>
                            <Button
                                bg="green"
                                onClick={() =>
                                    setEnquiryInstance({
                                        custPhone: popupInstance.custPhone,
                                        store: storeInfo,
                                        operator: user.username,
                                    })
                                }
                            >
                                Лавлагаа болгох
                            </Button>
                            <Button
                                bg="color"
                                onClick={() =>
                                    setCancelInstance({
                                        orderNumber: popupInstance.checkSeq,
                                        operator: user.username,
                                        orderTotal: popupInstance.totalsTotalDue,
                                    })
                                }
                            >
                                Захиалга цуцлах хүсэлт
                            </Button>
                        </Div>
                        <ProcessDetail setStoreInfo={setStoreInfo} processId={popupInstance.id} />
                    </PopupWrapper>
                </Dialog>
            )}
            {complaintInstance && <CompaintPopup instance={complaintInstance} setInstance={setComplaintInstance} />}
            {suggestionInstance && <SuggestionPopup instance={suggestionInstance} setInstance={setSuggestionInstance} />}
            {cancelInstance && <CancelRequestPopup instance={cancelInstance} setInstance={setCancelInstance} />}
            {/* // (
            //     <Dialog
            //         onClose={() => setCancelInstance(null)}
            //         title={'Та энэ захиалгыг цуцлахдаа итгэлтэй байна уу?'}
            //         submit={
            //             // <Button onClick={() => formRef.current.querySelector('.submit').click()} bg="green">
            //             //     Тийм
            //             // </Button>
            //             <Button onClick={() => handleCancelRequest()} bg="green">
            //                 Тийм
            //             </Button>
            //         }
            //     >
            //         <Container>
            //             <Form instance={cancelInstance} setInstance={setCancelInstance} onSubmit={handleCancelRequest} forwardRef={formRef} model={{ ...formCancelRequest }} />
            //         </Container>
            //     </Dialog>
            // )
            // } */}
            {enquiryInstance && <EnquiryPopup instance={enquiryInstance} setInstance={setEnquiryInstance} />}
            <div className="d-container">
                <div className="head">
                    <Title size={20}>Өнөөдөр</Title>
                    <Button bg="blue" onClick={() => loadOrderStatus()}>
                        Дахин дуудах
                    </Button>
                    <Button bg="red" onClick={() => setShowCheckStore(true)}>
                        Салбар шалгах
                    </Button>
                </div>
                <div className="box-container">{reportData.length > 0 ? reportData.map((box, index) => (box.storename !== 'PH99-MOCKSTORE2' && box.storename !== 'PH99-MOCKSTORE1' && box.storename !== 'PH16-NALAIKH' &&  <Box key={index} data={box}></Box>)) : ''}</div>
            </div>
            {showCheckStore &&
            <Dialog
                onClose={() => setShowCheckStore(false)}
                title="Салбар шалгах"
                width="600px"
            >
                <CheckStore />
            </Dialog>
            }
        </Container>
    );
};

export default DashboardIndex;

const Container = styled.div`
    .filter_wrap {
        margin-top: 30px;
        display: flex;
        align-items: flex-end;
        gap: 15px;
        margin-bottom: 30px;
    }
    .d-container {
        .head {
            display: flex;
            align-items: baseline;
            margin-bottom: 10px;
            gap: 20px;
            h3 {
                text-transform: uppercase;
            }
        }
    }
    .create_complaint_wrap {
        display: flex;
        & > form {
            flex: 1;
            min-width: 500px;
        }
    }
    .box-container {
        display: grid;
        gap: 20px 20px;
        grid-template-columns: auto auto auto auto;
        padding: 10px;
    }
`;

const filterFormStyle = `
    display:flex;
    gap:15px;
    & > div {
        display:flex;
        flex-direction:column;
        &:nth-child(3){
        }
        & > * {
            &:first-child{
                opacity:0.6;
                display:block;
                font-size:10px;
                margin-bottom:6px;
            }
            &:last-child{
            }
        }
    }
`;

const PopupWrapper = styled.div`
    .helper_buttons {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        margin-top: 50px;
        margin-bottom: 15px;
    }
`;
