import SelectHoc from 'contexts/SelectHoc';
import React from 'react';

const SelectPayment = ({ value: firstValue, onChange, onChangeClean, ...props }) => {
    const [value, setValue] = React.useState({})
    const constantValues = [
        {value: 'Бэлнээр', label: 'Бэлнээр'},
        {value: 'Картаар', label: 'Картаар'},
        {value: 'Купон', label: 'Купон'},
        {value: 'Данс', label: 'Данс'},
    ]
    const forwardChange = (e) => {
        onChange && onChange(e.value)
        onChangeClean && onChangeClean(e.value)
    }
    React.useEffect(() => {
        const findSelectedValue = constantValues.find(m => m.value === firstValue)
        setValue(findSelectedValue)
    }, [firstValue])
    return (
        <SelectHoc
            value={value}
            onChange={forwardChange}
            options={constantValues}
            {...props}
        />
    );
};

export default SelectPayment;