import axios from 'axios';
import React from 'react'
import { TailSpin } from 'react-loader-spinner'
import { NotificationManager } from 'react-notifications';
import Req from 'utils/Request';
import { getAuthContext } from './auth';

export const getReferenceContext = React.createContext();

export const ReferenceContextProvider = (props) => {

    const header = React.useRef()
    const [isLoading, setIsLoading] = React.useState(false)
    const [headerHeight, setHeaderHeight] = React.useState(54)
    const [translations, setTranslations] = React.useState([])
    const [menuImages, setMenuImages] = React.useState([])
    const {user,jwtToken} = React.useContext(getAuthContext)
    React.useEffect(() => {
        const root = document.querySelector('#root')
        root.addEventListener('startloader', () => {
            setIsLoading(true)
        })
        root.addEventListener('endloader', () => {
            setIsLoading(false)
        })
        root.addEventListener('toastmessage', ({detail}) => {
            const {type, message, title} = detail
            NotificationManager[type](title, message)
        })
        calcHeaderHeight()
    }, [])
    React.useEffect(() => {
        if(user) {
            fetchTranslations()
            fetchMenuImages()
        }
    }, [user])
    const fetchMenuImages = async () => {
        const result = await Req({url: '/menu/getImages', hideSuccess: true})
        setMenuImages(result.data.data)
    }
    const fetchTranslations = async () => {
        const result = await axios.post(window.url.server + `/menu/getTranslations`, {}, {headers: {'Authorization': `Bearer ${jwtToken}`}})
        setTranslations(result.data.data)
    }
    const calcHeaderHeight = () => {
        const target = header.current
        if (target) setHeaderHeight(target.offsetHeight)
    }
    const getTranslation = (argument) => {
        const transList = [...translations]
        const foundOne = transList.find(x => x.en.toLowerCase() === argument.toLowerCase())
        if(foundOne) return foundOne.mn
        else return argument
    }
    return (
        <getReferenceContext.Provider value={{
            header,
            headerHeight,
            setIsLoading,
            translations,
            getTranslation,
            menuImages
        }}>
            {isLoading && <TailSpin
                height="40"
                width="40"
                color="#EA3038"
                ariaLabel="loading-spinner"
                radius="1"
                visible={true}
            />}
            {props.children}
        </getReferenceContext.Provider>
    )

}