import { Auth, Hub } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const getAuthContext = React.createContext();
export const AuthContextProvider = (props) => {
    const [loggedIn, setLoggedIn] = React.useState(false);
    const [user, setUser] = React.useState(null);
    const [allowedModules, setAllowedModules] = React.useState([]);
    const [roles, setRoles] = React.useState([]);
    const [stores, setStores] = React.useState([]);
    const [jwtToken, setJwtToken] = React.useState('');
    const navigate = useNavigate();
    const checkUser = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const groups = user.signInUserSession.accessToken.payload['cognito:groups']?.filter((item) => !item.includes('STORE'));
            const user_stores = user.signInUserSession.accessToken.payload['cognito:groups']?.filter((item) => item.includes('STORE'));
            setRoles(groups);
            setStores(user_stores);
            const {
                accessToken: { jwtToken },
            } = await Auth.currentSession();
            setJwtToken(jwtToken);

            // const jwt = user.signInUserSession.accessToken.jwtToken;
            // localStorage.setItem('phjwt', jwt);
            setUser(user);
            setLoggedIn(true);
        } catch (error) {
            setLoggedIn(false);
            navigate('/login');
        }
    };

    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            console.log(event, data);
            switch (event) {
                case 'signIn':
                    console.log('asd');
                    // setUser(data);
                    break;
                case 'signOut':
                    // setUser(null);
                    break;
                case 'customOAuthState':
                    break;
                // setCustomState(data);
                default:
                    break;
            }
        });
        checkUser();
    }, []);

    return (
        <getAuthContext.Provider
            value={{
                jwtToken,
                user,
                setUser,
                roles,
                loggedIn,
                setLoggedIn,
                allowedModules,
                setAllowedModules,
                stores,
            }}
        >
            {props.children}
        </getAuthContext.Provider>
    );
};
