import Dialog from 'components/Dialog';
import { getReferenceContext } from 'contexts/reference';
import React from 'react';
import { getDay } from 'utils/date';
import { timeSince } from 'utils/helpers';
import Cardbox from '../Cardbox';
import PizzaPopup from './PizzaPopup';

const PizzaInstance = ({ name, data, isCombo, onChange, checked, disableIncrement }) => {
    const day = getDay()
    const inst = day === 2 ?
    data.items.filter((item) => item.subLevelsHex === '40'
     ? item.subLevelsHex === '40' : item.subLevelsHex !== '40' 
     && item.pizzaBase !== 'THN'  
     )
    : data.items.filter((item) =>item.subLevelsHex !== '40'
     && item.pizzaBase !== 'THN'
     )
    const instances = [...inst].sort((a, b) => a?.price > b?.price ? 1 : -1);
    const startingPrice = instances.length > 0 ? instances[0].price : 'Тодорхойгүй'
    const [showDialog, setShowDialog] = React.useState(false);
    const { getTranslation } = React.useContext(getReferenceContext)

    return (
        <>
            <Cardbox
                name={name}
                price={startingPrice}
                img="pizza"
                onClick={() => setShowDialog(true)}
                isCombo={isCombo}
                checked={checked}
                desc={data.longDescriptor_stringText}
            />
            {showDialog && (
                <Dialog
                    // hideTitle
                    title={getTranslation(name)}
                    hideFooter
                    onClose={() => setShowDialog(false)}
                >
                    <PizzaPopup
                        disableIncrement={disableIncrement}
                        name={name}
                        data={data}
                        isCombo={isCombo}
                        onChange={(v) => {
                            onChange(v);
                            setShowDialog(false);
                        }}
                    />
                </Dialog>
            )}
        </>
    );
};

export default PizzaInstance;

// const tossPizza = () => {
//     for (let i = 0; i < count; i++) {
//         setTimeout(() => {
//             tossToCart(imgRef.current)
//         }, i * 400);
//     }
//     setTimeout(() => {
//         NotificationManager.success(`Таны сагсанд ${count} ширхэг пицца нэмэгдлээ`, 'Амжилттай', 3000)
//     }, (count * 450));
//     setTimeout(() => {
//         setShowDialog(false)
//     }, (count * 450) + 500);
// }
