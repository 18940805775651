import { getReferenceContext } from 'contexts/reference';
import React from 'react';
import styled from 'styled-components';
import Div from 'components/Div';
import { getOrderContext } from 'contexts/order';
import { AiFillClockCircle } from 'react-icons/ai';
import { FaCartPlus, FaPhoneVolume } from 'react-icons/fa';
import Button from 'components/Button';
import Address from './Address';
import Timer from './Timer';
import Cart from './Cart';
import { formatNumber } from 'utils/vanillaFunctions';
import Dialog from 'components/Dialog';
import PlaceOrder from './PlaceOrder';
import { NotificationManager } from 'react-notifications';
import Tag from 'components/Tag';

const OrderPanel = () => {
    const { headerHeight } = React.useContext(getReferenceContext);
    const { custPhone, checkoutInfo, address, menus, checkOrder, setCheckOrder, helpMessage, storeName } = React.useContext(getOrderContext);
    const [showOrder, setShowOrder] = React.useState(false)
    const handleSubmit = () => {
        if(!address) return NotificationManager.warning('Та хаягийн мэдээллээ шалгана уу.', '', 3000)
        setShowOrder(true)
    }
    return (
        <Container headerHeight={headerHeight}>
            <Div c="top-wrap">
                <Div c="phone">
                    <FaPhoneVolume />
                    {custPhone}
                </Div>
                <Div c="countup">
                    <AiFillClockCircle />
                    <Timer />
                </Div>
            </Div>
            <Div c="help-message">
                {storeName ? <Tag color="green" style={{ padding: '5px 10px' }}>{storeName}</Tag> : '' }
            </Div>
            <Div c="help-message">
                {helpMessage}
            </Div>
            <Address />
            <Cart />
            <Div c="btm-wrap">
                <Div c="total-wrap">
                    <Div c="each">
                        <Div c="caption">Дүн:</Div>
                        <Div c="total">{checkoutInfo ? formatNumber(checkoutInfo.totalsSubTotal) : 0}₮</Div>
                    </Div>
                    <Div c="each">
                        <Div c="caption">Хүргэлтийн төлбөр:</Div>
                        <Div c="total">{checkoutInfo ? formatNumber(checkoutInfo.totalsOtherTotals) : 0}₮</Div>
                    </Div>
                    <Div c="each highlight">
                        <Div c="caption">Нийт дүн:</Div>
                        <Div c="total">{checkoutInfo ? formatNumber(checkoutInfo.totalsTotalDue) : 0}₮</Div>
                    </Div>
                </Div>
                <Button disabled={checkoutInfo ? false : true} onClick={handleSubmit}>Захиалах</Button>
            </Div>
            {showOrder && <Dialog submit={
                <Button className="checkOrderBtn" bg="green">{checkOrder ? 'Захиалга илгээх' : 'Захиалга шалгах'}</Button>
            } title={'Захиалга баталгаажуулах'} onClose={()=>{setShowOrder(false);setCheckOrder(false)}}>
                <PlaceOrder setShowOrder={setShowOrder}/>
            </Dialog>}
        </Container>
    );
};

export default OrderPanel;

const Container = styled.div`
    max-width: 290px;
    min-width: 290px;
    height: 100%;
    height: calc(100vh - ${({ headerHeight }) => headerHeight}px);
    box-shadow: -3px 0px 12px 3px rgba(0, 0, 0, 0.1);
    background: #efefef80;
    padding: 30px 15px;
    display: flex;
    flex-direction: column;
    .help-message {
        margin-bottom:10px
    }
    .top-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        .phone {
            font-size: 22px;
            display: flex;
            align-items: center;
            svg {
                margin-top: -2px;
            }
        }
        .countup {
            font-size: 18px;
            display: flex;
            align-items: center;
            gap: 6px;
            color: ${({ theme }) => theme.color};
            svg {
                margin-top: -2px;
            }
        }
    }
    .btm-wrap {
        margin-top: auto;
        .total-wrap {
            display:flex;
            flex-direction:column;
            gap:10px;
            & > .each {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                font-weight:400;
                &.highlight {
                    font-weight:bold;
                    font-size:16px;
                    margin-bottom:16px;
                }
            }
        }
        button {
            width: 100%;
            background: ${({ theme }) => theme.green};
            color: white;
        }
    }
`;
