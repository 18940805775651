import React from 'react';
import styledComponents from 'styled-components';
import { BsKeyFill } from 'react-icons/bs';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import Register from './__Register';
import { getAuthContext } from 'contexts/auth';
import LoginComponent from './__Login';
import ForceUpdatePass from './__ForceUpdatePass';
import { getReferenceContext } from 'contexts/reference';
import { getSocketContext } from 'contexts/socket';

const Signin = () => {
    const navigate = useNavigate();
    const { loggedIn, setLoggedIn, setUser } = React.useContext(getAuthContext);
    const { getUserToken } = React.useContext(getSocketContext);
    const { setIsLoading } = React.useContext(getReferenceContext);

    const [type, setType] = React.useState('login');
    const [email, setEmail] = React.useState('');
    const [currentUser, setCurrentUser] = React.useState('');
    const [defaultPassword, setDefaultPassword] = React.useState('');
    if (loggedIn) navigate('/');
    const handleSubmit = async (data) => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            NotificationManager.warning(`хэрэглэгч аль хэдийн нэвтэрсэн байна`, `${user.username}`, 5000);
            // window.location.href = '/';
            navigate('/');
        } catch (error) {
            console.log(error);
            setIsLoading(true);
            setEmail(data.email);
            try {
                const loginUser = await Auth.signIn(data.email, data.password);
                setCurrentUser(loginUser);
                getUserToken();
                if (loginUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    // const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
                    setDefaultPassword(data.password);
                    setType('newPass');
                    setIsLoading(false);
                } else {
                    setUser(loginUser);
                    setLoggedIn(true);
                    setIsLoading(false);
                    // socket.send('afoo');
                    navigate('/');
                    // other situations
                }
            } catch (e) {
                setIsLoading(false);
                switch (e.name) {
                    case 'UserNotConfirmedException':
                        try {
                            await Auth.resendSignUp(email);
                            console.log('code resent successfully');
                            navigate('/confirm');
                        } catch (err) {
                            NotificationManager.error(err.message, 'Error', 3000);
                            console.log('error resending code: ', err);
                        }
                    // return<Register confirmEmail={email} type={'confirmSignUp'} />;
                    default:
                        break;
                }
                NotificationManager.error(e.message, 'Error', 3000);
            }
        }
    };
    return type === 'login' ? (
        <LoginComponent handleSubmit={handleSubmit} />
    ) : type === 'newPass' ? (
        <ForceUpdatePass defaultPassword={defaultPassword} email={email} user={currentUser} setLoggedIn={setLoggedIn} />
    ) : (
        <></>
    );
};

export default Signin;

const Container = styledComponents.form`
    display:flex;
    height:100vh;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:300px;
    margin:auto;
    h2 {
      margin:15px 0;
    }
    img{
        width:100px;
    }
    .forgot_wrap{
        font-size:12px;
        text-align:center;
        margin-top:10px;
        color: ${({ theme }) => theme.lightblue};
        text-decoration:underline;
        cursor:pointer;
    }
    input[type="text"],input[type="password"]{
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 10px 14px;
        outline: none;
        transition: all 0.3s ease 0s;
        border-radius: 4px;
        box-sizing: border-box;
        text-overflow: ellipsis;
        margin-bottom:10px;
        width:100%;
        font-size:13px;
        &:focus{
            border-color: rgba(0, 0, 0, 0.8);
            box-shadow: rgba(0 0 0 / 25%) 0px 0px 0px 2px;
        }
    }
    button{
        display:flex;
        align-items:center;
        justify-content:center;
        svg{
            font-size:18px;
            margin-right:5px;
        }
        text-transform:capitalize;
        padding:10px 16px;
        background:${({ theme }) => theme.color};
        color:white;
        border:none;
        border-radius:6px;
        font-weight:500;
        position:relative;
        overflow:hidden;
        cursor:pointer;
        width:100%;
    }
    button[type="button"]{
        background:${({ theme }) => theme.dark};
        margin-top:5px;
    }
`;
