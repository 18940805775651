import React from 'react';
import styled from 'styled-components';

const Label = ({ children, ...props }) => {
    return (
        <Container {...props}>{children}</Container>
    );
};

export default Label;

const Container = styled.label`
    display:block;
    margin-bottom:10px;
    ${({inline})=>inline && `display:inline-block`};
    ${({transform}) => transform && `text-transform:uppercase`};
    ${({min}) => min && `min-width:${min}px`};
    ${({right}) => right && `text-align:right`};
`