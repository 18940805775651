import { getAuthContext } from 'contexts/auth';
import SelectHoc from 'contexts/SelectHoc';
import React from 'react';
import Req from 'utils/Request';

const SelectKhoroo = ({ value: firstValue, district, onChange, onChangeClean, ...props }) => {
    const [data, setData] = React.useState([])
    const [value, setValue] = React.useState({})
    const {user} = React.useContext(getAuthContext)
    React.useEffect(() => {
        if(user) {
            if (district) fetchData()
            else {setData([]);setValue('')}   
        }
    }, [district, firstValue, user])
    const fetchData = async () => {
        const result = await Req({
            url: `/getHoroo?district=${district}`,
            type: 2,
            method: 'get',
            hideSuccess: true
        })
        const mappedResult = result.data.data.map(d => ({ value: d.key, label: `${d.key} (${d.doc_count})` }))
        const findSelectedValue = mappedResult.find(m => m.value === firstValue)
        setData(mappedResult)
        if (findSelectedValue) {
            setValue(findSelectedValue)
        } else {
            setValue('')
        }
    }
    const forwardChange = (e) => {
        onChange && onChange(e)
        onChangeClean && onChangeClean(e?.value)
    }
    return (
        <SelectHoc value={value} onChange={forwardChange} options={data} {...props} />
    );
};

export default SelectKhoroo;