import React from 'react'

export const getMediaContext = React.createContext();

export const MediaContextProvider = (props) => {
    return (
        <getMediaContext.Provider value={{
        }}>
            {props.children}
        </getMediaContext.Provider>
    )
}