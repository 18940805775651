import axios from 'axios';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Form from 'components/Form';
import Table from 'components/Table';
import React from 'react';
import styled from 'styled-components';
import { tableStorePod } from '../../utils/tableModels'
import { formEditStorePod } from 'utils/formModels';
import { popupFormStyle } from 'utils/sharedStyles';
import Title from 'components/Title';
import { AiOutlineEdit } from 'react-icons/ai';
import { getReferenceContext } from 'contexts/reference';
import Req from 'utils/Request';

const StorePod = () => {
    const [data, setData] = React.useState([])
    const { setIsLoading } = React.useContext(getReferenceContext)
    const [selectedInstance, setSelectedInstance] = React.useState(null)
    const formRef = React.useRef()
    React.useEffect(() => {
        fetch()
    }, [])
    const fetch = async () => {
        try {
            setIsLoading(true)
            // const jwt = localStorage.getItem('phjwt')
            const result = await Req({ url: '/store/storePod/list', hideSuccess: true });
            // const result = await axios(`${window.url.server}/store/storePod/list`, {headers: {'Authorization': `Bearer ${jwt}`}})
            setData({ rows: result.data.data, count: 0 })
        } catch (e) { }
        finally { setIsLoading(false) }
    }
    const handleSubmit = async (e) => {
        e?.preventDefault()
        try {
            setIsLoading(true)
            // const jwt = localStorage.getItem('phjwt')
            // await axios.put(`${window.url.server}/store/storePod/update`, { ...selectedInstance }, {headers: {'Authorization': `Bearer ${jwt}`}})
            await Req({ url: '/store/storePod/update', body:selectedInstance,  hideSuccess: true });
            setSelectedInstance(null)
            fetch()
        } catch (e) { }
        finally { setIsLoading(false) }
    }
    return (
        <Container>
            <Title btmLine size={20}>Под</Title>
            <Table data={data} model={{
                ...tableStorePod, custom: {
                    label: '',
                    sticky: true,
                    jsx: ({ thisInstance }) => (
                        <Button bg="green" color="white" onClick={() => setSelectedInstance(thisInstance)}><AiOutlineEdit /> Засах</Button>
                    )
                }
            }} />
            {selectedInstance &&
                <Dialog
                    title="Под засах"
                    onClose={() => setSelectedInstance(null)}
                    width="500px"
                    submit={<Button onClick={() => formRef.current.querySelector('.submit').click()} bg="blue" type="submit">Хадгалах</Button>}
                >
                    <Form
                        forwardRef={formRef}
                        model={formEditStorePod}
                        styles={popupFormStyle}
                        instance={selectedInstance}
                        onSubmit={handleSubmit}
                        listenChange={setSelectedInstance}
                    />
                </Dialog>
            }
        </Container>
    );
};

export default StorePod;

const Container = styled.div`

`