import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import styled from 'styled-components';

const RichtextHoc = ({ onChange, ...props }) => {
    function getBase64(file, cb) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          cb(reader.result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
     }
     
    const handleImage = (e, editor) => {
        getBase64(e.target.files[0], (base64) => {
            const currentData = editor.data.get()
            editor.data.set(currentData + `<img src="${base64}"/>`)
        })
        
    }
    const injectUpload = (editor, d) => {
        const input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.addEventListener('change', (e)=>handleImage(e, editor))
        const ckedit = document.querySelector('.ck-editor')
        const wrapper = ckedit.querySelector('.ck-file-dialog-button')
        wrapper.innerHTML = ''
        wrapper.appendChild(input)
    }
    return (
        <Container>
            <CKEditor
                {...props}
                editor={ClassicEditor}
                onReady={(editor, d) => {
                    injectUpload(editor, d)
                    // console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    onChange(data)
                }}
                data={props.value ?? ''}
            />
        </Container>
    );
};

export default RichtextHoc;

const Container = styled.div`
    .ck{
        font-family:inherit;
        .ck-toolbar{
            border-top-left-radius:4px !important;
            border-top-right-radius:4px !important;
        }
        .ck-content{
            border-bottom-left-radius:4px !important;
            border-bottom-right-radius:4px !important;
            padding-left:12px;
            padding-right:12px;
        }
    }
`