let productStack = 0;
export const addRipple = (e) => {
    const target = e.target;
    const circle = document.createElement('span');
    const diameter = Math.max(target.clientWidth, target.clientHeight);
    const radius = diameter / 2;
    const clientRect = target.getBoundingClientRect();

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${e.clientX - (clientRect.left + radius)}px`;
    circle.style.top = `${e.clientY - (clientRect.top + radius)}px`;
    circle.classList.add('ripple');

    const ripple = target.getElementsByClassName('ripple')[0];

    if (ripple) {
        ripple.remove();
    }

    target.appendChild(circle);
};
export const tossToCart = (target) => {
    productStack = productStack + 10;
    const clone = target.cloneNode(true);
    const rect = target.getBoundingClientRect();
    clone.style.position = 'fixed';
    clone.style.maxWidth = rect.width + 'px';
    clone.style.minWidth = rect.width + 'px';
    clone.style.maxHeight = rect.height + 'px';
    clone.style.minHeight = rect.height + 'px';
    clone.style.left = rect.left + 'px';
    clone.style.top = rect.top + 'px';
    clone.style.zIndex = '1000';
    clone.style.transition = '0.7s cubic-bezier(0.8, -0.26, 0, 0.4)';

    const menuWrap = document.querySelector('#cartWrap');

    setTimeout(() => {
        clone.style.left = `${menuWrap.offsetLeft}px`;
        clone.style.top = `${menuWrap.offsetTop - 25}px`;
        clone.style.scale = '0.4';
    }, 100);
    setTimeout(() => {
        clone.style.transition = '0.15s ease';
        clone.style.scale = '0';
    }, 700);
    target.parentElement.append(clone);
};

export const formatNumber = (num) => {
    try {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } catch (e) {
        return num;
    }
};

export const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const deepEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
};

const isObject = (object) => {
    return object != null && typeof object === 'object';
};
