import NewDateHoc from 'contexts/NewDateHoc';
import React from 'react';
import dayjs from 'dayjs';

const SelectDatetime = ({ value: firstValue, onChange, onChangeClean, ...props }) => {
    const [value, setValue] = React.useState(firstValue ? dayjs(firstValue,'YYYY-MM-DD HH:mm' ) : null)
    const forwardChange = (value, dateString) => {
        onChange && onChange(value?.$d ?? '')
        onChangeClean && onChangeClean(value?.$d ?? '')
        setValue(value ? dayjs(value.$d) : '')
    }
    return (
        <NewDateHoc
            value={value}
            onChange={forwardChange}
            format="YYYY-MM-DD HH:mm"
            showTime={{ format: 'HH:mm' }}
            {...props}
        />
    );
};

export default SelectDatetime;