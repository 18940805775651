import React from 'react';
import { FormatDateFull } from 'utils/date';

const Datetime = ({value, ...props}) => {
    return (
        <>
        <td {...props}>
            {FormatDateFull(value)}
        </td>
        </>
    );
};

export default Datetime;
