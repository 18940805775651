import Title from 'components/Title';
import React from 'react';
import styled from 'styled-components';
import { HiChevronDown } from 'react-icons/hi';
import Req from 'utils/Request';
import { getAuthContext } from 'contexts/auth';
import { AiOutlineEdit, AiOutlinePlus, AiOutlineSave } from 'react-icons/ai';
import Div from 'components/Div';
import Button from 'components/Button';
import { formComplaintCategory } from 'utils/formModels';
import { popupFormStyle } from 'utils/sharedStyles';
import Dialog from 'components/Dialog';
import Form from 'components/Form';
import { NotificationManager } from 'react-notifications';

const ComplaintCategory = ({ onChange }) => {
    const [manager, setManager] = React.useState(null);
    const [categories, setCategories] = React.useState([]);
    const [indices, setIndices] = React.useState('');
    const [categoryObject, setCategoryObject] = React.useState(null);
    const [editCategory, setEditCategory] = React.useState(null);
    const [deleteCategory, setDeleteCategory] = React.useState(null);
    const [showCreate, setShowCreate] = React.useState(false);
    const [createCategoryInstance, setCreateCategoryInstance] = React.useState({});

    const { roles } = React.useContext(getAuthContext);
    const formRef = React.useRef();

    React.useEffect(() => {
        fetchCategory();
    }, []);
    React.useEffect(() => {
        const filtered = roles.filter((x) => x === 'INFO');
        if (filtered[0]) setManager(filtered[0]);
    }, [roles]);
    const fetchCategory = async () => {
        const result = await Req({ url: '/complaint/category/list', hideSuccess: true });
        const sortedData = result.data.data.complaintCategory.rows?.sort((a, b) => a.level - b.level);
        setCategories(sortedData);
    };
    React.useEffect(() => {
        resolveIndices();
    }, [indices]);
    const resolveIndices = () => {
        if (!indices) return setCategoryObject(null);
        const indexes = indices.split('.');
        if (indexes.length === 2)
            setCategoryObject({
                category: categories[indexes[0]].category,
                level: categories[indexes[0]].level,
                childCategory: categories[indexes[1]].category,
                childLevel: categories[indexes[1]].level,
            });
        else if (indexes.length === 1)
            setCategoryObject({
                category: categories[indexes[0]].category,
                level: categories[indexes[0]].level,
            });
    };
    const handleCreate = async (e) => {
        e?.preventDefault();
        const body = {
            ...createCategoryInstance,
        };
        await Req({ url: '/complaint/category/create', body });
        fetchCategory();
        setShowCreate(false);
    };
    const handleUpdateSave = async (e) => {
        e?.preventDefault();
        if (!editCategory.name || editCategory.name === '') return NotificationManager.warning('Төрөлөө оруулна уу', '', 3000);
        if (!editCategory.level || editCategory.level === '') return NotificationManager.warning('Түвшин оруулна уу.', '', 3000);
        console.log(editCategory);
        const body = { id: editCategory.id, name: editCategory.name, level: editCategory.level };
        await Req({ url: '/complaint/category/update', body });
        fetchCategory();
        setEditCategory(null);
    };
    const handleDelete = async () => {
        if (!deleteCategory) return NotificationManager.warning('Устгах төрөлөө сонгоно уу!', '', 3000);
        const body = {
            id: deleteCategory.id,
        };
        const result = await Req({ url: '/complaint/category/delete', body });
        if (result.code === 200 && result.success) {
            return NotificationManager.success('Амжилттай хадгаллаа!', '', 3000);
        }
        setDeleteCategory(null);
        fetchCategory();
    };


    React.useEffect(() => {
        onChange(categoryObject);
    }, [categoryObject]);
    return (
        <Container>
            <Title size={12} btmLine>
                <div className="top">
                    <span>Төрлүүд</span>
                    {manager && (
                        <Button onClick={() => setShowCreate(true)} bg="green" color="white">
                            <AiOutlinePlus />
                            Төрөл үүсгэх
                        </Button>
                    )}
                </div>
            </Title>

            <div className="tree_wrap">
                {categories.map((cat, catIndex) => (
                    <div className="instance_wrap" key={cat.id}>
                        <span data-children={cat.children?.length ? true : false} open={indices === '' + catIndex + ''} onClick={() => setIndices('' + cat.children ? '' : catIndex + '')}>
                            {cat.children?.length ? <HiChevronDown /> : ''}
                            {cat.category}
                        </span>
                        {manager && (
                            <Div c="btn_wrap">
                                <Button bg="green" color="white" onClick={() => setEditCategory(cat)}>
                                    Засах
                                </Button>
                                <Button
                                    bg="color"
                                    onClick={() => {
                                        setDeleteCategory({
                                            id: cat.id,
                                        });
                                    }}
                                >
                                    Устгах
                                </Button>
                            </Div>
                        )}

                        {cat?.children.map((child, childIndex) => (
                            <div className="instance_wrap child" key={child.id}>
                                <span open={indices === catIndex + '.' + childIndex} onClick={() => setIndices(catIndex + '.' + childIndex)}>
                                    {child.category}
                                </span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            {manager && showCreate && (
                <Dialog
                    onClose={() => setShowCreate(false)}
                    title="Төрөл үүсгэх"
                    submit={
                        <Button onClick={() => formRef.current.querySelector('.submit').click()} bg="blue" type="submit">
                            Хадгалах
                        </Button>
                    }
                    // width="500px"
                >
                    <Div c="create_category_wrap">
                        <Form
                            forwardRef={formRef}
                            model={formComplaintCategory}
                            instance={createCategoryInstance}
                            styles={popupFormStyle}
                            listenChange={setCreateCategoryInstance}
                            onSubmit={handleCreate}
                        />
                    </Div>
                </Dialog>
            )}
            {manager && editCategory && (
                <Dialog
                    onClose={() => setEditCategory(null)}
                    title="Гомдолын төрөл засах"
                    submit={
                        <Button onClick={() => formRef.current.querySelector('.submit').click()} bg="blue" type="submit">
                            Хадгалах
                        </Button>
                    }
                    // width="500px"
                >
                    <Form forwardRef={formRef} model={formComplaintCategory} instance={editCategory} styles={popupFormStyle} listenChange={setEditCategory} onSubmit={handleUpdateSave} />
                </Dialog>
            )}
            {manager && deleteCategory && (
                <Dialog
                    onClose={() => setDeleteCategory(null)}
                    title={'Энэ төрөлийг устгахдаа итгэлтэй байна уу?'}
                    submit={
                        <Button onClick={() => handleDelete()} bg="green">
                            Тийм
                        </Button>
                    }
                >
                    <Container>
                        <Form instance={deleteCategory} listenChange={setDeleteCategory} onSubmit={handleDelete} forwardRef={formRef} model={{}} />
                    </Container>
                </Dialog>
            )}
        </Container>
    );
};

export default ComplaintCategory;

const Container = styled.div`
    margin-right: 30px;
    padding-right: 30px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    max-height: 70vh;
    overflow: hidden;
    overflow-y: scroll;
    .top {
        display: flex;
        justify-content: space-between;
    }
    .tree_wrap {
        min-width: 285px;
        .instance_wrap {
            span {
                padding: 10px 15px;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin: 5px 0px;
                svg {
                    margin-right: 4px;
                }
                &[open] {
                    background: ${({ theme }) => theme.green};
                    color: white;
                }
                &[data-children='true'] {
                    opacity: 0.6;
                    text-decoration: underline;
                    cursor: default;
                    margin-left: -15px;
                }
            }
        }
        .instance_wrap.child {
            span {
                margin-left: 15px;
            }
        }
    }
    .btn_wrap {
        margin-top: 15px;
        display: flex;
        gap: 10px;
        justify-content: flex-end;
    }
`;
