import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { NotificationManager } from 'react-notifications';
import notifAudio from '../utils/notification.wav';
import configJson from '../config.json';

export const getSocketContext = React.createContext();

export const SocketContextProvider = ({ children, ...props }) => {
    const [token, setToken] = React.useState('');

    let url = configJson.url.aws_socket;
    if (token) {
        url = `${configJson.url.aws_socket}?accessToken=${token}`;
    }
    let socket = new WebSocket(url);
    const getUserToken = async () => {
        const {
            accessToken: { jwtToken },
        } = await Auth.currentSession();
        setToken(jwtToken);
    };
    const playNotifWav = () => {
        const audio = new Audio(notifAudio);
        audio.play();
    };
    const handleMessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.action === 'notifyComplaint') {
            NotificationManager.info('Шинэ гомдол орж ирлээ.', '', 3000);
            playNotifWav();
        }
    };
    const pingDefault = () => {
        socket.send(JSON.stringify({ action: 'ping', data: 'Connection renewal' }));
    };
    const renewConnection = () => {
        getUserToken();
        socket = new WebSocket(`${configJson.url.aws_socket}?accessToken=${token}`);
        console.log('renewed connection');
    };
    useEffect(() => {
        getUserToken();
        if (socket) {
            socket.addEventListener('message', handleMessage);
            socket.addEventListener('close', () => {
                console.log('connection lost');
                renewConnection();
            });
            const interval = setInterval(pingDefault, 9 * 60 * 1000);
            return () => {
                socket.removeEventListener('message', handleMessage);
                clearInterval(interval)
            };
        }
    }, [socket]);

    return (
        <getSocketContext.Provider
            value={{
                socket,
                getUserToken,
            }}
        >
            <audio src="../utils/notification.wav"></audio>
            {children}
        </getSocketContext.Provider>
    );
};
