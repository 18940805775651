import Table from 'components/Table';
import React, { useEffect } from 'react';
import { tableProcess } from 'utils/tableModels';
import sampleData from 'utils/order.json';
import Button from 'components/Button';
import Medialibrary from 'components/Medialibrary';
import { Richtext } from 'components/Form/InputTypes';
import { useNavigate } from 'react-router-dom';

const Index = () => {
    const model = tableProcess
    const [showMedialibrary, setShowMedialibrary] = React.useState(false)
    const [val, setVal] = React.useState('')
    const navigate = useNavigate()
    const orderByColumnCb = (sortArg) => new Promise((resolve, reject) => {
        // YOUR NETWORK REQUEST LOGIC HERE

        // IF PROMISE RESOLVES, TRIGGER WILL GO UP
        // resolve()

        // IF PROMISE REJECTS, TRIGGER WON'T CHANGE
        reject()
    })
    React.useEffect(() => {
        console.log(val, '?')
    }, [val])
    React.useEffect(() => {
        navigate('/main')
    })
    return (
        <React.Fragment>
            <Button bg="blue" color="white" onClick={() => setShowMedialibrary(true)}>Зураг оруулах</Button>
            <br />
            <Table bordered orderByColumnCb={orderByColumnCb} data={sampleData} model={model} />
            {showMedialibrary && <Medialibrary onClose={() => setShowMedialibrary(false)} />}
            <Richtext value={val} onChange={(v) => setVal(v)} />
        </React.Fragment>
    );
};

export default Index;
