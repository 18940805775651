import React from 'react'

export const getOrderContext = React.createContext();

export const OrderContextProvider = ({ children, custPhone, ...props }) => {
    const customerMode = custPhone ? true : false
    const [address, setAddress] = React.useState(null)
    const [helpMessage, setHelpMessage] = React.useState(null)
    const [storeName, setStoreName] = React.useState(null)
    const [menus, setMenus] = React.useState([])
    const [checkoutInfo, setCheckoutInfo] = React.useState(null)
    const [checkOrder, setCheckOrder] = React.useState(false)
    const elapsedTime = React.useRef()
    return (
        <getOrderContext.Provider value={{
            custPhone,
            customerMode,
            storeName,
            setStoreName,
            helpMessage,
            setHelpMessage,
            address,
            setAddress,
            menus,
            setMenus,
            checkoutInfo,
            setCheckoutInfo,
            elapsedTime,
            checkOrder,
            setCheckOrder
        }}>
            {children}
        </getOrderContext.Provider>
    )
}
