import axios from 'axios';
import Button from 'components/Button';
import Div from 'components/Div';
import Form from 'components/Form';
import { Text } from 'components/Form/InputTypes';
import Label from 'components/Label';
import Paper from 'components/Paper';
import SwitchButton from 'components/SwitchButton';
import Tag from 'components/Tag';
import Title from 'components/Title';
import { getAuthContext } from 'contexts/auth';
import { getOrderContext } from 'contexts/order';
import { getReferenceContext } from 'contexts/reference';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import errorDetail from 'utils/helpers';
import Req from 'utils/Request';
import PrevAddressList from './PrevAddressList';

const modelAddress = {
    city: { label: 'Хот', type: 'SelectCity' },
    district: { label: 'Дүүрэг', type: 'SelectDistrict' },
    horoo: { label: 'Хороо', type: 'SelectKhoroo' },
    address: { label: 'ХаягХаяг', type: 'SelectAddress' },
};
const modelDetailAddress = {
    orts: { label: 'Орц', type: 'Text', required: true },
    ortsKod: { label: 'Орцны код', type: 'Text', required: true },
    haalga: { label: 'Хаалга', type: 'Text', required: true },
    additional: { label: 'Нэмэлт тэмдэглэл', type: 'Textarea' },
};

const CallPage = () => {
    const { address: addressCtx, setAddress, setMenus, setHelpMessage, setStoreName } = React.useContext(getOrderContext);
    const { user } = React.useContext(getAuthContext);
    const [searchParams] = useSearchParams();
    const pickupCases = ['Хүргэлтээр', 'Очиж авах'];
    const [pickupCase, setPickupCase] = React.useState(pickupCases[0]);
    const momentCases = ['Одоо', 'Урьдчилсан'];
    const [momentCase, setMomentCase] = React.useState(momentCases[0]);
    const [deliveryInstance, setDeliveryInstance] = React.useState(addressCtx?.deliveryRaw ?? {});
    const [pickupInstance, setPickupInstance] = React.useState(addressCtx?.pickupRaw ?? {});
    const [pickupAddress, setPickupAddress] = React.useState(null);
    const [addressDetail, setAddressDetail] = React.useState(null);
    const { setIsLoading } = React.useContext(getReferenceContext);
    const [storeData, setStoreData] = React.useState(null);
    const detailFormRef = React.useRef();
    const [redirectTime, setRedirectTime] = React.useState(null);
    const [contextualAddress, setContextualAddress] = React.useState({
        haveDelivery: true,
        havePickup: false,
    });
    const rerouted = searchParams.get('rerouted') === 'true' ? true : false;
    const [mounted, setMounted] = React.useState(false);

    React.useEffect(() => {
        setMounted(true);
        //set current context state to this internal state
        if (addressCtx) {
            if (addressCtx.haveDelivery) {
                setPickupCase('Хүргэлтээр');
                defineAddressDetail();
            }
            if (addressCtx.havePickup) {
                setPickupCase('Очиж авах');
            }
        }
        if (rerouted) {
            NotificationManager.warning('Бүтээгдэхүүн захиалгын өмнө хаягийн мэдээлэл оруулна уу.', '', 3000);
        }
    }, []);

    React.useEffect(() => {
        if (mounted) defineAddressDetail();
    }, [deliveryInstance.address, pickupInstance.store]);

    //WATCH MOMENT CASE
    React.useEffect(() => {
        setRedirectTime(null);
    }, [momentCase]);

    //WATCH PICKUP CASE
    React.useEffect(() => {
        setPickupInstance({});
        setPickupAddress(null);
        if (pickupCase === 'Хүргэлтээр') {
            setMomentCase('Одоо');
            setDeliveryInstance({});
            setAddressDetail(null);
            setStoreData(null);
            setContextualAddress({ ...contextualAddress, haveDelivery: true, havePickup: false });
        } else {
            setMomentCase('Урьдчилсан');
            setDeliveryInstance({});
            setAddressDetail(null);
            setStoreData(null);
            setContextualAddress({ ...contextualAddress, haveDelivery: false, havePickup: true });
        }
    }, [pickupCase]);

    React.useEffect(() => {
        if (contextualAddress.date) handleSubmit();
    }, [contextualAddress.date]);
    //WATCH PICKUP TIME
    React.useEffect(() => {
        setContextualAddress((prev) => ({ ...prev, date: redirectTime }));
    }, [redirectTime]);

    const defineAddressDetail = async () => {
        try {
            const body = { date: contextualAddress.redirectTime ?? redirectTime };
            if (contextualAddress.haveDelivery) {
                if (!deliveryInstance.address) return NotificationManager.warning('Та хаягийн мэдээлэлээ шалгана уу.', '', 3000);
                const detail = await JSON.parse(deliveryInstance.address);
                body.orderType = 'delivery';
                body.podCode = detail.podph;
                setAddressDetail(detail);
            } else {
                body.orderType = 'pickup';
                body.storeName = pickupInstance.store;
                setAddressDetail(null);
            }
            // if(body.date) body.date = new Date(body.date)
            // else delete body.date
            const result = await Req({ url: '/store/schedule/check', body, hideSuccess: true });
            setStoreData({ quickMessage: result.data.data.quickMessage, storeName: result.data.data.storeName });
        } catch (e) {
            setStoreData(null);
        }
    };
    const handleSubmit = async (e) => {
        e?.preventDefault();
        if (!storeData) return NotificationManager.warning('Салбарын мэдээлэл ирээгүй байна.', '', 3000);
        if (!contextualAddress.date && momentCase !== 'Одоо') return NotificationManager.warning('Цагаа сонгоно уу.', '', 3000);
        let detail;
        if (contextualAddress.haveDelivery) detail = JSON.parse(deliveryInstance.address);
        const aggregatedAddress = {
            ...(contextualAddress.haveDelivery ? deliveryInstance : pickupInstance),
            ...contextualAddress,
            ...storeData,
            detail,
            detailRaw: deliveryInstance.address,
            contextualAddressRaw: { ...contextualAddress },
            deliveryRaw: contextualAddress.haveDelivery ? { ...deliveryInstance } : null,
            pickupRaw: contextualAddress.havePickup ? { ...pickupInstance } : null,
            storeDataRaw: { ...storeData },
        };
        setMenus([]);
        NotificationManager.success('Амжилттай хадгалагдлаа.', '', 3000);
        setAddress(aggregatedAddress);
        setHelpMessage(storeData.quickMessage);
        setStoreName(storeData.storeName);
    };
    return (
        <Container>
            <SwitchButton selected={pickupCase} onChange={setPickupCase} options={pickupCases} />
            <Paper>
                {pickupCase === 'Хүргэлтээр' ? (
                    <>
                        <Title size={16} transform="uppercase">
                            Хаяг мэдээлэл
                        </Title>
                        <Form styles={addressFormStyle} listenChange={(v) => setDeliveryInstance({ ...deliveryInstance, ...v })} model={modelAddress} instance={deliveryInstance} />
                        <Div c="detail_wrap">
                            <Div>
                                <Label>Хаягийн нэр</Label>
                                <Text value={addressDetail?.full_address ?? 'Хайлтаа хийнэ үү'} data-state={storeData ? 'success' : 'danger'} readOnly={true} />
                            </Div>
                            <Div>
                                <Label>Албан бус хаягийн мэдээлэл</Label>
                                <Text value={addressDetail?.bairnote ?? 'Хайлтаа хийнэ үү'} data-state={storeData ? 'success' : 'danger'} readOnly={true} />
                            </Div>
                        </Div>
                        <Form
                            onSubmit={handleSubmit}
                            listenChange={(v) => setDeliveryInstance({ ...deliveryInstance, ...v })}
                            forwardRef={detailFormRef}
                            styles={addressDetailFormStyle}
                            model={modelDetailAddress}
                            instance={deliveryInstance}
                        />
                        <Div c="tags">
                            {addressDetail ? (
                                storeData && (
                                    <>
                                        <Tag color="green" style={{ padding: '5px 10px' }}>
                                            {storeData.storeName}
                                        </Tag>
                                        <Tag color="color" style={{ padding: '5px 10px' }}>
                                            {storeData.quickMessage}
                                        </Tag>
                                    </>
                                )
                            ) : (
                                <Tag color="#010101" style={{ padding: '5px 10px' }}>
                                    Тодорхойгүй
                                </Tag>
                            )}
                        </Div>
                    </>
                ) : (
                    <>
                        <Title size={16} transform="uppercase">
                            САЛБАР СОНГОХ
                        </Title>
                        <Form hideLabel saveAddress={setPickupAddress} listenChange={setPickupInstance} instance={pickupInstance} model={{ store: { label: 'Салбар сонгох', type: 'SelectStore', required: true } }} />
                        {/* <Text value={pickupAddress ? pickupAddress: ''} data-state={'success'} readOnly={true} /> */}
                        {pickupAddress && <Tag color="blue" style={{ padding: '5px 10px', marginTop: '20px' }}>{pickupAddress}</Tag>}
                    </>
                )}
                <Div c="bottom-wrap">
                    {pickupCase === 'Хүргэлтээр' ? (
                        <>
                            <SwitchButton selected={momentCase} onChange={setMomentCase} options={momentCases} />
                            {momentCase === 'Урьдчилсан' && (
                                <Form
                                    listenChange={(v) => setRedirectTime(v.pickDate)}
                                    instance={{}}
                                    model={{ pickDate: { label: '', type: 'SelectDatetime', activeDaysfromNow: 0, reverseDisableDate: true, disableType: 'pick' } }}
                                    hideLabel
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <SwitchButton selected={'Урьдчилсан'} onChange={setMomentCase} options={momentCases} disabled />
                            {momentCase === 'Урьдчилсан' && (
                                <Form
                                    listenChange={(v) => setRedirectTime(v.pickDate)}
                                    instance={{}}
                                    model={{ pickDate: { label: '', type: 'SelectDatetime', activeDaysfromNow: 0, reverseDisableDate: true, disableType: 'pick' } }}
                                    hideLabel
                                />
                            )}
                        </>
                    )}

                    <Button
                        onClick={(e) => {
                            if (contextualAddress.haveDelivery) {
                                detailFormRef.current.querySelector('.submit').click();
                            } else {
                                handleSubmit(e);
                            }
                        }}
                        id="confirm"
                        bg="color"
                        color="white"
                    >
                        Хаяг баталгаажуулах
                    </Button>
                </Div>
            </Paper>
            {pickupCase === 'Хүргэлтээр' ? (
                <Paper>
                    <Title size={16} transform="uppercase">
                        ӨМНӨХ ЗАХИАЛСАН ХАЯГУУД
                    </Title>
                    <PrevAddressList setDeliveryInstance={setDeliveryInstance} />
                </Paper>
            ) : (
                ''
            )}
        </Container>
    );
};

export default CallPage;

const Container = styled.div`
    .tags {
        display: flex;
        gap: 10px;
    }
    .bottom-wrap {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-top: 15px;
        #confirm {
            margin-left: auto;
            padding: 10px 16px;
            font-size: 14px;
        }
    }
    .detail_wrap {
        margin-top: 30px;
        display: flex;
        gap: 15px;
        & > div {
            flex: 1;
            label {
                margin-bottom: 5px;
                display: block;
            }
        }
    }
`;
const addressFormStyle = `
    display:flex;
    gap:15px;
    & > div {
        flex:2;
        &:nth-child(1){
           flex:2;
        }
        &:nth-child(4){
            flex:5;
        }
        & > * {
            &:first-child{
                display:none;
            }
            &:last-child{
            }
        }
    }
`;
const addressDetailFormStyle = `
    display:flex;
    gap:15px;
    margin-top:30px;
    & > div {
        flex:1;
        & > * {
            &:first-child{
                display:block;
                margin-bottom:6px;
                &:before{
                    content: "*";
                    color:red;
                    margin-right:4px;
                }
            }
            &:last-child{
            }
        }
    }
`;
