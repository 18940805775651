import React from 'react';
import { DatePicker } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';

const NewDateHoc = ({ disableType = 'other' ,showToday= false, reverseDisableDate = false, activeDaysfromNow = false, showTime, ...props }) => {
    const defaultTime = () =>{
        const currentTime = dayjs();

        let currentHour = currentTime.hour();
        let currentMinute = currentTime.minute() + 30;
        if(currentMinute > 60) {
            currentHour = currentHour + 1
            currentMinute = currentMinute - 60 
        }
        if(currentMinute < 10 && currentMinute !== 0) {
            currentMinute = 10
        }
        return `${currentHour}:${Math.ceil(currentMinute / 10) * 10}:00`
    }


    const disabledTime = (current) => {
        if (!current) {
          // If no date is selected, all times will be disabled.
          return {
            disabledHours: () => Array.from({ length: 24 }, (_, index) => index),
            disabledMinutes: () => Array.from({ length: 60 }, (_, index) => index),
            disabledSeconds: () => Array.from({ length: 60 }, (_, index) => index),
          };
        }
        const currentTime = dayjs();
        const selectedDate = dayjs(current);

        let currentHour = currentTime.hour();
        let currentMinute = currentTime.minute() + 30;
        if(currentMinute > 60) {
            currentHour = currentHour + 1
            currentMinute = currentMinute - 60 
        }

        if(currentMinute < 10 && currentMinute !== 0) {
            currentMinute = 10
        }
        let disabledTimes= {};
        if(selectedDate.isAfter(currentTime, 'day')) {
            disabledTimes = {
                disabledHours: () => [],
                disabledMinutes: () => [],
                disabledSeconds: () => [],
            }
        } else {
            disabledTimes = {
                disabledHours: () => Array.from({ length: currentHour }, (_, index) => index),
                disabledMinutes: (hour) => (hour === currentHour ? Array.from({ length: currentMinute }, (_, index) => index) : []),
                disabledSeconds: () => [],
            }
        }
   
        return disabledTimes;
      };
    const disabledDate = (current) => {
        // Can not select days before today and today
        if(activeDaysfromNow || activeDaysfromNow === 0) return current && (current < dayjs().startOf('day') || current > dayjs().add(activeDaysfromNow , 'day').endOf('day'))
        if(reverseDisableDate) return current && current < dayjs().startOf('day');
        return current && current > dayjs().endOf('day');
    };
    // console.log(hour , '----',)
    // console.log('new Date() :>> ', new Date().toISOString());
    return (
        <Container>
            {disableType === 'pick' ? 
                        <DatePicker 
                            {...props}
                            showNow={false}
                            showTime={{
                                ...showTime,
                                defaultValue: dayjs(`${defaultTime()}`, 'HH:mm:ss'),
                                minuteStep: 10, // Set the step interval for minutes to 5

                    }} disabledTime={disabledTime} disabledDate={disabledDate} placeholder='Огноо сонгох' />
        :   
                  <DatePicker disabledDate={disabledDate} placeholder='Огноо сонгох' showTime={showTime} {...props}/>
                }

        </Container>
    );
};

export default NewDateHoc;

const Container = styled.div`
    .ant-picker{
        padding:2px 11px;
        border-radius:4px;
        width:100%;
    }
`