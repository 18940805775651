import React from 'react';
import styled from 'styled-components';
import HtmlParser from 'html-to-react';
const parser = HtmlParser.Parser();

const RichtextParser = ({ data }) => {
    return <Container>{parser.parse(data)}</Container>;
};

export default RichtextParser;

const Container = styled.div`
    line-height: 2;
    font-size: 13px;
`;
