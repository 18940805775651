import React from 'react';
import styledComponents from 'styled-components';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { getReferenceContext } from 'contexts/reference';

const ConfirmEmail = () => {
  const [email, setEmail] = React.useState('');
  const [authCode, setAuthcode] = React.useState('');
  const { setIsLoading } = React.useContext(getReferenceContext);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await Auth.confirmSignUp(email, authCode);
      NotificationManager.success('Амжилттай.', 3000);
      navigate('/login');
    } catch (error) {
      console.log('error confirming sign up', error);
      NotificationManager.error(error.message, 3000);
    } finally {
      // setIsLoading(false);
    }
  };
  return (
    <Container>
      <img src='/logo.png' />
      <h2>Бүртгэл баталгаажуулах</h2>

      <input
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type='text'
        placeholder='И-мэйл хаяг'
        name='email'
      />
      {/* <AuthCode authCode={setAuthcode} /> */}
      <input
        required
        value={authCode}
        onChange={(e) => setAuthcode(e.target.value)}
        type='text'
        placeholder='Confirmation code'
        name='code'
      />
      <button onClick={handleSubmit}>Илгээх</button>

      <div
        className='or'
        style={{ marginTop: 5, marginBottom: 5, opacity: 0.7, fontSize: 10 }}
      >
        &nbsp;
      </div>
      <button type='button' onClick={() => navigate('/login')}>
        Нэвтрэх
      </button>
    </Container>
  );
};

export default ConfirmEmail;

const Container = styledComponents.form`
    h2 {
      text-align : center;
    }
    display:flex;
    height:100vh;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:200px;
    margin:auto;
    img{
        width:100px;
    }
    input[type="text"],input[type="password"]{
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 8px 12px;
        outline: none;
        transition: all 0.3s ease 0s;
        border-radius: 4px;
        box-sizing: border-box;
        text-overflow: ellipsis;
        margin-bottom:10px;
        width:100%;
        &:focus{
            border-color: rgba(0, 0, 0, 0.8);
            box-shadow: rgba(0 0 0 / 25%) 0px 0px 0px 2px;
        }
    }
    button{
        display:flex;
        align-items:center;
        justify-content:center;
        svg{
            font-size:16px;
            margin-right:5px;
        }
        text-transform:capitalize;
        padding:10px 16px;
        background:${({ theme }) => theme.color};
        color:white;
        border:none;
        border-radius:6px;
        font-weight:500;
        position:relative;
        overflow:hidden;
        cursor:pointer;
        width:100%;
    }
    button[type="button"]{
        background:${({ theme }) => theme.dark};
        margin-top:5px;
    }
`;
