import { getOrderContext } from 'contexts/order';
import React from 'react';
import styled from 'styled-components';
import FlexDivider from '../FlexDivider';
import PizzaInstance from './Instance';

const Pizza = ({ data }) => {
    const {menus, setMenus} = React.useContext(getOrderContext)
    const handleAddMenu = (value) => {
        setMenus([...menus, {...value}])
    }
    return (
        <FlexDivider>
            {Object.keys(data).map((PizzaName) => (
                <PizzaInstance
                    key={PizzaName}
                    name={PizzaName}
                    data={data[PizzaName]}
                    onChange={handleAddMenu}
                />
            ))}
        </FlexDivider>
    );
};

export default Pizza;
