import React from 'react';
import styled from 'styled-components';
import * as InputTypes from './InputTypes';
import StaticType from './StaticType';

const Form = ({ instance, model, styles, listenChange, forwardRef, disabled, staticType, hideLabel, saveAddress, ...props }) => {
    // const [instance, setInstance] = React.useState(firstInstance);
    // console.log({instance,firstInstance});
    // React.useEffect(() => {
    //     console.log(instance);
    // listenChange && listenChange(instance);
    // }, [instance]);
    return (
        <Container ref={forwardRef} styles={styles} {...props}>
            {Object.keys(model).map((field) => {
                const object = model[field];
                const InputType = InputTypes[object.type];
                return (
                    <div key={field}>
                        {!hideLabel && <label>{object.label}</label>}
                        {staticType || object.disabled ? (
                            <StaticType {...object}>{instance[field]}</StaticType>
                        ) : (
                            <InputType
                                value={object.watch ? instance[object.watch] : instance[field]}
                                disabled={disabled}
                                onChangeClean={(value) => listenChange && listenChange({ ...instance, [field]: value })}
                                onChangeMultiple={(datas) => listenChange && listenChange({ ...instance, ...datas })}
                                // BIND INTEROPERABLE INPUT PROPERTIES
                                {...(object.type === 'SelectStore' ? { byRole: object.byRole ?? false, ...(saveAddress ? { saveAddress } : {}) } : {})}
                                {...(object.type === 'SelectDistrict' ? { city: instance.city } : {})}
                                {...(object.type === 'SelectKhoroo' ? { district: instance.district } : {})}
                                {...(object.type === 'SelectAddress'
                                    ? {
                                        city: instance.city,
                                        district: instance.district,
                                        horoo: instance.horoo,
                                      }
                                    : {})}
                                {...(object.type === 'SelectSeqFromNum' ? { checkNum: instance.orderCheckNum } : {})}
                                {...object}
                            />
                        )}
                    </div>
                );
            })}
            <button style={{ position: 'absolute', opacity: 0, zIndex: -10 }} className="submit">
                .
            </button>
        </Container>
    );
};

export default Form;

const Container = styled.form`
    ${({ styles }) => styles};
`;

// `
//     & > div {
//         & > * {
//             &:first-child{
//             }
//             &:last-child{
//             }
//         }
//     }
// `
