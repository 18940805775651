import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Form from 'components/Form';
import SuggestionCategory from 'pages/Suggestion/SuggestionCategory';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';
import { formCreateSuggestion } from 'utils/formModels';
import Req from 'utils/Request';

const SuggestionPopup = ({ instance: firstInstance, setInstance: firstSetInstance }) => {
    const [categoryObject, setCategoryObject] = React.useState(null);
    const [instance, setInstance] = React.useState(firstInstance);
    const formRef = React.useRef()
    const handleSave = async (e) => {
        e.preventDefault()
        if(!categoryObject) return NotificationManager.warning('Төрөл сонгоно уу!', '', 3000)
        await Req({url: '/suggestion/create', body: {...instance, ...categoryObject, isAddress : false}})
        firstSetInstance(null)
    }
    return (
        <Dialog
            title={'Санал бүртгэх'}
            onClose={()=>firstSetInstance(null)}
            submit={
                <Button onClick={()=>formRef.current.querySelector('.submit').click()} bg="green">Илгээх</Button>
            }
        >
            <Container>
                <SuggestionCategory onChange={setCategoryObject}/>
                <Form
                    listenChange={setInstance}
                    onSubmit={handleSave}
                    forwardRef={formRef}
                    model={{...formCreateSuggestion, custPhone: { label: 'Хэрэглэгчийн утас', type: 'Text' } }}
                    instance={instance}
                    styles={createComplaintStyle}
                />
            </Container>
        </Dialog>
    );
};

export default SuggestionPopup;

const Container = styled.div`
    display:flex;
    & > form{
        flex:1;
        min-width:500px;
    }
`;

const createComplaintStyle = `
    & > div {
        margin-bottom:15px;
        & > * {
            &:first-child{
                display:block;
                margin-bottom:8px;
            }
            &:last-child{
            }
        }
    }
`;
