import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaHeadphonesAlt, FaShoppingCart, FaPhoneSlash, FaHandshake, FaUserCheck, FaCheckDouble } from 'react-icons/fa';
import Req from 'utils/Request';
import Div from './Div';
const MainIndexCounts = ({}) => {
    const [dataCounts, setDataCounts] = useState([
        { name: 'totalCall', title: 'НИЙТ ДУУДЛАГА', backColor: '#313131', icon: FaHeadphonesAlt, count: 0 },
        { name: 'totalOrder', title: 'ЗАХИАЛГА', backColor: '#409eff', icon: FaShoppingCart, count: 0 },
        { name: 'totalCanceled', title: 'ЦУЦЛАГДСАН', backColor: '#de5353', icon: FaPhoneSlash, count: 0 },
        { name: 'totalComplaint', title: 'САНАЛ ГОМДОЛ', backColor: '#e6a23c', icon: FaHandshake, count: 0 },
        { name: 'totalEnquiry', title: 'ЛАВЛАГАА', backColor: '#46369c', icon: FaUserCheck, count: 0 },
        { name: 'totalSuccess', title: 'АМЖИЛТТАЙ ЗАХИАЛГА', backColor: '#67c23a', icon: FaCheckDouble, count: 0 },
    ]);
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        const { data: result } = await Req({
            url: `/dashboard/totalCounts`,
            hideSuccess: true,
        });
        if (result.success) {
            const newCounts = dataCounts.map((item) => {
                item.count = result.data[item.name];
                return item;
            });
            setDataCounts(newCounts);
        }
    };

    return (
        <Container>
            <Div c="section_header">
                <h3>Өнөөдөр</h3>
            </Div>
            <BoxContainer>
                {dataCounts.map((item, index) => {
                    const Icon = item.icon;
                    return (
                        <Box key={index}>
                            <h5>{item.title}</h5>
                            <div className="stat-container" style={{ backgroundColor: item.backColor }}>
                                <span>{item.count}</span>
                                <Icon color="white" size={16} />
                            </div>
                        </Box>
                    );
                })}
            </BoxContainer>
        </Container>
    );
};
export default MainIndexCounts;
const Container = styled.div`
    .section_header {
        h3 {
            font-weight: 400;
            font-size: 20px;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
    }
`;
const BoxContainer = styled.div`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
`;
const Box = styled.div`
    display: flex;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    h5 {
        padding: 20px 15px;
        min-width: 100px;
    }
    .stat-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: end;
        padding: 5px 15px;
        width: 100px;
        color: #fff;
        span {
            font-size: 20px;
        }
    }
`;
