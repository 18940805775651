import SelectHoc from 'contexts/SelectHoc';
import React from 'react';

const SelectNoat = ({ value: firstValue, onChange, onChangeClean, ...props }) => {
    const [value, setValue] = React.useState({})
    const constantValues = [
        {value: 1, label: 'Хувь хүн'},
        {value: 2, label: 'Хувь хүн регистер'},
        {value: 3, label: 'Байгууллага'}
    ]
    const forwardChange = (e) => {
        onChange && onChange(e.value)
        onChangeClean && onChangeClean(e.value)
    }
    React.useEffect(() => {
        const findSelectedValue = constantValues.find(m => m.value === firstValue)
        setValue(findSelectedValue)
    }, [firstValue])
    return (
        <SelectHoc
            value={value}
            onChange={forwardChange}
            options={constantValues}
            {...props}
        />
    );
};

export default SelectNoat;