import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Form from 'components/Form';
import Div from 'components/Div';
import Title from 'components/Title';
import React from 'react';
import styled from 'styled-components';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye, AiOutlinePlus, AiOutlineSave, AiOutlineSearch } from 'react-icons/ai';
import EnquiryCategory from '../Enquiry/EnquiryCategory'
import { getOrderContext } from 'contexts/order';
import { getAuthContext } from 'contexts/auth';
import Req from 'utils/Request';
import RichtextParser from 'components/RichtextParser';

const EnquiryPopup = ({instance: firstInstance, setInstance: firstSetInstance }) => {
    const { roles, user } = React.useContext(getAuthContext);
    const [categoryObject, setCategoryObject] = React.useState(null);
    const [solved, setSolved] = React.useState(false);
    const [data, setData] = React.useState(null);
    const { custPhone } = React.useContext(getOrderContext);
    const formRef = React.useRef()
    const handleCheck = (e) => {
        setSolved(true);
    };
    const handleSave = async () => {
        const body = {
            category: categoryObject.category ? categoryObject.category : '',
            childCategory: categoryObject.childCategory ? categoryObject.childCategory : '',
            isSolved: solved,
            custPhone,
            operator: user.username,
        };
        await Req({ url: '/enquiry/resolve', body });
    };
    const fetchData = async () => {
        const body = {
            id: categoryObject.childCategoryId ?? categoryObject.categoryId,
        };
        const result = await Req({
            url: '/enquiry/category/findById',
            body,
            hideSuccess: true,
        });
        setData(result.data.data.enquiryCategory);
    };
    React.useEffect(() => {
        if (categoryObject) {
            fetchData();
        }
    }, [categoryObject]);

    return (
        <Dialog
           title={'Лавлагаа'}
           onClose={()=>firstSetInstance(null)}
           width="60vw"
        >
        <Container>
            <Div c="outer_wrap">
                <EnquiryCategory onChange={setCategoryObject} />
                <Div c="main_wrap">
                    <Div c="add_wrap">
                        <input type="checkbox" value="true" onChange={handleCheck} id="isSolved" />
                        <label htmlFor="isSolved">Үйлчлүүлэгч мэдээллээ бүрэн авсан эсэх?</label>
                        {categoryObject && (
                            <Button onClick={() => handleSave()} bg="green" color="white">
                                <AiOutlinePlus />
                                Хадгалах
                            </Button>
                        )}
                    </Div>
                    {data && (
                        <Div c="content_wrap">
                            <Title btmLine size={20}>
                                {categoryObject?.category}
                            </Title>

                            <RichtextParser data={data.note} />
                        </Div>
                    )}
                </Div>
            </Div>
        </Container>
        </Dialog>
)
}

export default EnquiryPopup;

const Container = styled.div`
    .outer_wrap {
        display: flex;
        .main_wrap {
            padding-left: 30px;
            width:70%;
            .add_wrap {
                margin-bottom: 15px;
                padding-bottom: 15px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
            .content_wrap {
                .btn_wrap {
                    margin-top: 15px;
                    display: flex;
                    gap: 10px;
                    justify-content: flex-end;
                }
            }
        }

        .bottom_wrap {
            margin-top: 30px;
            width: 100%;
            height: 0;
        }
    }
    .create_enquiry_wrap {
        display: flex;
        form {
            & > div {
                margin-bottom: 15px;
                & > * {
                    &:first-child {
                        display: block;
                        margin-bottom: 6px;
                    }
                    &:last-child {
                    }
                }
            }
        }
    }
`;
