import SelectHoc from 'contexts/SelectHoc';
import React from 'react';

const SelectReason = ({ value: firstValue, onChange, onChangeClean, ...props }) => {
    const [value, setValue] = React.useState({});
    const constantValues = [
        { value: 'Захиалга өөрчилсөн', label: 'Захиалга өөрчилсөн' },
        { value: 'Хэрэглэгч ирж аваагүй', label: 'Хэрэглэгч ирж аваагүй' },
        { value: 'НӨАТ баримт сольсон', label: 'НӨАТ баримт сольсон' },
        { value: 'Салбар андуурч захиалга оруулсан', label: 'Салбар андуурч захиалга оруулсан' },
        { value: 'Ресторан түр хаасан', label: 'Ресторан түр хаасан' },
        { value: 'Салбарын тог тасарсан', label: 'Салбарын тог тасарсан' },
        { value: 'Систем, програмын алдаа', label: 'Систем, програмын алдаа' },
        { value: 'Операторын алдаа', label: 'Операторын алдаа' },
        { value: 'Хэрэглэгч хаягаа буруу оруулсан', label: 'Хэрэглэгч хаягаа буруу оруулсан' },
        { value: 'Хүргэлтийн хугацаа урт', label: 'Хүргэлтийн хугацаа урт' },
        { value: 'Хүргэлт хоцорч ирсэн', label: 'Хүргэлт хоцорч ирсэн' },
        { value: 'Хэрэглэгч өөр газар захиалга өгсөн', label: 'Хэрэглэгч өөр газар захиалга өгсөн' },
        { value: 'Хэрэглэгч эзгүй', label: 'Хэрэглэгч эзгүй' },
        { value: 'Захиалга давхардсан', label: 'Захиалга давхардсан' },
        { value: 'Хуурамч захиалга', label: 'Хуурамч захиалга' },
        { value: 'Хүргэлтийн бүсээс гадна', label: 'Хүргэлтийн бүсээс гадна' },
        { value: 'Бүтээгдэхүүн нэмсэн', label: 'Бүтээгдэхүүн нэмсэн' },
    ];
    const forwardChange = (e) => {
        onChange && onChange(e.value);
        onChangeClean && onChangeClean(e.value);
    };
    React.useEffect(() => {
        const findSelectedValue = constantValues.find((m) => m.value === firstValue);
        setValue(findSelectedValue);
    }, [firstValue]);
    return <SelectHoc value={value} onChange={forwardChange} options={constantValues} {...props} />;
};

export default SelectReason;
