import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Div from 'components/Div';
import Form from 'components/Form';
import Table from 'components/Table';
import Tag from 'components/Tag';
import Title from 'components/Title';
import { getAuthContext } from 'contexts/auth';
import { getOrderContext } from 'contexts/order';
import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { timeSince } from 'utils/helpers';
import Req from 'utils/Request';
import { tableProcessNew } from 'utils/tableModels';
import CompaintPopup from './CompaintPopup';
import ProcessDetail from './ProcessDetail';
import SuggestionPopup from './SuggestionPopup';
import CancelRequestPopup from './CancelRequestPopup';

import EnquiryPopup from './EnquiryPopup';
import dayjs from 'dayjs';
const ProcessIndex = ({ onlyTable }) => {
    const { custPhone } = React.useContext(getOrderContext);
    const [searchParams] = useSearchParams();
    const [processData, setProcessData] = React.useState([]);
    const [mounted, setMounted] = React.useState(false);
    const [orderCancelReq, setOrderCancelReq] = React.useState(false);
    const [filters, setFilters] = React.useState({
        phone: custPhone,
        checkSeq: '',
        startDate: new Date(`${dayjs().format('YYYY-MM-DD')} 08:00`),
        endDate: new Date(`${dayjs().format('YYYY-MM-DD')} 23:59`),
    });
    const [pagination, setPagination] = React.useState({
        limit: 10,
        offset: 0,
    });
    const [forwardPagination, setForwardPagination] = React.useState({
        size: 10,
        number: 0,
    });
    const [storeInfo, setStoreInfo] = React.useState('');
    const { user,roles } = React.useContext(getAuthContext);
    const [popupInstance, setPopupInstance] = React.useState(null);
    const [complaintInstance, setComplaintInstance] = React.useState(null);
    const [suggestionInstance, setSuggestionInstance] = React.useState(null);
    const [enquiryInstance, setEnquiryInstance] = React.useState(null);
    const [cancelInstance, setCancelInstance] = React.useState(null);
    const showProcess = searchParams.get('showProcess');

    const [onlyEmployee, setOnlyEmployee] = React.useState(false);
    React.useEffect(() => {
        const find = roles.find((x) => x === 'EMPLOYEE');
        if(find && roles.length === 1) {
            setOnlyEmployee(true)
        }
    }, [roles]);

    React.useEffect(() => {
        setMounted(true);
    }, []);
    React.useEffect(() => {
        if (mounted) {
            setPagination({ limit: 10, offset: 0 });
            setForwardPagination({ size: 10, number: 0 });
        }
    }, [filters]);
    React.useEffect(() => {
        if (user) fetchProcessData();
    }, [user]);
    React.useEffect(() => {
        if (popupInstance) fetchGetRequestByOrderId(popupInstance.id);
    }, [popupInstance]);
    React.useEffect(() => {
        setForwardPagination({
            size: pagination.limit,
            number: pagination.offset / 10 + 1,
        });
    }, [pagination]);
    React.useEffect(() => {
        if (user) fetchProcessData();
    }, [forwardPagination]);
    const fetchProcessData = async () => {
        if (!forwardPagination.number > 0) return null;
        const result = await Req({
            url: '/order/filterOrders',
            body: { ...filters, page: { ...forwardPagination } },
            hideSuccess: true,
        });
        setProcessData({
            rows: result.data.data.data,
            count: result.data.data.total,
        });
        if (showProcess) {
            const foundOne = result.data.data.data.find((d) => d.checkSeq === showProcess);
            if (foundOne) setPopupInstance({ ...foundOne });
        }
    };
    const fetchGetRequestByOrderId = async (id) => {
        setOrderCancelReq(false);
        const {
            data: {
                data: { request },
            },
        } = await Req({
            url: '/order/getRequestByOrderId',
            body: { orderId: id },
            hideSuccess: true,
        });
        if (request) setOrderCancelReq(true);
    };

    return (
        <Container>
            <Title size={20} style={{ marginBottom: 50 }}>
                {onlyTable ? 'Захиалгууд' : 'Явц'}
            </Title>
            {!onlyTable && (
                <Div c="filter_wrap">
                    <Form
                        listenChange={setFilters}
                        instance={filters}
                        styles={filterFormStyle}
                        onSubmit={(e) => e.preventDefault()}
                        model={{
                            phone: { label: 'Утас', type: 'Text' },
                            checkSeq: { label: 'Д/Д', type: 'Text' },
                            checkNum: { label: 'Захиалгын дугаар', type: 'Text' },
                            action: { label: 'Статус', type: 'SelectOrderStatus' },
                            startDate: {
                                label: 'Эхлэх огноо',
                                type: 'SelectDatetime',
                                value: `${dayjs().format('YYYY-MM-DD')} 08:00`,
                            },
                            endDate: {
                                label: 'Дуусах огноо',
                                type: 'SelectDatetime',
                                value: `${dayjs().format('YYYY-MM-DD')} 23:59`,
                            },
                        }}
                    />
                    <Button bg="blue" onClick={fetchProcessData}>
                        <AiOutlineSearch />
                        Хайх
                    </Button>
                </Div>
            )}
            <Table
                data={processData}
                model={{
                    custom2: {
                        label: 'Үүсгэсэн',
                        jsx: ({ thisInstance }) => <Tag color="green">{timeSince(thisInstance.createdAt)}-ийн өмнө</Tag>,
                    },
                    ...(!onlyEmployee ? {checkSeq: { label: 'Д/Д' }} : {} ),
                    ...tableProcessNew,
                    custom: {
                        label: '',
                        sticky: true,
                        jsx: ({ thisInstance }) => (
                            <Button onClick={() => setPopupInstance(thisInstance)} color="black" style={{ marginLeft: 'auto' }}>
                                Явц харах
                            </Button>
                        ),
                    },
                }}
                paginationSetting={pagination}
                setPaginationSetting={setPagination}
            />
            {popupInstance && (
                <Dialog title={`Д/Д: ${popupInstance.checkSeq}  |  Захиалгын дугаар: ${popupInstance.checkNum} ${orderCancelReq ? '| (Цуцлах хүсэлт)' : ''}`} onClose={() => setPopupInstance(null)}>
                    <PopupWrapper>
                        <Div c="helper_buttons">
                            <Button
                                bg="blue"
                                onClick={() =>
                                    setSuggestionInstance({
                                        custPhone: popupInstance.custPhone,
                                        orderNumber: popupInstance.checkSeq,
                                        store: storeInfo,
                                        operator: user.username,
                                    })
                                }
                            >
                                Санал бүртгэх
                            </Button>
                            <Button
                                bg="blue"
                                onClick={() =>
                                    setComplaintInstance({
                                        custPhone: popupInstance.custPhone,
                                        orderNumber: popupInstance.checkSeq,
                                        orderNumberLabel: popupInstance.checkSeq,
                                        orderCheckNum: popupInstance.checkNum,
                                        store: storeInfo,
                                        operator: user.username,
                                    })
                                }
                            >
                                Гомдол бүртгэх
                            </Button>
                            <Button
                                bg="green"
                                onClick={() =>
                                    setEnquiryInstance({
                                        custPhone: popupInstance.custPhone,
                                        store: storeInfo,
                                        operator: user.username,
                                    })
                                }
                            >
                                Лавлагаа болгох
                            </Button>
                            <Button
                                bg="color"
                                disabled={orderCancelReq}
                                onClick={() =>
                                    setCancelInstance({
                                        orderNumber: popupInstance.checkSeq,
                                        operator: user.username,
                                        orderTotal: popupInstance.totalsTotalDue,
                                    })
                                }
                            >
                                Захиалга цуцлах хүсэлт
                            </Button>
                        </Div>
                        <ProcessDetail setStoreInfo={setStoreInfo} processId={popupInstance.id} />
                    </PopupWrapper>
                </Dialog>
            )}
            {complaintInstance && <CompaintPopup instance={complaintInstance} setInstance={setComplaintInstance} />}
            {suggestionInstance && <SuggestionPopup instance={suggestionInstance} setInstance={setSuggestionInstance} />}
            {cancelInstance && <CancelRequestPopup instance={cancelInstance} setInstance={setCancelInstance} setOrderCancelReq={setOrderCancelReq} />}
            {/* // (
            //     <Dialog
            //         onClose={() => setCancelInstance(null)}
            //         title={'Та энэ захиалгыг цуцлахдаа итгэлтэй байна уу?'}
            //         submit={
            //             // <Button onClick={() => formRef.current.querySelector('.submit').click()} bg="green">
            //             //     Тийм
            //             // </Button>
            //             <Button onClick={() => handleCancelRequest()} bg="green">
            //                 Тийм
            //             </Button>
            //         }
            //     >
            //         <Container>
            //             <Form instance={cancelInstance} setInstance={setCancelInstance} onSubmit={handleCancelRequest} forwardRef={formRef} model={{ ...formCancelRequest }} />
            //         </Container>
            //     </Dialog>
            // )
            // } */}
            {enquiryInstance && <EnquiryPopup instance={enquiryInstance} setInstance={setEnquiryInstance} />}
        </Container>
    );
};

export default ProcessIndex;

const Container = styled.div`
    .filter_wrap {
        margin-top: 30px;
        display: flex;
        align-items: flex-end;
        gap: 15px;
        margin-bottom: 30px;
    }
    .create_complaint_wrap {
        display: flex;
        & > form {
            flex: 1;
            min-width: 500px;
        }
    }
`;

const filterFormStyle = `
    display:flex;
    gap:15px;
    & > div {
        display:flex;
        flex-direction:column;
        &:nth-child(3){
        }
        & > * {
            &:first-child{
                opacity:0.6;
                display:block;
                font-size:10px;
                margin-bottom:6px;
            }
            &:last-child{
            }
        }
    }
`;

const PopupWrapper = styled.div`
    .helper_buttons {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        margin-top: 50px;
        margin-bottom: 15px;
    }
`;
