import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FaUserCircle, FaCog, FaPowerOff } from 'react-icons/fa';
import { BsChevronDown } from 'react-icons/bs';
import { getReferenceContext } from 'contexts/reference';
import { Auth } from 'aws-amplify';
import { getAuthContext } from 'contexts/auth';
import { useNavigate } from 'react-router-dom';
import Div from 'components/Div';
import Button from 'components/Button';

const Header = () => {
  const { header } = React.useContext(getReferenceContext);
  const { user, setLoggedIn } = React.useContext(getAuthContext);
  const [showPopup, setShowPopup] = React.useState(false)

  const navigate = useNavigate();
  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      setLoggedIn(false);
      navigate('/login');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  return (
    <Container ref={header}>
      <img id='logo' width={40} src='/logo.png' />
      <Div c='right_wrap'>
        <FaUserCircle size={24} style={{ marginRight: 10 }} />
        <span className='nameWrap' onClick={()=>setShowPopup(!showPopup)}>{user?.username} <BsChevronDown/></span>
        {showPopup && <Div c="popupWrap">
          {/* <Button color="black"><FaCog />Тохиргоо</Button> */}
          <Button color="black" onClick={handleSignOut}><FaPowerOff />Гарах</Button>
        </Div>}
      </Div>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  background: ${({ theme }) => theme.dark};
  padding: 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 1px;
  .right_wrap {
    display: flex;
    align-items: center;
    color: white;
    padding: 15px 0px;
    position:relative;
    .nameWrap {
      display: flex;
      align-items: center;
      padding: 4px;
      cursor: pointer;
      svg {
        margin-left: 5px;
      }
    }
    .popupWrap{
      position:absolute;
      top:100%;
      right:0px;
      background:white;
      border-radius:8px;
      border:1px solid rgba(0,0,0,0.1);
      padding:8px;
      margin-top:-10px;
      button{
        border:none;
      }
    }
    sl-menu {
      padding: 0px;
      color: black;
      margin-top: 20px;
      button {
        display: block;
        width: 100%;
        padding: 12px 15px;
        text-align: left;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.7);
        border: none;
        svg {
          margin-right: 10px;
        }
        &:hover {
          color: ${({ theme }) => theme.lightblue};
          cursor: pointer;
        }
      }
    }
  }
`;
