import axios from 'axios';
import Div from 'components/Div';
import Form from 'components/Form';
import { getOrderContext } from 'contexts/order';
import { getReferenceContext } from 'contexts/reference';
import React from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { NotificationManager } from 'react-notifications';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FormatDateFull } from 'utils/date';
import { formOrderPlace } from 'utils/formModels';
import Req from 'utils/Request';
import { formatNumber } from 'utils/vanillaFunctions';
import configJson from '../../config.json';

const PlaceOrder = ({ setShowOrder }) => {
    const { address, setAddress, setHelpMessage, setStoreName, checkoutInfo, menus, setMenus, setCheckoutInfo, custPhone, checkOrder, setCheckOrder } = React.useContext(getOrderContext);
    const [orderForm, setOrderForm] = React.useState({});
    const formRef = React.useRef();
    const [companyName, setCompanyName] = React.useState(null);
    const { getTranslation } = React.useContext(getReferenceContext);

    const navigate = useNavigate();
    const forwardSubmit = () => {
        formRef.current.querySelector('.submit').click();
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!orderForm.noatType) return NotificationManager.warning('НӨАТ сонгоно уу.');
        if (orderForm.noatType === 3 || orderForm.noatType === 2) {
            if (!orderForm.regNo) return NotificationManager.warning('Байгууллага эсвэл хувь хүн регистер оруулна уу.');
            if (orderForm.regNo) {
                if (!companyName) return NotificationManager.warning('Та регистерийн дугаараа шалгана уу');
            }
        }
        const orderObject = {
            storeName: address.storeName,
            orderType: address.haveDelivery ? 'delivery' : 'pickup',
            orderDate: address.date,
        };
        if (address.haveDelivery === 'delivery') orderObject.podCode = address.detail.podph;
        let addressObject = null;
        if (address.haveDelivery) {
            addressObject = { ...address.detail };
            addressObject.ortsKod = address.ortsKod;
            addressObject.orts = address.orts;
            addressObject.haalga = address.haalga;
            addressObject.additional = address.additional;
        }
        const custObject = {
            note: orderForm.note,
            phone: custPhone,
            phone2: orderForm.additionalPhone,
            vatType: orderForm.noatType,
        };
        if (orderForm.noatType === 3 || orderForm.noatType === 2) {
            custObject.vatRegno = orderForm.regNo;
            custObject.vatName = companyName;
        }
        const bodyObject = { order: { ...orderObject }, cust: { ...custObject } };

        // IF DELIVERY TRUE, INJECT ADDRESS
        if (addressObject) bodyObject.address = { ...addressObject };

        // INJECT CART PRODUCTS MENU
        bodyObject.menus = [...menus];

        if (checkOrder) {
            Req({ url: '/order/place', body: bodyObject, successMsg: 'Захиалга амжилттай илгээгдлээ.' }).then((result) => {
                setAddress(null);
                setHelpMessage(null);
                setStoreName(null);
                setCheckoutInfo(null);
                navigate({ pathname: `/process`, search: createSearchParams({ showProcess: result.data.data.checkSeq }).toString() });
            });
            setShowOrder(false);
            setMenus([]);
        } else {
            await Req({ url: '/order/checkout', body: bodyObject, hideSuccess: true });
            setCheckOrder(true);
        }
    };
    React.useEffect(() => {
        setOrderForm({ ...orderForm, regNo: '' });
    }, [orderForm.noatType]);

    React.useEffect(() => {
        const btn = document.querySelector('.checkOrderBtn');
        btn.addEventListener('click', forwardSubmit);
    }, []);

    React.useEffect(() => {
        if (orderForm.regNo) {
            if (orderForm.regNo.length === 7 && orderForm.noatType === 3) checkCompany();
            if (orderForm.regNo.length === 10 && orderForm.noatType === 2) checkCompany();
            else setCompanyName(null);
        }
    }, [orderForm.regNo]);

    const checkCompany = async () => {
        // const result = await axios(`https://oldapi.pizzahut.mn/web/vatchecker?regno=${orderForm.regNo}`)
        const result = await axios.post(`${configJson.url.vatServer}`, { regno: orderForm.regNo });
        try {
            if (!result.data.success) throw new Error('');
            // setOrderForm({...orderForm, company: result.data.name})
            setCompanyName(result.data.result.name);
        } catch (e) {
            if (orderForm.noatType === 2) NotificationManager.warning('Хувь хүн регистр олдсонгүй.');
            if (orderForm.noatType === 3) NotificationManager.warning('Байгууллага олдсонгүй.');
        }
    };
    return (
        <Container checkOrder={checkOrder}>
            <Div c="detail_wrap">
                {address.haveDelivery ? (
                    <>
                        <Div c="row_wrap">
                            <Div c="title">Захиалга төрөл</Div>
                            <Div c="info">{address.date ? `Хүргэлт (Урьдчилсан)` : `Хүргэлт (Одоо)`}</Div>
                        </Div>
                        <Div c="row_wrap">
                            <Div c="title">Захиалга өгөх цаг</Div>
                            <Div c="info">{address.date ? `${FormatDateFull(address.date)}` : ``}</Div>
                        </Div>
                        <Div c="row_wrap">
                            <Div c="title">Хаяг</Div>
                            <Div c="info">
                                {address.detail.full_address}
                                <br />
                                Орц: {address.orts}
                                <br />
                                Орц код: {address.ortsKod}
                                <br />
                                Хаалга: {address.haalga}
                                <br />
                                Албан бус хаяг: {address.detail.bairnote}
                                <br />
                            </Div>
                        </Div>
                        <Div c="row_wrap">
                            <Div c="title">Тэмдэглэл</Div>
                            <Div c="info">{address.additional}</Div>
                        </Div>
                    </>
                ) : (
                    <>
                        <Div c="row_wrap">
                            <Div c="title">Захиалга төрөл</Div>
                            <Div c="info">{address.date ? `Очиж авах (Урьдчилсан)` : `Очиж авах (Одоо)`}</Div>
                        </Div>
                        <Div c="row_wrap">
                            <Div c="title">Захиалга өгөх цаг</Div>
                            <Div c="info">{address.date ? `${FormatDateFull(address.date)}` : ``}</Div>
                        </Div>
                    </>
                )}
                <Div c="row_wrap">
                    <Div c="title">Бүтээгдэхүүнүүд</Div>
                    <Div c="info">
                        {menus.map((menu, index) => (
                            <React.Fragment key={index}>
                                <Div>
                                    <FaChevronRight />
                                    {menu.type === 'pizza' ? `${getTranslation(menu.pizzaSize)} ${getTranslation(menu.pizzaBase)} ${getTranslation(menu.name)}` : getTranslation(menu.masterName)} (
                                    {menu.price}₮) -- ({menu.count}ш)
                                    <br />
                                </Div>
                                {menu.comboMainItems && (
                                    <Div c="sideItems">
                                        {menu.comboMainItems.type === 'pizza'
                                            ? `${getTranslation(menu.comboMainItems.pizzaSize)} ${getTranslation(menu.comboMainItems.pizzaBase)} ${getTranslation(menu.comboMainItems.name)}`
                                            : getTranslation(menu.comboMainItems.masterName)}{' '}
                                        -- 1ш
                                        <br />
                                    </Div>
                                )}
                                {menu.comboSideItems && (
                                    <Div c="sideItems">
                                        {menu.comboSideItems.map((sideItem, ind) => (
                                            <React.Fragment key={ind}>
                                                {sideItem.type === 'pizza'
                                                    ? `${getTranslation(sideItem.pizzaSize)} ${getTranslation(sideItem.pizzaBase)} ${getTranslation(sideItem.name)}`
                                                    : getTranslation(sideItem.masterName)}{' '}
                                                -- 1ш <br />
                                            </React.Fragment>
                                        ))}
                                    </Div>
                                )}
                            </React.Fragment>
                        ))}
                    </Div>
                </Div>
                <Div c="row_wrap">
                    <Div c="title">Салбар</Div>
                    <Div c="info">{address?.store ?? address.storeName}</Div>
                </Div>
                <Div c="row_wrap">
                    <Div c="title">Дүн</Div>
                    <Div c="info">{checkoutInfo ? formatNumber(checkoutInfo.totalsSubTotal) + '₮' : '0₮'}</Div>
                </Div>
                <Div c="row_wrap">
                    <Div c="title">Хүргэлтийн төлбөр</Div>
                    <Div c="info">{checkoutInfo ? formatNumber(checkoutInfo.totalsOtherTotals) + '₮' : '0₮'}</Div>
                </Div>
                <Div c="row_wrap">
                    <Div c="title">Нийт дүн</Div>
                    <Div c="info">{checkoutInfo ? formatNumber(checkoutInfo.totalsTotalDue) + '₮' : '0₮'}</Div>
                </Div>
            </Div>
            <Form
                listenChange={setOrderForm}
                model={{
                    ...formOrderPlace,
                    ...(orderForm.noatType === 3 || orderForm.noatType === 2 ? { regNo: { label: 'Регистр', type: 'Text', required: true } } : {}),
                }}
                forwardRef={formRef}
                instance={orderForm}
                styles={formStyle}
                onSubmit={handleSubmit}
            />
            <Div c="detail_wrap">
                <Div c="row_wrap">
                    <Div c="title">Байгууллага / Хувь хүн</Div>
                    <Div c="info" open={companyName ?? false}>
                        {companyName ?? ''}
                    </Div>
                </Div>
            </Div>
        </Container>
    );
};

export default PlaceOrder;

const Container = styled.div`
    .detail_wrap {
        line-height: 20px;
        padding: 0px 15px;
        display: flex;
        flex-direction: column;
        .row_wrap {
            display: flex;
            gap: 15px;
            margin-bottom: 10px;
            .title {
                max-width: 180px;
                min-width: 180px;
                font-weight: 500;
                text-align: right;
            }
            .info {
                flex: 1;
                svg {
                    font-size: 8px;
                    margin-right: 6px;
                }
                &[open] {
                    background: #dbf9cc;
                    color: ${({ theme }) => theme.green};
                    padding: 4px 8px;
                    border-radius: 4px;
                    // color:white;
                }
                .sideItems {
                    padding-left: 25px;
                }
            }
        }
    }
    ${({ checkOrder }) =>
        checkOrder &&
        `
        form {
            opacity:0.6;
            pointer-events:none;
            & > div {
                & > * {
                    &:first-child{

                    }
                    &:last-child{
                        background: #dbf9cc;
                        color: ${({ theme }) => theme.green};
                        & > div {
                            background: #dbf9cc;
                            color: ${({ theme }) => theme.green};
                        }
                        & > * {
                            background: #dbf9cc;
                            color: ${({ theme }) => theme.green};
                        }
                    }
                }
            }
        }
    `};
`;

export const formStyle = `
    border-top:1px solid rgba(0,0,0,0.1);
    margin-top:10px;
    padding-top:10px;
    & > div {
        display:flex;
        padding:6px 0px;
        margin:10px 0px;
        gap:10px;
        & > * {
            &:first-child{
                max-width:200px;
                min-width:200px;
                flex:1;
                font-weight:500;
                text-align:right;
                &:after{
                    content:":";
                }
            }
            &:last-child{
                flex:1;
            }
        }
    }
`;
