import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Div from 'components/Div';
import Form from 'components/Form';
import Table from 'components/Table';
import Tag from 'components/Tag';
import Title from 'components/Title';
import { getAuthContext } from 'contexts/auth';
import { getOrderContext } from 'contexts/order';
import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Req from 'utils/Request';
import dayjs from 'dayjs';
import MainIndexCounts from 'components/MainIndexCounts';
import Enquiry from '../Enquiry';
import Process from '../Process';
const ProcessIndex = () => {
    return (
        <Container>
            <MainIndexCounts />
            <Enquiry onlyTable />
            <Process onlyTable />

        </Container>
    );
};

export default ProcessIndex;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
