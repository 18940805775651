import SelectHoc from 'contexts/SelectHoc';
import React from 'react';
import { FormatDateFull } from 'utils/date';
import Req from 'utils/Request';

const SelectSeqFromNum = ({ value: firstCheckSeq, checkNum, onCustomChange, onChange, onChangeClean, ...props }) => {
    const [data, setData] = React.useState([]);
    const [orderCheckNum, setOrderCheckNum] = React.useState(checkNum);
    const [value, setValue] = React.useState(firstCheckSeq);
    React.useEffect(() => {
        fetchData();
    }, [orderCheckNum, firstCheckSeq]);

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const result = await Req({
            url: '/order/filterOrders',
            body: {
                checkNum: orderCheckNum?.toString(),
                page: {
                    size: 10,
                    number: 1,
                },
            },
            hideSuccess: true,
            disableLoader: false,
        });
        const mappedResult = result.data.data.data.map((d) => ({ value: d.checkSeq, label: `${d.checkNum} (${d.addrFullAddress} - ${FormatDateFull(d.createdAt)})` }));
        const findSelectedValue = mappedResult.find((m) => m.value === value);
        setValue(findSelectedValue);
        setData(mappedResult);
    };
    const forwardChange = (e) => {
        // onChange && onChange(e);
        onChangeClean && onChangeClean(e?.value);
    };
    return (
        <SelectHoc
            value={value}
            onChange={forwardChange}
            options={data}
            onInputChange={(v) => {
                if (v) setOrderCheckNum(v);
            }}
            {...props}
        />
    );
};

export default SelectSeqFromNum;
