import { getAuthContext } from 'contexts/auth';
import SelectHoc from 'contexts/SelectHoc';
import React from 'react';
import Req from 'utils/Request';

const SelectOrderStatus = ({ value: firstValue, onChange, onChangeClean, ...props }) => {
    const [data, setData] = React.useState([])
    // const [value, setValue] = React.useState({})
    // const { user } = React.useContext(getAuthContext)
    React.useEffect(() => {
       fetchData()
    }, [])
    const fetchData = async () => {
        const result = await Req({
            url: `/order/getOrderStatuses`,
            hideSuccess: true,
        })
        const mappedResult = Object.keys(result.data.data).map(d => ({ value: d, label: result.data.data[d] }))        
        // const findSelectedValue = mappedResult.find(m => m.value === firstValue)
        // setValue()
        setData(mappedResult)
    }
    const forwardChange = (e) => {
        onChange && onChange(e)
        onChangeClean && onChangeClean(e?.value)
    }
    return (
        <SelectHoc
            // value={value}
            onChange={forwardChange}
            options={data}
            {...props}
        />
    );
};

export default SelectOrderStatus;