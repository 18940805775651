import { OrderContextProvider } from 'contexts/order';
import Layout from 'Layout';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

const GenericEntry = () => {
    const { custPhone } = useParams()
    return (
        <OrderContextProvider custPhone={custPhone}>
            <Layout>
                <Outlet />
            </Layout>
        </OrderContextProvider>

    )
};

export default GenericEntry;
