import Button from 'components/Button';
import React from 'react';
import styled from 'styled-components';
import { FaCartPlus, FaMapMarkerAlt, FaChevronDown, FaChevronRight } from 'react-icons/fa'
import Div from 'components/Div';
import { getOrderContext } from 'contexts/order';

const Address = () => {
    const [toggled, setToggled] = React.useState(true)
    const {address} = React.useContext(getOrderContext)
    return (
        <Container>
            <Div c="toggle" onClick={()=>setToggled(!toggled)}>
                <FaMapMarkerAlt/>
                <span>Хаяг</span>
                {toggled ? <FaChevronDown className='chevron'/> : <FaChevronRight className='chevron'/>}
            </Div>
            {toggled ? <Div c="content">
                {address ? address.haveDelivery ? address?.detail?.full_address : `Очиж авах: ${address?.store}` : ''}
            </Div> : ''}
        </Container>
    );
};

export default Address;

const Container = styled.div `
    margin-bottom:30px;
    .toggle{
        cursor:pointer;
        background:${({theme})=>theme.color};
        color:white;
        display:flex;
        align-items:center;
        gap:4px;
        padding:8px 8px;
        font-size:14px;
        border-radius:4px;
        .chevron{
            margin-left:auto;
        }
        margin-bottom:10px;
    }
    .content{

    }
`