import React from 'react';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Form from 'components/Form';
import { NotificationManager } from 'react-notifications';
import Req from 'utils/Request';
import styled from 'styled-components';

import { formCancelRequest } from 'utils/formModels';

const CancelRequestPopup = ({ instance: firstInstance, setInstance: firstSetInstance,setOrderCancelReq }) => {
    const formRef = React.useRef();
    const [cancelInstance, setCancelInstance] = React.useState(firstInstance);

    const handleCancelRequest = async () => {
        if (!cancelInstance) return NotificationManager.warning('Цуцлах захиалга сонгоно уу!', '', 3000);
        const body = {
            orderNumber: cancelInstance.orderNumber,
            operator: cancelInstance.operator,
            reason: cancelInstance.reason,
            comment: cancelInstance.comment,
        };
        const result = await Req({ url: '/order/createRequest', body });
        if (result.code === 200 && result.success) {
            return NotificationManager.success('Амжилттай хадгаллаа!', '', 3000);
        }
        setOrderCancelReq && setOrderCancelReq(true)
        setCancelInstance(null);
        firstSetInstance(null);
    };

    const handle = () => {
        firstSetInstance(null);
        setCancelInstance(null);
    };

    return (
        <Dialog
            onClose={() => handle()}
            title={'Та энэ захиалгыг цуцлахдаа итгэлтэй байна уу?'}
            height={700}
            submit={
                <Button onClick={() => handleCancelRequest()} bg="green">
                    Тийм
                </Button>
            }
        >
            <Container>
                <Form instance={cancelInstance} listenChange={setCancelInstance} onSubmit={handleCancelRequest} forwardRef={formRef} model={{ ...formCancelRequest }} />
            </Container>
        </Dialog>
    );
};

export default CancelRequestPopup;

const Container = styled.div`
    form > div > input {
        margin: 8px 0;
    }

    form > div > div {
        margin: 7px 0;
    }
`;
