import Div from 'components/Div';
import React from 'react';
import styled from 'styled-components';
import Cardbox from '../Cardbox';
import FlexDivider from '../FlexDivider';
import PizzaInstance from '../Pizza/Instance';

const ComboPopup = ({ instance, setSelectedCombo }) => {
    const main = instance.comboMainItems;
    const sides = instance.comboSideItems;
    const [mainItemState, setMainItemState] = React.useState({ ...instance.comboMainItems, selected: null });
    const [sideItemsState, setSideItemsState] = React.useState(instance.comboSideItems.map((x) => ({ ...x, selected: x?.items?.length > 0 ? x.items[0] : null })));
    React.useEffect(() => {
        setSelectedCombo({ ...instance, comboMainItems: { ...mainItemState }, comboSideItems: [...sideItemsState] });
    }, [mainItemState, sideItemsState]);
    const handleMain = (value) => {
        delete value.count;
        setMainItemState((prevState) => ({ ...prevState, selected: value }));
    };
    const handleSides = (value, index) => {
        delete value.count;
        const clonedState = [...sideItemsState];
        clonedState[index].selected = { ...value };
        setSideItemsState(clonedState);
    };
    return (
        <Container>
            <Div c="choiceWrap">
                <Div c="choiceType">{main.type}</Div>
                {main.type === 'pizza' && (
                    <Div c="pizzaChoiceWrap">
                        <FlexDivider grid={Object.keys(main.items).length <= 3 ? 3 : 3} gap={20}>
                            {Object.keys(main.items).map((PizzaName) => {
                                let checked = false;
                                const thisInstance = main.items[PizzaName];
                                if (mainItemState.selected) {
                                    const found = thisInstance.items.find((x) => x.menuItemDefID === mainItemState.selected.menuItemDefID);
                                    if (found) checked = true;
                                }
                                return <PizzaInstance key={PizzaName} name={PizzaName} data={thisInstance} isCombo checked={checked} onChange={handleMain} />;
                            })}
                        </FlexDivider>
                    </Div>
                )}
                {main.type === 'single' && (
                    <Div c="pizzaChoiceWrap">
                        <FlexDivider grid={3} gap={20}>
                            {main.items.map((item) => {
                                let checked = false;
                                const thisInstance = item;
                                if (mainItemState.selected) {
                                    // const found = thisInstance.items.find((x) => x.menuItemDefID === mainItemState.selected.menuItemDefID);
                                    if (thisInstance.menuItemDefID === mainItemState.selected.menuItemDefID) {
                                        checked = true;
                                    }
                                    // if (found) checked = true;
                                }
                                return (
                                    <Cardbox
                                        key={item.menuItemDefID}
                                        name={item.masterName}
                                        price={item.price}
                                        desc={item.longDescriptor_stringText}
                                        img={item.majorGroup.toLowerCase()}
                                        data={item}
                                        isCombo
                                        checked={checked}
                                        onSelect={handleMain}
                                    />
                                );
                            })}
                        </FlexDivider>
                    </Div>
                )}
            </Div>
            {sides.map((side, index) => (
                <Div c="choiceWrap" key={`side-${index}`}>
                    <Div c="choiceType">{side.optionName}</Div>
                    <Div c="pizzaChoiceWrap">
                        <FlexDivider grid={Object.keys(side.items)?.length <= 3 ? 3 : 3}gap={20}>
                            {side.type !== 'pizza'
                                ? side.items.map((item) => {
                                      let checked = false;
                                      const thisInstance = item;
                                      if (sideItemsState[index].selected) {
                                          if (thisInstance.menuItemDefID === sideItemsState[index].selected.menuItemDefID) {
                                              checked = true;
                                          }
                                      }
                                      return (
                                          <Cardbox
                                              key={item.menuItemDefID}
                                              name={item.masterName}
                                              price={item.price}
                                              desc={item.longDescriptor_stringText}
                                              img={item.majorGroup.toLowerCase()}
                                              data={item}
                                              isCombo
                                              checked={checked}
                                              onSelect={(value) => handleSides(value, index)}
                                          />
                                      );
                                  })
                                : Object.keys(side.items).map((PizzaName, i) => {
                                      let checked = false;
                                      const thisInstance = side.items[PizzaName];
                                      if (sideItemsState[index].selected) {
                                          const found = thisInstance.items.find((x) => x.menuItemDefID === sideItemsState[index].selected.menuItemDefID);
                                          if (found) checked = true;
                                      }
                                      return (
                                          <PizzaInstance
                                              disableIncrement={true}
                                              key={PizzaName + i}
                                              name={PizzaName}
                                              data={side.items[PizzaName]}
                                              isCombo
                                              checked={checked}
                                              onChange={(value) => handleSides(value, index)}
                                          />
                                      );
                                  })}
                        </FlexDivider>
                    </Div>
                </Div>
            ))}
        </Container>
    );
};

export default ComboPopup;

const Container = styled.div`
    .choiceWrap {
        margin-bottom: 30px;
        .choiceType {
            font-size: 20px;
            opacity: 0.5;
            font-weight: 500;
            text-transform: capitalize;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-bottom: 15px;
            margin-bottom: 15px;
        }
    }
`;
