import Button from 'components/Button';
import Div from 'components/Div';
import Form from 'components/Form';
import Title from 'components/Title';
import React from 'react';
import styled from 'styled-components';
import { AiOutlineSearch, AiFillEdit, AiOutlineEye, AiOutlineCheck } from 'react-icons/ai'
import axios from 'axios';
import { getReferenceContext } from 'contexts/reference';
import Table from 'components/Table';
import { tableSuggestion } from 'utils/tableModels';
import { NotificationManager } from 'react-notifications';
import Dialog from 'components/Dialog';
import { popupDetailStyle, popupFormStyle } from 'utils/sharedStyles';
import { formSuggestion, formCreateSuggestion, formSolveSuggestion } from 'utils/formModels';
import SuggestionCategory from './SuggestionCategory';
import Tag from 'components/Tag';
import SwitchButton from 'components/SwitchButton';
import { getOrderContext } from 'contexts/order';
import { getAuthContext } from 'contexts/auth';
import Req from 'utils/Request';

const Suggestion = () => {
    const { setIsLoading } = React.useContext(getReferenceContext)
    const [data, setData] = React.useState(null)
    const { custPhone } = React.useContext(getOrderContext)
    const [filters, setFilters] = React.useState({custPhone: custPhone ?? ''})
    const [selectedInstance, setSelectedInstance] = React.useState(null)
    const [formDisabled, setFormDisabled] = React.useState(true)
    const [categoryObject, setCategoryObject] = React.useState(null)
    const formRef = React.useRef()
    const formSolveSuggestRef = React.useRef()
    const [paginationSetting, setPaginationSetting] = React.useState({ limit: 10, offset: 0 })
    const switchCases = ['Шийдвэрлээгүй', 'Шийдвэрлэсэн']
    const [isSolved, setIsSolved] = React.useState(switchCases[0])
    const [solvingInstance, setSolvingInstance] = React.useState(null)
    const { user } = React.useContext(getAuthContext)
    const fetchData = async () => {
        const body = {}
        const { startDate, endDate, custPhone } = filters
        if (startDate || endDate || custPhone) {
            if (startDate && endDate && startDate.getTime() > endDate.getTime()) return NotificationManager.warning('Эхлэх огноо дуусах огнооноос их байх боломжгүй', '', 3000)
            if (startDate) body.startDate = startDate
            if (endDate) body.endDate = endDate
            if (custPhone) body.custPhone = custPhone
        }
        const bodyObject = {
            ...body,
            ...paginationSetting,
            isSolved: isSolved === "Шийдвэрлэсэн" ? true : false,
            isAddress: false,
            sortBy: 'createdAt',
            sortDirection: 'DESC'
        }
        const result = await Req({url: '/suggestion/list', body: bodyObject, hideSuccess: true})
        let otherRemainedRows = result.data.data.suggestion.rows.filter(x => !x.categoryName.includes('Хаяг нэмэх хүсэлт'))
        otherRemainedRows = otherRemainedRows.filter(x => !x.categoryName.includes('Хүргэлтийн бүс нэмэх'))
        otherRemainedRows = otherRemainedRows.filter(x => !x.categoryName.includes('Салбарын байршилтай'))
        otherRemainedRows = otherRemainedRows.filter(x => !x.categoryName.includes('Хаяг шинэчлэх хүсэлт'))
        setData({count : result.data.data.suggestion.count, rows : otherRemainedRows})
    }
    React.useEffect(() => {
        fetchData()
    }, [paginationSetting, isSolved])
    const handleSave = async (e) => {
        e?.preventDefault()
        if (!categoryObject) return NotificationManager.warning('Саналын төрөл сонгоно уу.', '', 3000)
        const body = {...selectedInstance, ...categoryObject, custPhone, operator: user.username, isAddress: false}
        await Req({url: '/suggestion/create', body})
        fetchData()
        setSelectedInstance(null)
    }
    const handleSolveComplaint = async (e) => {
        e?.preventDefault()
        const body = {
            // ...solvingInstance,
            resolvedNote: solvingInstance.resolvedNote,
            resolvedAt: new Date(),
            resolvedEmp: user.username,
            isSolved: true,
            id: solvingInstance.id
        }
        await Req({url: `/suggestion/resolve`, body })
        setSolvingInstance(null)
    }
    return (
        <Container>
            <Title size={20} style={{ marginBottom: 50 }}>Санал</Title>
            <SwitchButton selected={isSolved} onChange={setIsSolved} options={switchCases} />
            <Div c='filter_wrap'>
                <Form
                    listenChange={(e) => setFilters({ ...e })}
                    styles={filterFormStyle}
                    onSubmit={(e)=>e.preventDefault()}
                    instance={filters}
                    model={{ startDate: { label: 'Эхлэх огноо', type: 'SelectDatetime' }, endDate: { label: 'Дуусах огноо', type: 'SelectDatetime' }, custPhone: {label: 'Утасны дугаар', type: 'Text'} }}
                />
                <Button bg="blue" onClick={fetchData}><AiOutlineSearch />Хайх</Button>
                <Button bg="green" style={{ marginLeft: 'auto' }} onClick={() => { setSelectedInstance({operator: user.username}); setFormDisabled(false) }}>+ Санал бүртгэх</Button>
            </Div>
            <Table
                data={data}
                paginationSetting={paginationSetting}
                setPaginationSetting={setPaginationSetting}
                model={{
                    ...tableSuggestion,
                    custom: {
                        label: '',
                        sticky: true,
                        jsx: ({ thisInstance }) => (
                            <Div style={{ display: 'flex', gap: 15 }}>
                                <Button bg="green" onClick={() => { setSelectedInstance(thisInstance); setFormDisabled(true) }}>
                                    <AiOutlineEye />
                                    Үзэх
                                </Button>
                                {isSolved === 'Шийдвэрлээгүй' && <Button bg="blue" onClick={() => { setSolvingInstance(thisInstance); setFormDisabled(false) }}>
                                    <AiFillEdit />
                                    Шийдвэрлэх
                                </Button>}
                            </Div>
                        )
                    }, isSolved: {
                        label: 'Төлөв',
                        jsx: ({ thisInstance }) => (
                            <Tag color={thisInstance.isSolved ? 'green' : 'blue'}>
                                {thisInstance.isSolved ? 'Шийдвэрлэгдсэн' : 'Бүртгэгдсэн'}
                            </Tag>
                        )
                    }
                }} />
            {selectedInstance &&
                <Dialog
                    onClose={() => setSelectedInstance(null)}
                    title="Саналын дэлгэрэнгүй"
                    submit={formDisabled ? null : <Button onClick={() => formRef.current.querySelector('.submit').click()} bg="blue" type="submit">Хадгалах</Button>}
                    // width="500px"
                >
                    {formDisabled ?
                        <Form
                            forwardRef={formRef}
                            model={formSuggestion}
                            instance={selectedInstance}
                            styles={popupDetailStyle}
                            disabled={formDisabled}
                            staticType
                        />
                        :
                        <Div c="create_complaint_wrap">
                            <SuggestionCategory onChange={setCategoryObject} />
                            <Form
                                listenChange={setSelectedInstance}
                                onSubmit={handleSave}
                                forwardRef={formRef}
                                model={formCreateSuggestion}
                                instance={selectedInstance}
                                styles={createComplaintStyle}
                                disabled={formDisabled}
                            />
                        </Div>
                    }

                </Dialog>
            }
            {solvingInstance &&
                <Dialog
                    onClose={() => setSolvingInstance(null)}
                    title="Санал шийдвэрлэх"
                    submit={formDisabled ? null : <Button onClick={() => formSolveSuggestRef.current.querySelector('.submit').click()} bg="blue" type="submit">Шийдвэрлэх</Button>}
                    width="500px"
                >
                    <Form
                        forwardRef={formSolveSuggestRef}
                        model={formSolveSuggestion}
                        instance={solvingInstance}
                        styles={popupFormStyle}
                        listenChange={setSolvingInstance}
                        onSubmit={handleSolveComplaint}
                    />
                </Dialog>
            }
        </Container>
    );
};

export default Suggestion;

const Container = styled.div`
    .filter_wrap{
        margin-top:30px;
        display:flex;
        align-items:flex-start;
        gap:15px;
        margin-bottom:30px;
    }
    .create_complaint_wrap{
        display:flex;
        & > form{
            flex:1;
            min-width:500px;
        }
    }
`

const filterFormStyle = `
    display:flex;
    gap:15px;
    & > div {
        display:flex;
        flex-direction:column-reverse;
        &:nth-child(3){
            flex:3;
        }
        & > * {
            &:first-child{
                opacity:0.6;
                margin-top:6px;
                display:block;
                font-size:10px;
            }
            &:last-child{
            }
        }
    }
`;

const createComplaintStyle = `
    & > div {
        margin-bottom:15px;
        & > * {
            &:first-child{
                display:block;
                margin-bottom:8px;
            }
            &:last-child{
            }
        }
    }
`;
