import { getOrderContext } from 'contexts/order';
import React from 'react';
import { useStopwatch } from 'react-timer-hook';

const Timer = () => {
    const fix = (n) => n.toString().length < 2 ? '0' + n : n
    const { seconds, minutes, hours, isRunning, start, pause, reset } = useStopwatch({ autoStart: true })
    const {elapsedTime} = React.useContext(getOrderContext)
    return (
        <span ref={elapsedTime} data-elapsedtime={`${hours ? fix(hours) + ':' : ''}${minutes ? fix(minutes) + ':' : '00:'}${seconds ? fix(seconds) + '' : '00'}`}>
            {hours ? fix(hours) + ':' : ''}
            {minutes ? fix(minutes) + ':' : '00:'}
            {seconds ? fix(seconds) + '' : '00'}
        </span>
    );
};

export default Timer;
