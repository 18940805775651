// --ADDRESS--

export const formEditAddress = {
    city: { label: 'Хот', type: 'Text' },
    district: { label: 'Дүүрэг', type: 'SelectDistrict' },
    horoo: { label: 'Хороо', type: 'SelectKhoroo' },
    bairname: { label: 'Хаяг', type: 'Text' },
    bairnote: { label: 'Албан бус хаяг', type: 'Text' },
    podcode: { label: 'Х/Бүс', type: 'Text', required: true },
    podph: { label: 'PH Х/Бүс', type: 'Text' },
};

//--COMPLAINT--
export const formComplaint = {
    category: { label: 'Ангилал', type: 'Text' },
    channel: { label: 'Суваг', type: 'Text', required: true },
    custComplaint: { label: 'Гомдол', type: 'Text' },
    custPhone: { label: 'Хэрэглэгчийн утас', type: 'Text' },
    isSolved: { label: 'Шийдвэрлэсэн эсэх', type: 'Boolean' },
    operator: { label: 'Оператор', type: 'Text' },
    orderNumber: { label: 'Захиалгын дугаар', type: 'Text' },
    store: { label: 'Салбар', type: 'SelectStore' },
    createdAt: { label: 'Бүртгэсэн огноо', type: 'SelectDate' },
    resolvedAt: { label: 'Шийдвэрлэсэн огноо', type: 'SelectDate' },
    resolvedEmp: { label: 'Шийдвэрлэсэн хэрэглэгч', type: 'Text' },
    resolvedNote: { label: 'Шийдвэрлэсэн тэмдэглэл', type: 'Text' },
};
export const formComplaintCategory = {
    name: { label: 'Төрлийн нэр', type: 'Text', required: true },
    level: { label: 'Түвшин', type: 'Number', required: true },
};
export const formSolveComplaint = {
    category: { label: 'Ангилал', type: 'Text', disabled: true },
    custComplaint: { label: 'Гомдол', type: 'Textarea', disabled: true },
    custPhone: { label: 'Хэрэглэгчийн утас', type: 'Text', disabled: true },
    store: { label: 'Салбар', type: 'Text', disabled: true },
    // resolvedAt: { label: 'resolvedAt', type: 'Text' },
    // resolvedEmp: { label: 'resolvedEmp', type: 'Text' },
    resolvedNote: {
        label: 'Шийдвэрлэсэн тэмдэглэл',
        type: 'Textarea',
        required: true,
    },
};
export const formCreateComplaint = {
    custComplaint: { label: 'Гомдол', type: 'Textarea', required: true },
    orderNumberLabel: { label: 'Д/Д', type: 'Text', required: true, readOnly: true, watch : "orderNumber" },
    orderNumber: { label: 'Захиалгын дугаар', type: 'SelectSeqFromNum', required: true },
    store: { label: 'Салбар', type: 'SelectStore', required: true },
    orderDate: { label: 'Хэзээ', type: 'SelectDatetime' },
    operator: { label: 'Оператор', type: 'Text' },
};

//--ENQUIRY--
export const formCreateEnquiry = {
    name: { label: 'Гарчиг', type: 'Text' },
    note: { label: 'Дэлгэрэнгүй', type: 'Richtext' },
    sort: {label: 'Эрэмбэлэх дугаар', type: 'Text'},
};

//--SUGGESTION--
export const formSuggestion = {
    category: { label: 'Төрөл', type: 'Text' },
    operator: { label: 'Бүртгэсэн хэрэглэгч', type: 'Text' },
    custPhone: { label: 'Утасны дугаар', type: 'Text' },
    // channel: { label: 'Суваг', type: 'Text' },
    createdAt: { label: 'Үүсгэсэн огноо', type: 'SelectDate' },
    orderNumber: { label: 'Захиалгын дугаар', type: 'Text' },
    custSuggestion: { label: 'Тэмдэглэл', type: 'Text' },
    isSolved: { label: 'Шийдвэрлэсэн эсэх', type: 'Boolean' },
    // orderDate: { label: 'orderDate', type: 'Text' },
    store: { label: 'Салбар', type: 'SelectStore' },
    resolvedAt: { label: 'Шийдвэрлэсэн огноо', type: 'SelectDate' },
    resolvedEmp: { label: 'Шийдвэрлэсэн ажилтан', type: 'Text' },
    resolvedNote: { label: 'Хэрхэн шийдвэрлэсэн тэмдэглэл', type: 'Text' },
};
export const formSuggestionCategory = {
    name: { label: 'Төрлийн нэр', type: 'Text', required: true },
    level: { label: 'Түвшин', type: 'Number' },
};
export const formCreateSuggestion = {
    custSuggestion: { label: 'Хүсэлт', type: 'Textarea', required: true },
    operator: { label: 'Оператор', type: 'Text', required: true },
    orderDate: { label: 'Хэзээ', type: 'SelectDatetime' },
    orderNumber: { label: 'Захиалгын дугаар', type: 'Text' },
    store: { label: 'Салбар', type: 'SelectStore' },
};
export const formSolveSuggestion = {
    category: { label: 'Ангилал', type: 'Text', disabled: true },
    custSuggestion: { label: 'Хүсэлт', type: 'Textarea', disabled: true },
    custPhone: { label: 'Хэрэглэгчийн утас', type: 'Text', disabled: true },
    store: { label: 'Салбар', type: 'Text', disabled: true },
    // resolvedAt: { label: 'resolvedAt', type: 'Text' },
    // resolvedEmp: { label: 'resolvedEmp', type: 'Text' },
    resolvedNote: {
        label: 'Шийдвэрлэсэн тэмдэглэл',
        type: 'Textarea',
        required: true,
    },
};

// --STORE--
export const formEditStore = {
    storeName: { label: 'Дэлгүүрийн нэр', type: 'Text', disabled: true },
    haveAdvanced: {
        label: 'Урьдчилж захиалах',
        type: 'Boolean',
        disabled: true,
    },
    haveDelivery: { label: 'Хүргэлт', type: 'Boolean', disabled: true },
    havePickup: { label: 'Очиж авах', type: 'Boolean', disabled: true },
    minDeliveryAmount: {
        label: 'Хамгийн бага хүргэлтийн дүн',
        type: 'Text',
        disabled: true,
    },
    minPickupAmount: {
        label: 'Хамгийн бага захиалгын дүн',
        type: 'Text',
        disabled: true,
    },
    freeDelivery: {
        label: 'Үнэгүй хүргэлттэй эсэх',
        type: 'Text',
        disabled: true,
    },
    status: { label: 'Статус', type: 'Text', disabled: true },
    quickMessage: { label: 'Туслах мессеж', type: 'Text' },
};

// --STOREPOD
export const formEditStorePod = {
    podCode: { label: 'podcode', type: 'Text' },
    storeName: { label: 'storename', type: 'Text' },
    isActive: { label: 'is_active', type: 'Boolean' },
    created_at: { label: 'created_at', type: 'SelectDate' },
    updated_at: { label: 'updated_at', type: 'SelectDate' },
};

// --SCHEDULE
export const formEditStoreSchedule = {
    startTime: { label: 'Нээх цаг', type: 'SelectTime' },
    endTime: { label: 'Хаах цаг', type: 'SelectTime' },
    endTimeShift: { label: 'Дараа өдөртөө орох', type: 'Boolean' },
    haveRedirect: { label: 'Шилжүүлэх эсэх', type: 'Boolean' },
};

// --ORDER PLACE
export const formOrderPlace = {
    note: { label: 'Тэмдэглэл', type: 'Textarea' },
    additionalPhone: { label: 'Нэмэлт утас', type: 'Text' },
    noatType: { label: 'НӨАТ', type: 'SelectNoat', required: true },
};

// --CANCEL REQUEST
export const formCancelRequest = {
    orderNumber: { label: 'Захиалгын дугаар', type: 'Text' },
    orderTotal: { label: 'Захиалгын дүн', type: 'Text' },
    reason: { label: 'Шалтгаан', type: 'SelectReason', required: true },
    comment: { label: 'Тайлбар', type: 'Textarea' },
};
