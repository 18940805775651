import axios from 'axios';
import Button from 'components/Button';
import Table from 'components/Table';
import React from 'react';
import { tableEditAddress } from 'utils/tableModels';
import { formEditAddress } from 'utils/formModels';
import { AiFillEdit, AiFillClockCircle, AiOutlineSearch } from 'react-icons/ai';
import Title from 'components/Title';
import Dialog from 'components/Dialog';
import Form from 'components/Form';
import { popupFormStyle } from 'utils/sharedStyles';
import Div from 'components/Div';
import styled from 'styled-components';
import { getReferenceContext } from 'contexts/reference';
import { NotificationManager } from 'react-notifications';
import { Auth } from 'aws-amplify';

const Editaddress = () => {
    const [data, setData] = React.useState([]);
    const [instance, setInstance] = React.useState(null);
    const [formValues, setFormValues] = React.useState({});
    const { setIsLoading } = React.useContext(getReferenceContext);
    const formRef = React.useRef();
    const fetchData = async (e) => {
        e?.preventDefault();
        const { district, horoo, address } = formValues;
        try {
            setIsLoading(true);
            // const jwt = localStorage.getItem('phjwt');
            const {
                accessToken: { jwtToken },
            } = await Auth.currentSession();
            const result = await axios(`${window.url.server}/search?address=${address}`, { headers: { Authorization: `Bearer ${jwtToken}` } });
            setData({ rows: result.data.data, count: 0 });
        } catch (e) {
        } finally {
            setIsLoading(false);
        }
    };
    const handleSave = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            // const jwt = localStorage.getItem('phjwt');
            const {
                accessToken: { jwtToken },
            } = await Auth.currentSession();
            await axios.post(`${window.url.server}/updateAddress`, { ...instance }, { headers: { Authorization: `Bearer ${jwtToken}` } });
            NotificationManager.success('Амжилттай шинэчлэгдлээ', '', 3000);
        } catch (e) {
        } finally {
            setIsLoading(false);
            setInstance(null);
        }
    };
    React.useEffect(() => {
        fetchData();
    }, []);
    return (
        <Container>
            <Title btmLine size={20}>
                Хаяг
            </Title>
            <Div c="filter_wrap">
                <Form
                    onSubmit={fetchData}
                    listenChange={setFormValues}
                    styles={addressFormStyle}
                    model={{ district: { label: 'Дүүрэг', type: 'SelectDistrict' }, horoo: { label: 'Хороо', type: 'SelectKhoroo' }, address: { label: 'Хаяг', type: 'Text' } }}
                    instance={{ address: '1' }}
                />
                <Button bg="blue" onClick={fetchData}>
                    <AiOutlineSearch />
                    Хайх
                </Button>
            </Div>
            <br />
            <br />
            <Title>Хайсан хаяг:</Title>
            <Table
                data={data}
                model={{
                    ...tableEditAddress,
                    custom: {
                        label: '',
                        jsx: ({ thisInstance }) => (
                            <Div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
                                <Button size="s" bg="blue" onClick={() => setInstance(thisInstance)}>
                                    <AiFillEdit />
                                    Засах
                                </Button>
                                <Button size="s" bg="white" color="inherit">
                                    <AiFillClockCircle />
                                    Түүх харах
                                </Button>
                            </Div>
                        ),
                    },
                }}
            />
            {instance && (
                <Dialog
                    submit={
                        <Button onClick={() => formRef.current.querySelector('.submit').click()} bg="blue" type="submit">
                            Хаяг засах
                        </Button>
                    }
                    title="Хаяг засах"
                    onClose={() => setInstance(null)}
                >
                    <Form listenChange={(v) => setInstance(v)} forwardRef={formRef} onSubmit={handleSave} instance={instance} model={formEditAddress} styles={popupFormStyle} />
                </Dialog>
            )}
        </Container>
    );
};

export default Editaddress;

const Container = styled.div`
    .filter_wrap {
        display: flex;
        gap: 15px;
        align-items: flex-start;
        & > form {
            flex: 1;
        }
    }
`;

const addressFormStyle = `
    display:flex;
    gap:15px;
    & > div {
        display:flex;
        flex-direction:column-reverse;
        &:first-child{
            flex:1;
        }
        &:nth-child(2){
            flex:1;
        }
        &:nth-child(3){
            flex:3;
        }
        & > * {
            &:first-child{
                opacity:0.6;
                margin-top:6px;
                display:block;
                font-size:10px;
            }
            &:last-child{
            }
        }
    }
`;
