import React, { useEffect } from 'react';
import styled from 'styled-components';
import ColumnAction from './ColumnAction';
import ReactPaginate from 'react-paginate';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import * as ColTypes from './Types';

const Table = ({ model, data, orderByColumnCb, bordered, paginationSetting, setPaginationSetting }) => {
    const perPage = paginationSetting?.limit ?? 10;
    const rows = data?.rows ?? [];
    const rowCount = data?.count ?? 0;
    const pageCount = Math.ceil(rowCount / perPage);
    useEffect(() => {
        if (setPaginationSetting) {
            setPaginationSetting({ limit: perPage, offset: 0 });
        }
    }, [pageCount]);
    return (
        <React.Fragment>
            <Container data-scrollable bordered={bordered}>
                <table cellSpacing={0}>
                    <thead>
                        <tr>
                            {Object.keys(model).map((fieldName) => {
                                const fieldObject = model[fieldName];
                                if (fieldObject.columnOrder || fieldObject.columnFilter)
                                    return (
                                        <ColumnAction
                                            key={fieldName}
                                            data={rows}
                                            fieldName={fieldName}
                                            orderByColumnCb={orderByColumnCb}
                                            order={fieldObject.columnOrder}
                                            filter={fieldObject.columnFilter}
                                            sticky={fieldObject.sticky}
                                            label={fieldObject.label}
                                        />
                                    );
                                return (
                                    <th key={fieldName} data-sticky={fieldObject.sticky}>
                                        {fieldObject.label}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.length ? (
                            rows.map((instance, i) => (
                                <tr key={instance.id ?? 0 + Math.random()}>
                                    {Object.keys(model).map((fieldName) => {
                                        const fieldObject = model[fieldName];
                                        const Jsx = fieldObject.jsx;
                                        const Type = ColTypes[fieldObject.type];
                                        if (Type) return <Type key={fieldName + i} data-sticky={fieldObject.sticky} data-content={fieldObject.filterable} value={instance[fieldName]} />;
                                        return (
                                            <td key={fieldName + i} data-sticky={fieldObject.sticky} data-content={fieldObject.filterable}>
                                                {Jsx ? <Jsx thisInstance={instance} index={i} /> : instance[fieldName]}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className="noresult" colSpan={Object.keys(model).length}>
                                    Илэрц олдсонгүй
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Container>
            <PaginationWrap>
                Нийт: {rowCount}
                <ReactPaginate
                    breakLabel='...'
                    nextLabel={<BsChevronRight />}
                    previousLabel={<BsChevronLeft />}
                    onPageChange={({ selected }) => setPaginationSetting({ limit: perPage, offset: selected * perPage })}
                    pageRangeDisplayed={3}
                    pageCount={pageCount}
                />
            </PaginationWrap>
        </React.Fragment>
    );
};

export default Table;

const Container = styled.div`
    position: relative;
    overflow: auto;
    white-space: nowrap;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    &::-webkit-scrollbar-thumb {
        background-color: rgb(80 166 255 / 40%);
        border-radius: 4px;
        &:hover {
            background-color: rgb(80 166 255 / 100%);
        }
    }
    &::-webkit-scrollbar {
        height: 6px;
    }
    table {
        color: #606266;
        font-size: 12px;
        line-height: 23px;
        width: 100%;
        tr {
            th {
                border-top: 1px solid #ebeef5;
                border-bottom: 1px solid #ebeef5;
                ${({ bordered }) => bordered && `border-left:1px solid #ebeef5;`};
                padding: 15px 10px;
                user-select: none;
                text-align: left;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
                background: #fbfbfb;
                ${({ bordered }) => bordered && `text-align:center;`};
                &:last-child {
                    ${({ bordered }) => bordered && `border-right:1px solid #ebeef5;`};
                }
            }
            td {
                border-bottom: 1px solid #ebeef5;
                ${({ bordered }) => bordered && `border-left:1px solid #ebeef5;`};
                padding: 15px 10px;
                &:last-child {
                    ${({ bordered }) => bordered && `border-right:1px solid #ebeef5;`};
                }
            }
            td.noresult {
                text-align: center;
                background: rgba(0, 0, 0, 0.02e);
                opacity: 0.8;
            }
            &:hover {
                background: #fbfbfb;
            }
        }
        tr {
            td[data-sticky='true'],
            th[data-sticky='true'] {
                position: sticky;
                // background-color: white;
                right: 0px;
                // border-left:1px solid #FBFBFB;
                padding-left: 15px;
                padding-right: 15px;
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 80px;
                    margin-left: -80px;
                    height: 100%;
                    background: linear-gradient(270deg, #fbfbfb, transparent);
                }
            }
            td[data-sticky='true'] {
                background: #fbfbfb;
                box-shadow: -2px 0px 0px 0px rgba(255, 255, 255, 0.05);
                // border-top-left-radius:8px;
                // border-bottom-left-radius:8px;
            }
        }
    }
`;

const PaginationWrap = styled.div`
    display:flex;
    gap:10px;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    margin-top: 15px;
    ul {
        display: flex;
        li {
            list-style-type: none;
            background: #f0f0f0;
            cursor: pointer;
            transition: 0.3s ease;
            &:nth-child(2) {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }
            a {
                min-width: 30px;
                min-height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.3s ease;
                position: relative;
                user-select: none;
            }
        }
        li.previous {
            margin-right: 6px;
            border-radius: 8px;
        }
        li.next {
            margin-left: 6px;
            border-radius: 8px;
        }
        li.selected {
            a {
                border-radius: 8px;
                color: white;
                background: ${({ theme }) => theme.blue};
                box-shadow: 0px 0px 4px 1px ${({ theme }) => theme.blue};
            }
        }
    }
`;
