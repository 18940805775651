import Tag from 'components/Tag';
import React from 'react';
import styled from 'styled-components';
import { FormatDateFull } from 'utils/date';
import { timeSince } from 'utils/helpers';

const StaticType = ({ children, ...props }) => {
    const resolveType = (value, type) => {
        if (type === "Text") return value
        if (type === "Boolean") return value ? <Tag color="green">Тийм</Tag> : <Tag color="color">Үгүй</Tag>
        if (type === "SelectDate") return value ? FormatDateFull(value) : ''
        else return children
    }
    return (
        <Container {...props}>
            {resolveType(children, props.type)}
        </Container>
    );
};

export default StaticType;

const Container = styled.div`

`