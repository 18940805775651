import { getAuthContext } from 'contexts/auth';
import SelectHoc from 'contexts/SelectHoc';
import React from 'react';

const cityOptions = [
    { label: 'УБ', value: 'УБ' },
    { label: 'Дархан', value: 'Дархан' },
];
const SelectCity = ({ value: firstValue, onChange, onChangeClean, onChangeMultiple, ...props }) => {
    const [value, setValue] = React.useState(cityOptions[0]);
    const { user } = React.useContext(getAuthContext);
    React.useEffect(() => {
        if (user) fetchData();
    }, [firstValue, user]);
    const fetchData = () => {
        const findSelectedValue = cityOptions.find((m) => m.value === firstValue);
        if (findSelectedValue) {
            setValue(findSelectedValue);
        }
    };
    const forwardChange = (e) => {
        if (e?.value !== value?.value) {
            onChangeMultiple && onChangeMultiple({ city: e.value, district: '', horoo: '' });
        }
    };
    return <SelectHoc value={value} onChange={forwardChange} options={cityOptions} {...props} />;
};

export default SelectCity;
