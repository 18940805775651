import 'react-notifications/lib/notifications.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Outlet } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Signin from './pages/__Signin';
import { AuthContextProvider } from './contexts/auth';
import { SocketContextProvider } from 'contexts/socket';
import { ThemeProvider } from 'styled-components';
import * as theme from './utils/theme';
import Index from './pages/Index';
import Main from './pages/Main';
import { Amplify } from 'aws-amplify';
import configFile from './config.json';
import Register from 'pages/__Register';
import { NotificationContainer } from 'react-notifications';
import { ReferenceContextProvider } from 'contexts/reference';
import Editaddress from 'pages/Editaddress';
import Forgot from 'pages/__Forgot';
import ConfirmEmail from 'pages/__Confirm';
import CallPage from 'pages/Call';
import Complaint from 'pages/Complaint';
import AuthEntry from 'pages/entry';
import Enquiry from 'pages/Enquiry';
import Suggestion from 'pages/Suggestion';
import Order from 'pages/Order';
import OrderRequest from 'pages/Order/Request';
import Store from 'pages/Store';
import StorePod from 'pages/StorePod';
import Process from 'pages/Process';
import Dashboard from 'pages/Dashboard';
import SuggestionRequest from 'pages/Suggestion/Request';
import GenericEntry from 'utils/GenericEntry';

const root = ReactDOM.createRoot(document.getElementById('root'));
Amplify.configure(configFile.awsmobile);
window.url = configFile.url;

const routes = [
    { path: '', element: Index },
    { path: 'main', element: Main },
    { path: 'call', element: Main },
    { path: 'address', element: CallPage },
    { path: 'editaddress', element: Editaddress },
    { path: 'complaint', element: Complaint },
    { path: 'enquiry', element: Enquiry },
    { path: 'suggestion', element: Suggestion },
    { path: 'order', element: Order },
    { path: 'order-request', element: OrderRequest },
    { path: 'store', element: Store },
    { path: 'store-pod', element: StorePod },
    { path: 'suggestion-request', element: SuggestionRequest },
    { path: 'process', element: Process },
    { path: 'dashboard', element: Dashboard },
];

root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <SocketContextProvider>
                <AuthContextProvider>
                    <ReferenceContextProvider>
                        <Routes>
                            <Route path="/" element={<AuthEntry />}>
                                {routes.map(({ path, element: Element }) => {
                                    return (
                                        <Route key={path} path={path} element={<GenericEntry />}>
                                            <Route index element={<Element />} />
                                            <Route path=":custPhone" element={<Element />} />
                                        </Route>
                                    );
                                })}
                            </Route>
                            <Route path="/login" element={<Signin />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/forgot" element={<Forgot />} />
                            <Route path="/confirm" element={<ConfirmEmail />} />
                        </Routes>
                    </ReferenceContextProvider>
                </AuthContextProvider>
            </SocketContextProvider>
            <NotificationContainer />
        </ThemeProvider>
    </BrowserRouter>
    // </React.StrictMode>
);
