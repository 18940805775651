import axios from 'axios';
import Foodcart from 'components/Foodcart';
import React from 'react';
import styled from 'styled-components';

const Order = () => {
    return (
        <Container>
            <Foodcart />
        </Container>
    );
};

export default Order;

const Container = styled.div`

`
