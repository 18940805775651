import Button from 'components/Button';
import Div from 'components/Div';
import Table from 'components/Table';
import Tag from 'components/Tag';
import { getOrderContext } from 'contexts/order';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';
import { timeSince } from 'utils/helpers';
import Req from 'utils/Request';
import { getUniqueListBy } from 'utils/vanillaFunctions';

const PrevAddressList = ({ setDeliveryInstance }) => {
    const { custPhone } = React.useContext(getOrderContext);
    const [addressData, setAddressData] = React.useState([]);
    React.useEffect(() => {
        if (custPhone) fetchPrevAddresses();
    }, []);
    const fetchPrevAddresses = async () => {
        const result = await Req({
            url: '/order/filterOrders',
            body: { phone: custPhone, page: { size: 10, number: 1 } },
            hideSuccess: true,
        });
        const resultRows = result.data.data.data
        const resultWithDetail = []
        await Promise.all(
            resultRows.map(async (row) => {
                const detail = await Req({ url: '/order/getOrderById', body: { id: row.id }, hideSuccess: true });
                resultWithDetail.push(detail.data.data.order);
            })
        );
        const deliveryAddressesOnly = resultWithDetail.filter((x) => x.orderType === 'delivery');
        const uniqueAddresses = getUniqueListBy(deliveryAddressesOnly, 'addrFullAddress');

        setAddressData(uniqueAddresses);
    };
    const handleClick = async (thisInstance) => {
        const pairs = thisInstance.addrDetails.split(', ');
        const addrDetails = {};
        pairs.forEach((pair) => {
            const [key, value] = pair.split(': ');
            addrDetails[key] = value === 'undefined' ? undefined : value;
        });
        try {
            const result = await Req({
                url: `/searchByAddress?district=&horoo=&address=${thisInstance.addrFullAddress}`,
                type: 2,
                hideSuccess: true,
                method: 'get',
            });
            const resultRows = result.data.data;
            const filterOne = resultRows.filter((x) => x.full_address === thisInstance.addrFullAddress);
            setDeliveryInstance({ address: JSON.stringify(filterOne[0]),orts : addrDetails['Орц'], ortsKod: addrDetails['Орц код'],haalga : addrDetails['Хаалга'], additional : addrDetails['Нэмэлт']});
            NotificationManager.success(filterOne[0].full_address + ' | ' + filterOne[0].bairnote, 'Хаяг амжилттай сонгогдлоо');
            NotificationManager.warning('Хаяг баталгаажуулна уу');
        } catch (e) {
            NotificationManager.warning('Хаяг сонголт амжилтгүй');
        }
    };
    return (
        <Container>
            {addressData.length ? (
                <Table
                    data={{ rows: addressData, count: addressData.length }}
                    model={{
                        addrFullAddress: { label: 'Хаяг' },
                        addrDetails: { label: 'Дэлгэрэнгүй' },
                        custom2: {
                            label: 'Захиалсан хугацаа',
                            jsx: ({ thisInstance }) => <Tag color="green">{timeSince(thisInstance.createdAt)}-ийн өмнө</Tag>,
                        },
                        custom: {
                            label: '',
                            jsx: ({ thisInstance }) => (
                                <Button onClick={() => handleClick(thisInstance)} bg="green">
                                    Сонгох
                                </Button>
                            ),
                        },
                    }}
                />
            ) : (
                ''
            )}
        </Container>
    );
};

export default PrevAddressList;

const Container = styled.div``;
