import React from 'react';
import styled from 'styled-components';
import { BsFillHouseDoorFill } from 'react-icons/bs';
import { FaDesktop, FaTruck, FaShoppingCart, FaMarker, FaMapMarkerAlt, FaHandshake, FaLightbulb, FaHeadphonesAlt, FaStore, FaCheck } from 'react-icons/fa';
import { MdHowToVote } from 'react-icons/md';
import { GiReceiveMoney } from 'react-icons/gi';
import { getReferenceContext } from 'contexts/reference';
import { NavLink } from 'react-router-dom';
import { VscLightbulbAutofix } from 'react-icons/vsc';
import { getOrderContext } from 'contexts/order';
import { getAuthContext } from 'contexts/auth';

const SideMenu = () => {
    const { headerHeight } = React.useContext(getReferenceContext);
    const { customerMode, custPhone } = React.useContext(getOrderContext);
    const { roles } = React.useContext(getAuthContext);
    const [info, setInfo] = React.useState(null);
    React.useEffect(() => {
        const filtered = roles.filter((x) => x === 'INFO');
        if (filtered[0]) setInfo(filtered[0]);
    }, [roles]);

    let staticMenu = [
        { label: 'Нүүр', icon: FaDesktop, path: customerMode ? '/call' : '/main' },
        { label: 'Хяналт', icon: FaDesktop, path: '/dashboard', role: 'info' },
        { label: 'Явц', icon: FaTruck, path: '/process' },
        { label: 'Захиалга', icon: FaShoppingCart, path: '/order' },
        // { label: 'Хаяг', icon: FaMapMarkerAlt, path: '/editaddress' },
        { label: 'Хаяг-Очиж авах', icon: GiReceiveMoney, path: '/address' },
        { label: 'Гомдол', icon: FaHandshake, path: '/complaint' },
        { label: 'Санал хүсэлт', icon: FaLightbulb, path: '/suggestion' },
        {
            label: 'Хаягын хүсэлт',
            icon: VscLightbulbAutofix,
            path: '/suggestion-request',
        },
        { label: 'Лавлагаа', icon: MdHowToVote, path: '/enquiry' },
        // { label: 'Лавлагаа', icon: FaHeadphonesAlt, path: '/lavlagaa' },
        { label: 'Салбар', icon: FaStore, path: '/store' },
        { label: 'Цуцлах хүсэлт', icon: FaCheck, path: '/order-request' },
        // { label: 'Pod', icon: FaMarker, path: '/store-pod' }
    ];

    if (!custPhone) staticMenu = staticMenu.filter((x) => x.path !== '/order');

    return (
        <Container data-scrollable headerHeight={headerHeight}>
            {staticMenu.map(
                (menu) =>
                    (!menu.role || (menu.role === 'info' && info)) && (
                        <NavLink to={customerMode ? menu.path + '/' + custPhone : menu.path} key={Math.random()}>
                            <div className="menu-item">
                                <menu.icon />
                                <span>{menu.label}</span>
                            </div>
                        </NavLink>
                    )
            )}
        </Container>
    );
};

export default SideMenu;

const Container = styled.div`
    box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 1px;
    width: fit-content;
    overflow: hidden;
    ${({ headerHeight }) => `
        max-height:calc(100vh - ${headerHeight}px);
    `};
    .menu-item {
        width: 100px;
        height: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.05);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        flex-direction: column;
        svg {
            font-size: 27px;
        }
        span {
            font-size: 12px;
            margin-top: 4px;
            text-align: center;
        }
    }
    .menu-item:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    a {
        color: inherit;
        text-decoration: none;
        &.active {
            .menu-item {
                background: white;
                color: ${({ theme }) => theme.green};
                &:hover {
                    cursor: default;
                    opacity: 1;
                }
            }
        }
    }
`;
