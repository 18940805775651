import React from 'react';
import { TimePicker } from 'react-ios-time-picker';
import styled from 'styled-components';

const TimeHoc = (props) => (
    <Container><TimePicker placeHolder='Цаг сонгох' {...props} /></Container>
)

export default TimeHoc;

const Container = styled.div`
    input{
        padding: 6px 10px;
        border-radius:4px;
        border:1px solid hsl(0,0%,80%);
    }
`