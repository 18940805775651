import Div from 'components/Div';
import React from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import styled from 'styled-components';
import { FormatDateFull } from 'utils/date';

const ProcessLine = ({detail}) => {
    const actionLength = detail.orderActions.length
    const orderActions = [...detail.orderActions]
    let count = detail.orderActions.length;
    if(detail.order?.action === 'CanceledByInfo') {}
    else if(detail.order?.orderType === 'pickup') {
        count = count <= 3 ? 3 : count
    } else if (detail.order?.orderType === 'delivery') {
        count = count <= 4 ? 4 : count
    }
    //CanceledByInfo zurahgui
    //pickup 3aaraa
    //delivery 5

    if(actionLength < count) {
        const pushCount = count - actionLength
        for(let i=0; i < pushCount; i++) {
            orderActions.push({actionText: 'Тодорхойгүй', indefinite: true})
        }
    }
    return (
        <Container>
            {orderActions.map((action, i) => (
                <Div c="lineInstance" data-indefinite={action.indefinite} key={action?.id ?? i}>
                    <Div c="checkwrap"><AiOutlineCheck/></Div>
                    <Div c="caption">{action.actionText}</Div>
                    <Div c="date">{action.created_at ? FormatDateFull(action.created_at) : '.........................'}</Div>
                </Div>
            ))}
        </Container>
    );
};

export default ProcessLine;

const Container = styled.div `
    display:flex;
    padding:30px 0px;
    color:${({theme})=>theme.green};
    justify-content:center;
    .lineInstance{
        position:relative;
        min-width:200px;
        text-align:center;
        .caption{
            margin:15px 0px;
            font-weight:500;
        }
        .checkwrap{
            width:fit-content;
            margin:auto;
            width:24px;
            height:24px;
            border:2px solid ${({theme})=>theme.green};
            color:${({theme})=>theme.green};
            border-radius:100%;
            display:flex;
            justify-content:center;
            align-items:center;
            background:white;
            position:relative;
            z-index:1;
        }
        &:before{
            content: "";
            display:block;
            width:5px;
            height:2px;
            background:${({theme})=>theme.green};
            position:absolute;
            left:0px;
            width:50%;
            top:10px;
        }
        &:after{
            content: "";
            display:block;
            width:5px;
            height:2px;
            background:${({theme})=>theme.green};
            position:absolute;
            left:50%;
            width:50%;
            top:10px;
        }
        &:first-child{
            &:before{
                display:none;
            }
        }
        &:last-child{
            &:after{
                display:none;
            }
        }
    }
    [data-indefinite="true"]{
        color:rgba(0,0,0,0.6);
        .checkwrap{
            color:rgba(0,0,0,0.6);
            border-color:rgba(0,0,0,0.6);
        }
        &:before{
            background:rgba(0,0,0,0.6);
        }
        &:after{
            background:rgba(0,0,0,0.6);
        }
    }
`