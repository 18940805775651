import SelectHoc from 'contexts/SelectHoc';
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Req from 'utils/Request';

const SelectStore = ({ value: firstValue, onChange, onChangeClean,byRole,saveAddress=false, ...props }) => {
    const [data, setData] = React.useState([]);
    const [value, setValue] = React.useState({});
    const [searchParams] = useSearchParams();
    let system = true;
    React.useEffect(() => {
        if (window.location.pathname.indexOf('complaint') !== -1) {
            system = false;
        }
        fetchData();
    }, [firstValue]);
    let urlConfig = { url: `/store/list`, hideSuccess: true, body: {}, disableLoader:false };
    const fetchData = async () => {
        if (!system) {
            urlConfig.body = { isSystem: false  };
        }
        if(byRole) {
            urlConfig.body = {...urlConfig.body, byRole:true}
        }
        const result = await Req(urlConfig);
        const sortedData = result.data.data.sort(function(a, b){return a.storeNo - b.storeNo});
        const mappedResult = sortedData.map((d) => ({ value: d.storeName, label: `${d.storeNameMn??d.storeName}`, address:d.address }));
        const findSelectedValue = mappedResult.find((m) => m.value === firstValue);
        setValue(findSelectedValue);
        setData(mappedResult);
    };
    const forwardChange = (e) => {
        saveAddress && saveAddress(e?.address);
        onChange && onChange(e);
        onChangeClean && onChangeClean(e?.value);
    };
    return <SelectHoc value={value} onChange={forwardChange} options={data} {...props} />;
};

export default SelectStore;
