import Table from 'components/Table';
import { useEffect, useState } from 'react';
import Req from 'utils/Request';
import Button from 'components/Button';
import Tag from 'components/Tag';
import styled from 'styled-components';


const CheckStore = () => {
    const [tableData, setTableData] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);
    //functions
    const fetchData = async () => {
        const result = await Req({
            url: '/store/list',
            hideSuccess: true,
        });
        const mappedData = result.data.data.map((item, index) => {
            return {
              storeName: item.storeName,
              hrsUrl: '',
              success: '',
            };
          });
        setData(mappedData);
        setTableData(mappedData);
    };
    const fetchCheckStore = () =>{
        data.forEach((store) => {
            let newArray = [...tableData];
            const newDat = newArray.find(
              (item) => item.storeName === store.storeName
            );
            newDat.success = 'pending';
            newDat.hrsUrl = '';
            setTimeout(() => {
                setTableData([...newArray]);
            })
            Req({
                url: '/store/checkStoreEndpoint',
                hideSuccess: true,
                body: { storeName: store.storeName },
                disableLoader : false
            })
              .then((response) => {
                const {
                  data: { data: newData },
                } = response;
                let newArrayData = [...tableData];
                const updateItem = newArrayData.find(
                  (item) => item.storeName === newData.storeName
                );
                updateItem.success = newData.success;
                updateItem.hrsUrl = newData.hrsUrl;
                setTimeout(() => {
                  setTableData([...newArrayData]);
                });
              });
          });
    }

    return (
        <Container>
            <Button className='but' bg="blue" onClick={fetchCheckStore}>
                Салбар шалгах
            </Button>
            <Table
                className='table'
                data={{count: 0 , rows : tableData}}
                model={{
                    storeName: {
                        label: 'Салбар',
                    },
                    hrsUrl: {
                        label: 'Url',
                    },
                    success: {
                        label: 'Success',
                        jsx: ({ thisInstance }) => {
                                let text = thisInstance.success;
                                const color =
                                thisInstance.success === 'pending' ? 'black' : thisInstance.success === true ? 'green' : 'volcano';
                                return (
                                  <Tag color={color}>
                                    {text.toString().toUpperCase()}
                                  </Tag>
                                );
                        },
                    },
                }}
                // paginationSetting={pagination}
                // setPaginationSetting={setPagination}
            />
        </Container>
    )
};
const Container = styled.div`
    .but {
        margin-bottom:30px;
    }
`
export default CheckStore;
