import React from 'react';
import styled from 'styled-components';

const FlexDivider = ({grid = 5, gap = 15, children}) => {
    return (
        <Container grid={grid} gap={gap}>
            {children}
        </Container>
    );
};

export default FlexDivider;

const Container = styled.div `
    display:flex;
    flex-wrap:wrap;
    gap: ${({gap}) =>gap}px;
    & > .cardbox {
        flex: 0 0 calc(100% / ${({grid}) => grid} - ${({gap}) => gap}px + ${({gap}) => gap}px/${({grid}) => grid});
        max-width:calc(100% / ${({grid}) => grid} - ${({gap}) => gap}px + ${({gap}) => gap}px/${({grid}) => grid});
    }
`