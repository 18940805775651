import React from 'react';
import styled from 'styled-components';
import { MediaContextProvider } from './context';
import Main from './main';

const Medialibrary = ({ onClose }) => {
    const backgroundRef = React.useRef()
    const handleClose = () => {
        backgroundRef.current.classList.add('unfade')
        setTimeout(() => {
            onClose()
        }, 300);
    }
    return (
        <MediaContextProvider>
            <BackgroundFade onClick={(e) => e.target === backgroundRef.current && handleClose()} ref={backgroundRef}>
                <Main />
            </BackgroundFade>
        </MediaContextProvider>
    );
};

export default Medialibrary;

const BackgroundFade = styled.div`
    position:fixed;
    z-index:1001;
    left:0;
    right:0;
    top:0;
    bottom:0;
    animation: fade-in 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    @-webkit-keyframes fade-in{0%{opacity:0}100%{opacity:1}}@keyframes fade-in{0%{opacity:0}100%{opacity:1}}
    @-webkit-keyframes fade-out{0%{opacity:1}100%{opacity:0}}@keyframes fade-out{0%{opacity:1}100%{opacity:0}}
    &.unfade{
        animation: fade-out 0.3s ease-out both !important;
    }

    background:rgba(0,0,0,0.6);
    display:flex;
    justify-content:center;
    align-items:center;

`
