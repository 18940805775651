const FormatDate = (string, dotted) => {
    if (dotted) {
        let date = new Date(string)
        date = date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate()
        return date
        return
    }
    let date = new Date(string)
    date = date.getFullYear() + ' оны ' + (date.getMonth() + 1) + ' сарын ' + date.getDate()
    return date
}

export const formatDateWithDash = (date) => {
    date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return date
}
export const getDay = () => {
    const day = new Date().getDay()
    const currentDate = new Date();
    const date = new Date();
    const currentHour = currentDate.getHours();
    date.setDate(date.getDate() - 1);
    const yesterday = new Date(date).getDay();
    const calculatedHour =  currentHour < 8 ? yesterday : day;
    return calculatedHour
}

export const FormatDateFull = (string) => {
    const date = new Date(string);
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    let hours = date.getHours()
    let minutes = date.getMinutes()

    month = month.toString().length > 1 ? month : '0' + month
    day = day.toString().length > 1 ? day : '0' + day
    hours = hours.toString().length > 1 ? hours : '0' + hours
    minutes = minutes.toString().length > 1 ? minutes : '0' + minutes

    return year + ' оны ' + month + ' сарын ' + ' ' + day + ' (' + hours + ':' + minutes + ')'
}

export const FormatDateHourMinuteSecond = (string) => {
    const date = new Date(string);
    return date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
}

export default FormatDate