import React from 'react';
import Header from 'modules/Header';
import SideMenu from 'modules/SideMenu';
import styled from 'styled-components';
import { getReferenceContext } from 'contexts/reference';
import { getOrderContext } from 'contexts/order';
import OrderPanel from 'components/OrderPanel';

const Layout = ({ children }) => {

    const { headerHeight } = React.useContext(getReferenceContext)
    const { customerMode } = React.useContext(getOrderContext)

    return (
        <Container headerHeight={headerHeight}>
            <Header />
            <nav data-main>
                <SideMenu />
                <section data-main data-scrollable>
                    {children}
                </section>
                {customerMode && <OrderPanel />}
            </nav>
        </Container>
    );
};

export default Layout;

const Container = styled.div`
    max-height:100vh;
    display:flex;
    flex-direction:column;
    nav[data-main]{
        display:flex;
        section[data-main]{
            max-height:calc(100vh - ${({ headerHeight }) => headerHeight}px);
            flex:1;
            padding:30px;
        }
    }
`