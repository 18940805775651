import Div from 'components/Div';
import { getReferenceContext } from 'contexts/reference';
import React from 'react';
import { AiOutlineCaretRight } from 'react-icons/ai';
import styled from 'styled-components';
import { FormatDateFull, FormatDateHourMinuteSecond } from 'utils/date';
import Req from 'utils/Request';
import { formatNumber } from 'utils/vanillaFunctions';
import ProcessLine from '../Process/ProcessLine';

const RequestDetail = ({ processId }) => {
    const [detail, setDetail] = React.useState(null);
    const { getTranslation } = React.useContext(getReferenceContext);
    React.useEffect(() => {
        fetchDetail();
    }, []);
    const fetchDetail = async () => {
        const result = await Req({ url: '/order/getRequestById', body: { id: processId }, hideSuccess: true });
        console.log(result, 'res');
        console.log(result.data.data.request.request.comment, 'result.data.data.request');
        setDetail(result.data.data.request);
    };
    return (
        <>
            {detail && <ProcessLine detail={detail} />}
            {detail && (
                <Container>
                    <Div c="wrapper">
                        <Div c="instance">
                            <Div c="caption">Шалтгаан:</Div>
                            <Div c="info">{detail.request.reason}</Div>
                        </Div>
                        <Div c="instance">
                            <Div c="caption">Тэмдэглэл:</Div>
                            <Div c="info">{detail.request.comment}</Div>
                        </Div>
                        <Div c="instance">
                            <Div c="caption">Цуцлах хүсэлт өгсөн:</Div>
                            <Div c="info">{FormatDateFull(detail.request.createdAt)}</Div>
                        </Div>
                        <Div c="instance">
                            <Div c="caption">Захиалга өгсөн огноо:</Div>
                            <Div c="info">{FormatDateFull(detail.request.orderDate)}</Div>
                        </Div>
                        <Div c="instance">
                            <Div c="caption">Утасны дугаар:</Div>
                            <Div c="info">{detail.request.custPhone}</Div>
                        </Div>
                        <Div c="instance">
                            <Div c="caption">Захиалга төрөл</Div>
                            <Div c="info">
                                {detail.request.orderType === 'delivery' ? 'Хүргэлтээр' : 'Очиж авах'}&nbsp;
                                {detail.request.orderDate ? ' - ' + FormatDateFull(detail.request.orderDate) : '(Одоо)'}
                            </Div>
                        </Div>
                        <Div c="instance">
                            <Div c="caption">Хүргэх төрөл</Div>
                            <Div c="info">{detail.request.orderType === 'delivery' ? 'Хүргэлтээр' : 'Очиж авах'}</Div>
                        </Div>
                        <Div c="instance">
                            <Div c="caption">Салбар</Div>
                            <Div c="info">{detail.request.storeName}</Div>
                        </Div>
                        {detail.request.addrFullAddress ? (
                            <Div c="instance">
                                <Div c="caption">Хаяг</Div>
                                <Div c="info">
                                    {detail.request.addrFullAddress}
                                    <br />
                                    {detail.request.addrDetails?.split(',').map((x) => (
                                        <>
                                            {x}
                                            <br />
                                        </>
                                    ))}
                                </Div>
                            </Div>
                        ) : (
                            ''
                        )}
                    </Div>
                    <Div c="wrapper">
                        <Div c="instance">
                            <Div c="caption">Дүн</Div>
                            <Div c="info">{formatNumber(detail.request.totalsSubTotal)}₮</Div>
                        </Div>
                        <Div c="instance">
                            <Div c="caption">Хүргэлт</Div>
                            <Div c="info">{formatNumber(detail.request.totalsOtherTotals)}₮</Div>
                        </Div>
                        <Div c="instance">
                            <Div c="caption">Нийт дүн</Div>
                            <Div c="info">{formatNumber(detail.request.totalsTotalDue)}₮</Div>
                        </Div>
                        <Div c="instance">
                            <Div c="caption">НӨАТ</Div>
                            <Div c="info">
                                {detail.request.vatType === 1 && 'Хувь хүн'}
                                {detail.request.vatType === 2 && 'Хувь хүн регистер'}
                                {detail.request.vatType === 3 && 'Байгууллага'}
                            </Div>
                        </Div>
                        {detail.request.vatType === 3 && (
                            <Div c="instance">
                                <Div c="caption">Байгууллага</Div>
                                <Div c="info">{detail.request.vatName}</Div>
                            </Div>
                        )}
                        {detail.request.vatType === 2 && (
                            <Div c="instance">
                                <Div c="caption">Регистр</Div>
                                <Div c="info">{detail.request.vatRegno}</Div>
                            </Div>
                        )}
                        <Div c="instance">
                            <Div c="caption">Бүтээгдэхүүнүүд</Div>
                            <Div c="info">
                                {detail.orderMenu.map((menu) => (
                                    <>
                                        <AiOutlineCaretRight /> ({menu.count}ш) -- {getTranslation(menu.masterName)}
                                        <br />
                                    </>
                                ))}
                            </Div>
                        </Div>
                    </Div>
                </Container>
            )}
        </>
    );
};

export default RequestDetail;

const Container = styled.div`
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    & > .wrapper {
        padding: 30px;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 5px;
        &:first-child {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            padding-right: 15px;
            margin-right: 15px;
        }
        & > .instance {
            display: flex;
            gap: 15px;
            & > .caption {
                font-weight: 500;
                text-align: right;
                flex: 1;
                line-height: 28px;
            }
            & > .info {
                flex: 1;
                line-height: 28px;
                text-transform: capitalize;
                svg {
                    color: rgba(0, 0, 0, 0.75);
                }
            }
        }
    }
`;
