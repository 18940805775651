import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Box = ({ data: firstData, ...props }) => {
    const [data, setData] = useState({});
    useEffect(() => {
        setData(firstData);
    }, []);
    return (
        <Container {...props}>
            <div className="header">
                <strong>Салбар: {data.storename}</strong>
            </div>
            <div className="body">
                <div className="head-wrapper">
                    <div>
                        <h3 className="head-title">Захиалга</h3>
                    </div>
                    <div className="head-column">
                        <b>{data.total}</b>
                    </div>
                </div>
                <div className="row-flex">
                    <div>Баталгаажсан</div> <div className="head-column">{data.reachedmakeline}</div>
                </div>
                <div className="row-flex">
                    <div>Гал тогоо хүлээн авсан</div> <div className="head-column">{data.cook}</div>
                </div>
                <div className="row-flex">
                    <div>Захиалга бэлтгэгдсэн</div> <div className="head-column">{data.prepared}</div>
                </div>
                <div className="row-flex">
                    <div>Хүргэлтэнд гарсан</div> <div className="head-column">{data.dispatched}</div>
                </div>
                <div className="row-flex">
                    <div>Захиалга цуцлагдсан</div> <div className="head-column">{data.canceledbyinfo}</div>
                </div>
                <div className="row-flex">
                    <div>Амжилттай хүргэгдсэн</div> <div className="head-column">{data.delivered}</div>
                </div>
            </div>
        </Container>
    );
};

export default Box;

const Container = styled.div`
    // padding:30px;
    // box-shadow:0 2px 12px 0 rgb(0 0 0 / 10%);
    // // border:1px solid #ddd;
    // margin-top:30px;
    // margin-bottom:30px;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #ebeef5;
    background-color: #fff;
    color: #303133;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    .header {
        padding: 18px 20px;
        border-bottom: 1px solid #ebeef5;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    .body {
        padding: 20px;
    }
    .row-flex {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        width: 90%;
        padding: 4px 0;
        font-size: 16px;
    }
    .head-column {
        margin: 0 15px;
        font-size: 16px;
    }
    .head-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        width: 90%;
        margin-bottom: 10px;
        .head-title {
            margin: 5px;
            font-weight: 700;
            text-transform: uppercase;
            text-align: right;
        }
    }
`;
