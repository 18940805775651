import Div from 'components/Div';
import React from 'react';
import styled from 'styled-components';
import Weekday from './Weekday';

const Schedule = ({ data, storeName }) => {
    const weekDayArray = [1, 2, 3, 4, 5, 6, 7]
    return (
        <Container>
            <Div c="leftwrap">
                {weekDayArray.map(weekDay => {
                    const foundInstance = data.find(d => d.weekDay === weekDay)
                    return <Weekday storeName={storeName} key={weekDay} day={weekDay} data={foundInstance ?? {}} />
                })}
            </Div>
        </Container>
    );
};

export default Schedule;

const Container = styled.div`

`