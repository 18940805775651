import TimeHoc from 'contexts/TimeHoc';
import React from 'react';

const SelectTime = ({ required, onChange, onChangeClean, ...props }) => {
    const forwardChange = (e) => {
        onChange && onChange(e)
        onChangeClean && onChangeClean(e)
    }
    return <TimeHoc onChange={forwardChange} required={required} {...props} />
};

export default SelectTime;