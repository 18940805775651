export const popupFormStyle = `
    & > div {
        display:flex;
        padding:6px 0px;
        margin:10px 0px;
        gap:10px;
        & > * {
            font-size:14px;
            color:rgba(0,0,0,0.7);
            &:first-child{
                min-width:100px;
                flex:1;
                text-align:right;
                &:after{
                    content:":";
                }
            }
            &:last-child{
                flex:1;
                min-width:240px;
                max-width:240px;
            }
        }
    }
`

export const popupDetailStyle = `
    & > div {
        display:flex;
        padding:6px 0px;
        margin:10px 0px;
        gap:10px;
        & > * {
            color:rgba(0,0,0,0.7);
            font-size:14px;
            &:first-child{
                max-width:160px;
                min-width:160px;
                flex:1;
                text-align:right;
                &:after{
                    content:":";
                }
            }
            &:last-child{
                flex:1;
            }
        }
    }
`