import SelectHoc from 'contexts/SelectHoc';
import React from 'react';

const staticData = [{ value: 1, label: 'Даваа' }, { value: 2, label: 'Мягмар' }, { value: 3, label: 'Лхагва' }, { value: 4, label: 'Пүрэв' }, { value: 5, label: 'Баасан' }, { value: 6, label: 'Бямба' }, { value: 7, label: 'Ням' }]

const SelectWeekday = ({ value: firstValue, onChange, onChangeClean, ...props }) => {
    const [value, setValue] = React.useState(staticData.find(x => x.value === firstValue))
    const forwardChange = (e) => {
        onChange && onChange(e)
        onChangeClean && onChangeClean(e?.value)
    }
    return (
        <SelectHoc value={value} onChange={forwardChange} options={staticData} {...props} />
    );
};

export default SelectWeekday;