import axios from 'axios';
import Button from 'components/Button';
import Div from 'components/Div';
import Form from 'components/Form';
import React from 'react';
import { AiFillCheckCircle, AiOutlineCheck, AiOutlineCheckCircle, AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';
import { formEditStoreSchedule } from 'utils/formModels';
import { resolveWeekLabel } from 'utils/helpers';

const Weekday = ({ data: firstValue, day, storeName }) => {
    const [instance, setInstance] = React.useState(firstValue)
    const [changes, setChanges] = React.useState(0)
    const [isDisabled, setIsDisabled] = React.useState(true)
    React.useEffect(() => {
        setChanges(changes + 1)
    }, [instance])
    React.useEffect(() => {
        if (changes > 1) setIsDisabled(false)
    }, [changes])
    const handleSubmit = async () => {
        try {
            if (instance.id) {
                await axios.post(`${window.url.server}/store/schedule/update`, {
                    ...instance,
                    redirectEndTimeShift: instance.redirectEndTimeShift ? 1 : 0,
                    endTimeShift: instance.endTimeShift ? 1 : 0,
                    storeName,
                    weekDay: day
                })
            } else {
                await axios.post(`${window.url.server}/store/schedule/create`, {
                    ...instance,
                    redirectEndTimeShift: instance.redirectEndTimeShift ? 1 : 0,
                    endTimeShift: instance.endTimeShift ? 1 : 0,
                    storeName,
                    weekDay: day
                })
            }
        } catch (e) {
        } finally { setChanges(0) }

    }
    const haveRedirectObject = {
        redirectStoreName: { label: 'Шилжүүлэх салбар', type: 'SelectStore' },
        redirectStartTime: { label: 'Шилжүүлэг эхлэх цаг', type: 'SelectTime' },
        redirectEndTime: { label: 'Шилжүүлэг дуусах цаг', type: 'SelectTime' },
        redirectEndTimeShift: { label: 'Шилжүүлэг дараа өдөртөө орох', type: 'Boolean' }
    }
    return (
        <Container>
            <Div c='dayName'>{resolveWeekLabel(day)}</Div>
            <Form styles={formCss} listenChange={setInstance} instance={instance} model={{
                ...formEditStoreSchedule,
                ...instance.haveRedirect ? haveRedirectObject : {}
            }} />
            <Div c="btn-wrap">
                <Button disabled={isDisabled} onClick={handleSubmit} color="green" bg="white"><AiOutlineCheck /></Button>
                {/* <Button disabled={isDisabled} onClick={() => setInstance(firstValueClone)} color="color" bg="white"><AiOutlineClose /></Button> */}
            </Div>
        </Container>
    );
};

export default Weekday;

const Container = styled.div`
    display:flex;
    gap:12px;
    margin-bottom:15px;
    align-items:flex-start;
    .dayName{
        width:150px;
        opacity:0.7;
    }
    .btn-wrap{
        padding-top:4px;
        margin-left:30px;
        display:flex;
        gap:10px;
        button{
            font-size:20px;
            padding:0px;
            border:none;
            border-radius:100%;
            align-items:flex-start;
            &[disabled]{
                opacity:0.3 !important;
            }
        }
    }
`
const formCss = `
    display:flex;
    gap:15px;
    flex-wrap:wrap;
    max-width:560px;
    & > div {
        display:flex;
        flex-direction:column-reverse;
        label{
            opacity:0.4;
            margin-top:4px;
        }
    }
`
