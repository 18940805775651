import { Auth } from 'aws-amplify';
import axios from 'axios';
import configJson from '../config.json';
import errorDetail from './helpers';

const Req = async ({ method = 'post', url, type = 1, hideError, hideSuccess, successMsg, body = {}, disableLoader = true }) => {
    const {
        accessToken: { jwtToken },
    } = await Auth.currentSession();
    return new Promise(async (resolve, reject) => {
        const root = document.querySelector('#root');
        try {
            // START LOADER
            disableLoader && root.dispatchEvent(new CustomEvent('startloader'));
            let urlType = configJson.url.server;
            if (type === 1) urlType = configJson.url.server;
            if (type === 2) urlType = configJson.url.address;
            if (type === 10) urlType = configJson.url.local;
            // const result = await axios[method](urlType + url)
            let axiosObject;
            if (method === 'get') {
                axiosObject = [{ headers: { Authorization: `Bearer ${jwtToken}` } }];
            } else {
                axiosObject = [{ ...body }, { headers: { Authorization: `Bearer ${jwtToken}` } }];
            }
            const result = await axios[method](urlType + url, ...axiosObject);
            resolve(result);
            if (hideSuccess) return;
            root.dispatchEvent(new CustomEvent('toastmessage', { detail: { type: 'success', message: successMsg ?? 'Амжилттай хадгалагдлаа', title: '' } }));
        } catch (e) {
            const error = await errorDetail(e);
            reject(error);
            if (hideError) return;
            root.dispatchEvent(new CustomEvent('toastmessage', { detail: { type: 'error', message: error, title: url } }));
        } finally {
            root.dispatchEvent(new CustomEvent('endloader'));
        }
    });
};

export default Req;
