import Button from 'components/Button';
import Div from 'components/Div';
import React from 'react';
import styled from 'styled-components';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import { getReferenceContext } from 'contexts/reference';
import dayjs from 'dayjs';
import { FormatDateFull } from 'utils/date';
import isBetwen from 'dayjs/plugin/isBetween'
import { formatNumber } from 'utils/vanillaFunctions';
dayjs.extend(isBetwen)

const PizzaPopup = ( {name, data, isCombo, onChange, disableIncrement }) => {
    const [base, setBase] = React.useState(Object.keys(data.pizzaBase)[0])
    const [size, setSize] = React.useState(data.pizzaSize[Object.keys(data.pizzaSize)[0]])
    const [count, setCount] = React.useState(1)
    const [selectedOption, setSelectedOption] = React.useState(null)
    const { menuImages, getTranslation } = React.useContext(getReferenceContext)
    const [imageFound, setImageFound] = React.useState(null)
    React.useEffect(() => {
        const items = [...data.items]
        let foundItems = []

        if(isCombo) {
            items.forEach((item, i) => {
                if(item.masterName === `${size} ${base} ${name}`) {
                    foundItems.push({...item})
                }
            })
        } else {
            items.forEach((item, i) => {
                if(item.pizzaBase === base && item.pizzaSize === size) {
                    foundItems.push({...item})
                }
            })
        }

        const currentDate = dayjs(new Date())
        const tuesday = currentDate.set('day', 2).set('hour', 6).set('minute', 0).set('seconds', 0)
        const wednesday = currentDate.set('day', 3).set('hour', 6).set('minute', 0).set('seconds', 0)

        const isHutday = currentDate.isBetween(tuesday, wednesday)

        let thereIsHutdayPizza = null
        let thereIsNormalPizza = null

        const hutDayHexSelector = "40"
        const normalHexSelector = "80"

        if(isHutday) {
            const lookForHutdayPizza = foundItems.find(p => p.subLevelsHex === hutDayHexSelector)
            if(lookForHutdayPizza) thereIsHutdayPizza = lookForHutdayPizza
        } else {
            foundItems = foundItems.filter(p => p.subLevelsHex !== hutDayHexSelector)
            const lookForNormalPizza = foundItems.find(p => p.subLevelsHex === normalHexSelector)
            if(lookForNormalPizza) thereIsNormalPizza = lookForNormalPizza
        }
        setSelectedOption(thereIsHutdayPizza ?? thereIsNormalPizza ?? foundItems[0])
    }, [base, size])

    React.useEffect(() => {
        const clonedMenuImages = [...menuImages]
        const imageInstance = clonedMenuImages.find(x => x.name === name)
        if(imageInstance) setImageFound({...imageInstance})
    }, [])

    const handleAdd = (e) => {
        e.preventDefault()
        onChange({ ...selectedOption, count })
    }
    
    return (
        <Container>
            {imageFound ? <img className='pizza_img' src={imageFound.url} /> : <img className='pizza_img empty' src='/images/pizza.png' />}
            {selectedOption?.price || selectedOption?.price === 0 ? <Div c="operation">
                <Div c="left_wrap">
                    <Div c="count_wrap">
                        <Button onClick={() => count > 1 && setCount(count - 1)}><AiOutlineMinus /></Button>
                        <Div c="count">{count}</Div>
                        <Button onClick={() => setCount(count + 1)}><AiOutlinePlus /></Button>
                    </Div>
                </Div>
                <Div c="right_wrap">
                    <Div c="total">{selectedOption?.price || selectedOption?.price === 0  ? '₮'+formatNumber(selectedOption.price * count) : ''}</Div>
                </Div>
            </Div> : ''}
            <Div c="choice_title">Хэмжээ</Div>
            <Div c="choice_wrap">
                {Object.keys(data.pizzaSize).map(s => {
                    const o = data.pizzaSize[s]
                    return (
                        <Button onClick={() => setSize(o)} open={size === o} key={o}>{getTranslation(o)}</Button>
                    )
                })}
            </Div>
            <Div c="choice_title">Гурил</Div>
            <Div c="choice_wrap">
                {Object.keys(data.pizzaBase).map(b => (
                    b !== "THN" &&
                    <Button onClick={() => setBase(b)} open={base === b} key={b}>{getTranslation(b)}</Button>
                ))}
            </Div>
            {!selectedOption && <p className='errorLine'>{getTranslation(name)} {base} {size} гарах боломжгүй</p>}
            <Button onClick={handleAdd} disabled={!selectedOption} className="add_to_cart">Сагсанд хийх</Button>
        </Container>
    );
};

export default PizzaPopup;

const Container = styled.div `
    padding:0px;
    max-width:350px;
    min-width:350px;
    margin:auto;
    .pizza_img{
        width:100%;
        margin-bottom:15px;
        display:block;
        &.empty{
            height:150px;
            object-fit:contain;
            opacity:0.3;
            padding:15px;
            border:2px solid rgba(0,0,0,0.1);
        }
    }
    .operation{
        display:flex;
        align-items:center;
        justify-content:space-between;
        font-size:16px;
        margin-bottom:15px;
        .left_wrap{
            .count_wrap{
                border:2px solid rgba(0,0,0,0.1);
                border-radius:4px;
                padding:0px 0px;
                display:flex;
                align-items:center;
                gap:5px;
                button{
                    border:none;
                    color:black;
                }
                .count{
                    font-weight:400;
                    margin-top:3px;
                    user-select:none;
                }
            }
        }
        .right_wrap{
            .total{
                font-weight:300;
                font-size:24px;
                margin-top:2px;
                user-select:none;
            }
        }
    }
    .choice_title{
        margin-top:15px;
        margin-bottom:10px;
        padding-bottom:10px;
        border-bottom:1px solid rgba(0,0,0,0.1);
    }
    .choice_wrap{
        display:flex;
        gap:8px;
        margin-bottom:8px;
        button{
            max-width:80px;
            flex: calc(100% / 3);
            border:2px solid rgba(0,0,0,0.1);
            border-radius:4px;
            padding:8px 8px;
            // font-weight:bold;
            text-align:center;
            user-select:none;
            cursor:pointer;
            color:inherit;
            transition:0.3s ease;
            font-size:12px;
            &[open]{
                // background:rgba(0,0,0,0.3);
                // background:${({theme})=>theme.color};
                // color:white;
                cursor:default;
                border-color:${({theme})=>theme.color};
                color:${({theme})=>theme.color};
                font-weight:bold;
            }
        }
    }
    .add_to_cart{
        margin-top:20px;
        background:${({ theme }) => theme.color};
        width:100%;
        border-radius:4px;
        font-size:14px;
    }
    .errorLine{
        text-align:center;
        color: ${({theme})=>theme.color};
        margin-top:15px;
    }
`