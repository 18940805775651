import Div from 'components/Div';
import { getOrderContext } from 'contexts/order';
import React from 'react';
import styled from 'styled-components';
import { deepEqual, formatNumber } from 'utils/vanillaFunctions';
import { FaCartPlus, FaMapMarkerAlt, FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { AiFillCloseSquare } from 'react-icons/ai';
import { NotificationManager } from 'react-notifications';
import { getAuthContext } from 'contexts/auth';
import errorDetail from 'utils/helpers';
import Req from 'utils/Request';
import { getReferenceContext } from 'contexts/reference';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
const usePrevious = (value) => {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const Cart = () => {
    const [toggled, setToggled] = React.useState(true);
    const { menus, setMenus, checkoutInfo, setCheckoutInfo, address, custPhone } = React.useContext(getOrderContext);
    const { getTranslation } = React.useContext(getReferenceContext);

    const { user } = React.useContext(getAuthContext);
    const [timeoutId, setTimeoutId] = React.useState();
    const [timeoutId2, setTimeoutId2] = React.useState();
    const prevMenus = usePrevious(menus);
    const checkDuplicate = () => {
        const clonedMenu = [...menus];
        const clonedItem = { ...clonedMenu[clonedMenu.length - 1] };
        clonedMenu.pop();
        clonedMenu.forEach((menu, index) => {
            const isEqual = deepEqual({ ...menu, count: null }, { ...clonedItem, count: null });
            if (isEqual) {
                const storeCount = menu.count ?? 1;
                delete menu.count;
                clonedMenu[index] = {
                    ...menu,
                    count: storeCount + 1,
                };
                setMenus(clonedMenu);
            }
        });
    };
    const handleRemove = (index) => {
        const clonedMenu = [...menus];
        clonedMenu.splice(index, 1);
        setMenus(clonedMenu);
    };

    const handlePlus = (index) => {
        const clonedMenu = [...menus];
        clonedMenu[index] = { ...clonedMenu[index], count: (clonedMenu[index].count ?? 1) + 1 };
        setMenus(clonedMenu);
    };

    const handleMinus = (index) => {
        const clonedMenu = [...menus];
        clonedMenu[index] = { ...clonedMenu[index], count: (clonedMenu[index].count ?? 1) - 1 };
        setMenus(clonedMenu);
    };

    const debounce = (fn, delay, timeout, settimeout) => {
        return function (...args) {
            clearTimeout(timeout);
            settimeout(
                setTimeout(() => {
                    fn.apply(this, args);
                }, delay)
            );
        };
    };

    const toastMessage = () => {
        NotificationManager.success('Амжилттай хадгалагдлаа', '', 1000);
    };
    const handleCheckout = async () => {
        const clonedMenu = [...menus];
        clonedMenu.forEach((menu, i) => {
            if (!menu.count) clonedMenu[i].count = 1;
        });
        const orderObject = {
            storeName: address.storeName,
            orderType: address.haveDelivery ? 'delivery' : 'pickup',
            orderDate: address.date,
        };
        if (address.haveDelivery === 'delivery') orderObject.podCode = address.detail.podph;

        let addressObject = null;
        if (address.haveDelivery) {
            addressObject = { ...address.detail };
            addressObject.ortsKod = address.ortsKod;
            addressObject.orts = address.orts;
            addressObject.haalga = address.haalga;
            addressObject.additional = address.additional;
        }
        const custObject = {
            phone: custPhone,
        };
        const bodyObject = { order: { ...orderObject }, cust: { ...custObject } };

        // IF DELIVERY TRUE, INJECT ADDRESS
        if (addressObject) bodyObject.address = { ...addressObject };

        // INJECT CART PRODUCTS MENU
        bodyObject.menus = [...menus];
        try {
            const result = await Req({ url: '/order/checkout', body: bodyObject, hideSuccess: true, hideError: true });
            setCheckoutInfo(result.data.data.totals);
        } catch (e) {
            NotificationManager.warning(e, '');
            setCheckoutInfo(null);
        }
    };
    React.useEffect(() => {
        if (menus.length) {
            checkDuplicate();

            // debounce(toastMessage, 200, timeoutId, setTimeoutId)();
            debounce(handleCheckout, 200, timeoutId2, setTimeoutId2)();
        } else {
            setCheckoutInfo(null);
        }
    }, [menus]);

    return (
        <Container id="cartWrap">
            <Div c="toggle" onClick={() => setToggled(!toggled)}>
                <FaCartPlus />
                <span>Бүтээгдэхүүн</span>
                {toggled ? <FaChevronDown className="chevron" /> : <FaChevronRight className="chevron" />}
            </Div>
            {toggled ? (
                <Div c="content">
                    {menus.map((menu, index) => (
                        <Div key={index}>
                            <Div c="menuWrap">
                                <span className="name">
                                    {menu.type === 'pizza' ? `${getTranslation(menu.pizzaSize)} ${getTranslation(menu.pizzaBase)} ${getTranslation(menu.name)}` : getTranslation(menu.masterName)} (
                                    {menu.count}ш)
                                </span>
                                <div className="right">
                                    <div className="action">
                                        <button disabled={menus[index]?.count === 1} onClick={() => handleMinus(index)}>
                                            <FaMinusCircle size={13} color="#C0C0C0" />
                                        </button>
                                        <span className="count">{menu.count ?? 1}</span>
                                        <button onClick={() => handlePlus(index)}>
                                            <FaPlusCircle size={13} color="#C0C0C0" />
                                        </button>
                                    </div>
                                    <span className="count">
                                        {formatNumber(menu.price * (menu.count ?? 1))}₮
                                        <AiFillCloseSquare onClick={() => handleRemove(index)} />
                                    </span>
                                </div>
                            </Div>
                            <Div c="submenu">
                                {menu.comboMainItems ? (
                                    <span className="name">
                                        -
                                        {menu.comboMainItems.type === 'pizza'
                                            ? `${getTranslation(menu.comboMainItems.pizzaSize)} ${getTranslation(menu.comboMainItems.pizzaBase)} ${getTranslation(menu.comboMainItems.name)}`
                                            : getTranslation(menu.comboMainItems.masterName)}{' '}
                                        ({menu.comboMainItems?.price}₮)
                                    </span>
                                ) : (
                                    ''
                                )}
                                {menu.comboSideItems
                                    ? menu.comboSideItems.map((sideItem, index) => (
                                          <span className="name" key={index}>
                                              {/* -{sideItem.type === 'single' ? } */}-
                                              {sideItem.type === 'pizza'
                                                  ? `${getTranslation(sideItem.pizzaSize)} ${getTranslation(sideItem.pizzaBase)} ${getTranslation(sideItem.name)}`
                                                  : getTranslation(sideItem?.masterName)}{' '}
                                              ({sideItem?.price}₮)
                                          </span>
                                      ))
                                    : ''}
                            </Div>
                        </Div>
                    ))}
                </Div>
            ) : (
                ''
            )}
        </Container>
    );
};

export default Cart;

const Container = styled.div`
    .toggle {
        cursor: pointer;
        background: ${({ theme }) => theme.color};
        color: white;
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 8px 8px;
        font-size: 14px;
        border-radius: 4px;
        .chevron {
            margin-left: auto;
        }
        margin-bottom: 10px;
    }
    .content {
        .menuWrap {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            gap: 10px;
            .name {
                font-weight: 500;
                flex-basis: 110px;
            }
            .right {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-grow: 1;
                .action {
                    display: flex;
                    gap: 5px;
                }
                .count {
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    svg {
                        font-size: 20px;
                        color: ${({ theme }) => theme.color};
                        margin-top: -1px;
                        cursor: pointer;
                    }
                }
            }
        }
        .submenu {
            display: flex;
            flex-direction: column;
            padding-left: 10px;
            margin-bottom: 10px;
            color: gray;
            .name {
                margin-bottom: 2px;
            }
        }
    }
`;
