import React from 'react';
import styled from 'styled-components';

const SwitchButton = ({ options, selected, onChange,disabled = false, ...props }) => {
    return (
        <Container {...props}>
            {options.map(option => <button onClick={() => onChange(option)} disabled = {selected !== option && disabled} open={selected === option} key={option}>{option}</button>)}
        </Container>
    );
};

export default SwitchButton;

const Container = styled.div`
    button{
        padding:10px 20px;
        font-size:14px;
        cursor:pointer;
        border:1px solid rgba(0,0,0,0.1);
        &:first-child{
            border-top-left-radius:4px;
            border-bottom-left-radius:4px;
        }
        &:last-child{
            border-top-right-radius:4px;
            border-bottom-right-radius:4px;
        }
        &[open]{
            background:${({ theme }) => theme.green};
            color:white;
            cursor:default;
        }
    }
`