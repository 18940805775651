import Title from 'components/Title';
import React from 'react';
import styled from 'styled-components';
import { HiChevronDown } from 'react-icons/hi';
import configFile from 'config.json';
import Req from 'utils/Request';

const EnquiryCategory = ({ onChange }) => {
    const [categories, setCategories] = React.useState([])
    const [indices, setIndices] = React.useState('')
    const [categoryObject, setCategoryObject] = React.useState(null)
    React.useEffect(() => {
        fetchCategory()
    }, [])
    const fetchCategory = async () => {
        const result = await Req({url: '/enquiry/category/list', hideSuccess: true})
        setCategories(result.data.data.enquiryCategory.rows)
    }
    React.useEffect(() => {
        resolveIndices()
    }, [indices])
    const resolveIndices = () => {
        if (!indices) return setCategoryObject(null)
        const indexes = indices.split('.')
        if (indexes.length === 2) setCategoryObject({
            category: categories[indexes[0]].name,
            categoryId: categories[indexes[0]].id,
            childCategory: categories[indexes[0]].children[indexes[1]].name,
            childCategoryId: categories[indexes[0]].children[indexes[1]].id,
        })
        else if (indexes.length === 1) setCategoryObject({
            category: categories[indexes[0]].name,
            categoryId: categories[indexes[0]].id,
        })
    }
    React.useEffect(() => {
        onChange(categoryObject)
    }, [categoryObject])
    const handleCollapse = (e) => {
        if (e.target.dataset.children) {
            const res = e.target.parentElement.dataset.open
            if (res === "true") {
                e.target.parentElement.setAttribute('data-open', 'false')
            }
            else {
                e.target.parentElement.setAttribute('data-open', 'true')
            }
        }
    }
    return (
        <Container>
            <Title size={12} btmLine>Төрлүүд</Title>
            <div className='tree_wrap'>
                {categories.map((cat, catIndex) => (
                    <div className='instance_wrap' key={cat.id}>
                        <span data-children={cat.children?.length ? true : false} open={indices === '' + catIndex + ''} onClick={(e) => {
                            // setIndices('' + cat.children ? '' : catIndex + '');
                            setIndices('' + catIndex + '');
                            handleCollapse(e)
                        }}>
                            {cat.children?.length ? <HiChevronDown /> : ''}
                            {cat.name}
                        </span>
                        {cat?.children.map((child, childIndex) => (
                            <div className='instance_wrap child' key={child.id}>
                                <span open={indices === catIndex + '.' + childIndex} onClick={() => setIndices(catIndex + '.' + childIndex)}>
                                    {child.name}
                                </span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </Container>
    );
};

export default EnquiryCategory;

const Container = styled.div`
    width:30%;
    padding-right:30px;
    border-right:1px solid rgba(0,0,0,0.1);
    max-height:70vh;
    overflow:hidden;
    overflow-y:scroll;
    .tree_wrap{
        min-width:285px;
        .instance_wrap{
            span{
                padding:10px 15px;
                display:flex;
                align-items:center;
                cursor:pointer;
                margin:5px 0px;
                svg{
                    margin-right:4px;
                    pointer-events:none;
                }
                &[open]{
                    background:${({ theme }) => theme.green};
                    color:white;
                }
                &[data-children="true"]{
                    cursor:default;
                    margin-left:-15px;
                    cursor:pointer;
                }
            }
        }
        .instance_wrap.child{
            span{
                display:none;
                margin-left:15px;
            }
        }
        .instance_wrap[data-open="true"]{
            .instance_wrap.child{
                span{
                    display:block
                }
            }
        }
    }
`
