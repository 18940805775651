import React from 'react';
import styled from 'styled-components';

const Title = ({ children, ...props }) => {
    return (
        <Container {...props}>
            {children}
        </Container>
    );
};

export default Title;

const Container = styled.p`
    margin:0px;
    margin-bottom:15px;
    ${({size}) => size && `font-size: ${size}px`};
    ${({transform}) => transform && `text-transform: ${transform}`};
    ${({btmLine}) => btmLine && `
        margin-bottom:30px !important;
        padding-bottom:15px;
        border-bottom:1px solid rgba(0,0,0,0.1);
    `};
`