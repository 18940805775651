import SelectHoc from 'contexts/SelectHoc';
import React from 'react';
import Req from 'utils/Request';

const SelectAddress = ({ value: firstValue, city, district, horoo, onChange, onChangeClean, ...props }) => {
    const [address, setAddress] = React.useState('')
    const [data, setData] = React.useState([])
    const [value, setValue] = React.useState({})
    React.useEffect(() => {
        if (address) fetchData()
        else setData([])
    }, [firstValue, district, horoo,city, address])
    React.useEffect(()=> {
        if(firstValue) {
            const detail = JSON.parse(firstValue)
            setAddress(detail.full_address)
        }
    }, [])
    const fetchData = async () => {
        const result = await Req({
            url: `/searchByAddress?city=${city ?? 'УБ'}&district=${district ?? ''}&horoo=${horoo ?? ''}&address=${address}`,
            type: 2,
            hideSuccess: true,
            method: 'get'
        })
        const mappedResult = result.data.data.map(d => ({ value: JSON.stringify(d), label: `${d.full_address} | ${d.bairnote}` }))
        const findSelectedValue = mappedResult.find(m => m.value === firstValue)
        setValue(findSelectedValue)
        setData(mappedResult)
    }
    const forwardChange = (e) => {
        onChange && onChange(e)
        onChangeClean && onChangeClean(e?.value)
    }
    return (
        <div>
            <SelectHoc placeholder="Хайх хаягаа бичнэ үү." options={data} value={value} onChange={forwardChange} inputValue={address} onInputChange={(v) => setAddress(v)} {...props} />
        </div>
    );
};

export default SelectAddress;
