import Div from 'components/Div';
import { getReferenceContext } from 'contexts/reference';
import React from 'react';
import { AiOutlineCaretRight } from 'react-icons/ai';
import styled from 'styled-components';
import { FormatDateFull, FormatDateHourMinuteSecond } from 'utils/date';
import Req from 'utils/Request';
import { formatNumber } from 'utils/vanillaFunctions';
import ProcessLine from './ProcessLine';

const ProcessDetail = ({ processId, setStoreInfo }) => {
    const [detail, setDetail] = React.useState(null);
    const [message, setMessage] = React.useState(null);
    const { getTranslation } = React.useContext(getReferenceContext);
    React.useEffect(() => {
        fetchDetail();
    }, []);
    const fetchDetail = async () => {
        const result = await Req({ url: '/order/getOrderById', body: { id: processId }, hideSuccess: true });
        setDetail(result.data.data);
        setStoreInfo(result.data.data.order.storeName);
        const body = {
            podCode: result.data.data.order.addrPod,
            orderType: result.data.data.order.orderType,
            storeName: result.data.data.order.storeName,
        };
        const resultCheck = await Req({ url: '/store/schedule/check', body, hideSuccess: true });
        setMessage(resultCheck.data.data.quickMessage);
    };
    return (
        <>
            {detail && <ProcessLine detail={detail} />}
            {detail && (
                <Container>
                    <Div c="wrapper">
                        <Div c="instance">
                            <Div c="caption">Захиалга авсан цаг</Div>
                            <Div c="info">{FormatDateHourMinuteSecond(detail.order.createdAt)}</Div>
                        </Div>
                        <Div c="instance">
                            <Div c="caption">Захиалгын хүргэгдэх хугацаа</Div>
                            <Div c="info">{message}</Div>
                        </Div>
                        <Div c="instance">
                            <Div c="caption">Захиалга төрөл</Div>
                            <Div c="info">
                                {detail.order.orderType === 'delivery' ? 'Хүргэлтээр' : 'Очиж авах'}&nbsp;
                                {detail.order.orderDate ? ' - ' + FormatDateFull(detail.order.orderDate) : '(Одоо)'}
                            </Div>
                        </Div>
                        {/* <Div c="instance">
                    <Div c="caption">Захиалга суваг</Div>
                    <Div c="info"></Div>
                </Div> */}
                        {/* <Div c="instance">
                    <Div c="caption">Захиалга өгөх цаг</Div>
                    <Div c="info"></Div>
                </Div> */}
                        <Div c="instance">
                            <Div c="caption">Хүргэх төрөл</Div>
                            <Div c="info">{detail.order.orderType === 'delivery' ? 'Хүргэлтээр' : 'Очиж авах'}</Div>
                        </Div>
                        <Div c="instance">
                            <Div c="caption">Салбар</Div>
                            <Div c="info">{detail.order.storeName}</Div>
                        </Div>
                        {/* <Div c="instance">
                    <Div c="caption">Жолооч Утас</Div>
                    <Div c="info"></Div>
                </Div> */}
                        <Div c="instance">
                            <Div c="caption">Тэмдэглэл</Div>
                            <Div c="info">{detail.order.custNote}</Div>
                        </Div>
                        {detail.order.addrFullAddress ? (
                            <Div c="instance">
                                <Div c="caption">Хаяг</Div>
                                <Div c="info">
                                    {detail.order.addrFullAddress}
                                    <br />
                                    {detail.order.addrDetails?.split(',').map((x) => (
                                        <>
                                            {x}
                                            <br />
                                        </>
                                    ))}
                                </Div>
                            </Div>
                        ) : (
                            ''
                        )}
                        <Div c="instance">
                            <Div c="caption">Утасны дугаар</Div>
                            <Div c="info">{detail.order.custPhone}</Div>
                        </Div>
                        <Div c="instance">
                            <Div c="caption">Нэмэлт утасны дугаар</Div>
                            <Div c="info">{detail.order.custPhone2}</Div>
                        </Div>
                    </Div>
                    <Div c="wrapper">
                        <Div c="instance">
                            <Div c="caption">Дүн</Div>
                            <Div c="info">{formatNumber(detail.order.totalsSubTotal)}₮</Div>
                        </Div>
                        <Div c="instance">
                            <Div c="caption">Хүргэлт</Div>
                            <Div c="info">{formatNumber(detail.order.totalsOtherTotals)}₮</Div>
                        </Div>
                        <Div c="instance">
                            <Div c="caption">Нийт дүн</Div>
                            <Div c="info">{formatNumber(detail.order.totalsTotalDue)}₮</Div>
                        </Div>
                        <Div c="instance">
                            <Div c="caption">НӨАТ</Div>
                            <Div c="info">
                                {detail.order.vatType === 1 && 'Хувь хүн'}
                                {detail.order.vatType === 2 && 'Хувь хүн регистер'}
                                {detail.order.vatType === 3 && 'Байгууллага'}
                            </Div>
                        </Div>
                        {detail.order.vatType === 3 && (
                            <Div c="instance">
                                <Div c="caption">Байгууллага</Div>
                                <Div c="info">{detail.order.vatName}</Div>
                            </Div>
                        )}
                        {detail.order.vatType === 2 && (
                            <Div c="instance">
                                <Div c="caption">Регистр</Div>
                                <Div c="info">{detail.order.vatRegno}</Div>
                            </Div>
                        )}
                        <Div c="instance">
                            <Div c="caption">Бүтээгдэхүүнүүд</Div>
                            <Div c="info">
                                {detail.orderMenu.map((menu) => (
                                    <>
                                        {(!menu.isComboSideItems && !menu.isComboMainItems) && (
                                            <>
                                                <AiOutlineCaretRight /> ({menu.count}ш) -- {getTranslation(menu.masterName)} ({formatNumber(menu.price * menu.count)}₮) 
                                                <br/>
                                            </>
                                        )}
                                        {(menu.isComboSideItems || menu.isComboMainItems )&& (
                                            <div className='sideItems'>
                                                ({menu.count}ш) -- {getTranslation(menu.masterName)}
                                            </div>
                                        )}
                                    </>
                                ))}
                            </Div>
                        </Div>
                    </Div>
                </Container>
            )}
        </>
    );
};

export default ProcessDetail;

const Container = styled.div`
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    & > .wrapper {
        padding: 30px;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 5px;
        &:first-child {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            padding-right: 15px;
            margin-right: 15px;
        }
        & > .instance {
            display: flex;
            gap: 15px;
            & > .caption {
                font-weight: 500;
                text-align: right;
                flex: 1;
                line-height: 28px;
            }
            & > .info {
                flex: 1;
                line-height: 28px;
                text-transform: capitalize;
                svg {
                    color: rgba(0, 0, 0, 0.75);
                }
                .sideItems {
                    margin-left: 15px;
                }
            }
        }
    }
`;
