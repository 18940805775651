import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { getOrderContext } from 'contexts/order';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';
import Req from 'utils/Request';
import Cardbox from '../Cardbox';
import FlexDivider from '../FlexDivider';
import ComboPopup from './ComboPopup';

const Combo = ({data: comboRows}) => {
    const [comboMenuData, setComboMenuData] = React.useState([])
    const [selectedCombo, setSelectedCombo] = React.useState(null)
    const {menus, setMenus, address} = React.useContext(getOrderContext)
    React.useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {
        const res = await Req({url: '/menu/getComboMenus', body: {storeName: address.store ?? address.storeName}, hideSuccess: true})
        const comboDetails = res.data.data
        const storeMatchedCombos = []
        comboRows.forEach(comboRow => {
            const foundCombo = comboDetails.find(d => (d.comboMenuId === comboRow.miMasterObjNum) && d?.active)
            if(foundCombo) {
                storeMatchedCombos.push({
                    ...foundCombo,
                    comboName: comboRow.masterName,
                    price: comboRow.price
                })
            }
        })
        setComboMenuData(storeMatchedCombos)
    }
    const handleAddMenu = () => {
        let isValidProcess = true
        if(!selectedCombo.comboMainItems.selected) {
            NotificationManager.warning('Та үндсэн бүтээгдэхүүн сонгоно уу!', '', 2000)
            isValidProcess = false
        }
        selectedCombo.comboSideItems.forEach(side =>{
            if(!side.selected) {
                NotificationManager.warning(`Та ${side.optionName} бүтээгдэхүүн сонгоно уу!` ,'', 2000)
                isValidProcess = false
            }
        })
        if(isValidProcess) {
            const comboObject = {
                type: "combo",
                count: 1,
                masterName: selectedCombo.masterName,
                comboMenuId: selectedCombo.comboMenuId,
                primaryComboGroupId: selectedCombo.primaryComboGroupId,
                comboMainItems: selectedCombo.comboMainItems.selected,
                comboSideItems: selectedCombo.comboSideItems.map(x => x.selected),
                price: selectedCombo.price
            }
            setMenus([...menus, comboObject])
            setSelectedCombo(null)
        }
    }
    return (
        <Container>
            <FlexDivider>
                {comboMenuData.map((comboMenu, i) => (
                    <Cardbox
                        key={comboMenu.id + '' + i}
                        name={comboMenu.comboName}
                        price={comboMenu.price} img="combo"
                        desc={comboMenu.longDescriptor_stringText}
                        onClick={()=>setSelectedCombo(comboMenu)}
                        >
                        {/* <div>
                            <p><b>-{comboMenu.comboMainItems.type}</b></p>
                            {comboMenu.comboSideItems.map(sideItem =>(
                                <p key={Math.random()}>-{sideItem.optionName}</p>
                            ))}
                        </div> */}
                    </Cardbox>
                ))}
            </FlexDivider>
            {selectedCombo &&
                <Dialog
                    width={'600px'}
                    title={selectedCombo.comboName}
                    submit={<Button bg="green" onClick={handleAddMenu}>Багц нэмэх</Button>}
                    onClose={()=>setSelectedCombo(null)}
                    >
                    <ComboPopup onChange={handleAddMenu} instance={selectedCombo} setSelectedCombo={setSelectedCombo}/>
                </Dialog>
            }
        </Container>
    );
};

export default Combo;

const Container = styled.div `
    width:100%;
`