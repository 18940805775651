import axios from 'axios';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Form from 'components/Form';
import Table from 'components/Table';
import React from 'react';
import styled from 'styled-components';
import { tableStore } from '../../utils/tableModels';
import { formEditStore } from 'utils/formModels';
import { popupFormStyle } from 'utils/sharedStyles';
import Title from 'components/Title';
import { AiOutlineEdit, AiOutlineFieldTime } from 'react-icons/ai';
import { getReferenceContext } from 'contexts/reference';
import Div from 'components/Div';
import Schedule from 'components/Schedule';
import Req from 'utils/Request';
import Tag from 'components/Tag';

const Store = () => {
    const { setIsLoading } = React.useContext(getReferenceContext);
    const [data, setData] = React.useState([]);
    const [selectedInstance, setSelectedInstance] = React.useState(null);
    const [selectedSchedule, setSelectedSchedule] = React.useState(null);
    const formRef = React.useRef();
    React.useEffect(() => {
        fetch();
    }, []);
    const fetch = async () => {
        const result = await Req({ url: '/store/list', hideSuccess: true });
        setData({ rows: result.data.data, count: 0 });
    };
    const handleSubmit = async (e) => {
        e?.preventDefault();
        await Req({ url: '/store/update', body: { storeName: selectedInstance.storeName, quickMessage: selectedInstance.quickMessage } });
        setSelectedInstance(null);
        fetch();
    };
    const fetchScheduleInstance = async ({ storeName }) => {
        try {
            setIsLoading(true);
            const result = await axios.post(`${window.url.server}/store/schedule/findByName`, { storeName });
            console.log(result, 'result');
            setSelectedSchedule({ data: result.data.data ?? [], storeName });
        } catch (e) {
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <Container>
            <Title btmLine size={20}>
                Салбар
            </Title>
            <Table
                data={data}
                model={{
                    ...tableStore,
                    custom: {
                        label: '',
                        sticky: true,
                        jsx: ({ thisInstance }) => (
                            <Div style={{ display: 'flex', gap: 8 }}>
                                <Button color="white" bg="green" onClick={() => setSelectedInstance(thisInstance)}>
                                    <AiOutlineEdit /> Засах
                                </Button>
                                {/* <Button color="white" bg="blue" onClick={() => fetchScheduleInstance(thisInstance)}><AiOutlineFieldTime /> Цагийн хуваарь засах</Button> */}
                            </Div>
                        ),
                    },
                    // haveAdvanced: {
                    //     label: 'Урьдчилж захиалах',
                    //     jsx: ({ thisInstance }) => (
                    //         <Tag color={thisInstance.haveAdvanced ? 'green' : 'red'}>
                    //             {thisInstance.haveAdvanced ? 'Тийм' : 'Үгүй'}
                    //         </Tag>
                    //     )
                    // },
                    // haveDelivery: {
                    //     label: 'Хүргэлт',
                    //     jsx: ({ thisInstance }) => (
                    //         <Tag color={thisInstance.haveDelivery ? 'green' : 'red'}>
                    //             {thisInstance.haveDelivery ? 'Тийм' : 'Үгүй'}
                    //         </Tag>
                    //     )
                    // },
                    // havePickup: {
                    //     label: 'Очиж авах',
                    //     jsx: ({ thisInstance }) => (
                    //         <Tag color={thisInstance.havePickup ? 'green' : 'red'}>
                    //             {thisInstance.havePickup ? 'Тийм' : 'Үгүй'}
                    //         </Tag>
                    //     )
                    // },
                    // isActive: {
                    //     label: 'status',
                    //     jsx: ({ thisInstance }) => (
                    //         <Tag color={thisInstance.isActive ? 'green' : 'red'}>
                    //             {thisInstance.isActive ? 'Тийм' : 'Үгүй'}
                    //         </Tag>
                    //     )
                    // },
                    
                }}
            />
            {selectedInstance && (
                <Dialog
                    title="Салбарын мэдээлэл засах"
                    onClose={() => setSelectedInstance(null)}
                    width="500px"
                    submit={
                        <Button onClick={() => formRef.current.querySelector('.submit').click()} bg="blue" type="submit">
                            Хадгалах
                        </Button>
                    }
                >
                    <Form forwardRef={formRef} model={formEditStore} styles={popupFormStyle} instance={selectedInstance} onSubmit={handleSubmit} listenChange={setSelectedInstance} />
                </Dialog>
            )}
            {selectedSchedule && (
                <Dialog title={`"${selectedSchedule.storeName}" салбарын цагийн хуваарь засах`} onClose={() => setSelectedSchedule(null)} width="500px">
                    <Schedule {...selectedSchedule} />
                </Dialog>
            )}
        </Container>
    );
};

export default Store;

const Container = styled.div``;
