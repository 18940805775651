import React from 'react';
import styled from 'styled-components';

const Main = () => {
    return (
        <Container>
            test
        </Container>
    );
};

export default Main;

const Container = styled.div `
    
`
