import Button from 'components/Button';
import Div from 'components/Div';
import { getOrderContext } from 'contexts/order';
import React from 'react';
import Cardbox from '../Cardbox';
import FlexDivider from '../FlexDivider';

const Product = ({ data, type }) => {
    const {menus, setMenus} = React.useContext(getOrderContext)
    const handleAddMenu = (product) => {
        setMenus([...menus, {...product}])
    }
    return (
        <FlexDivider>
            {data.map((product) => (
                <Cardbox
                    name={product.masterName}
                    price={product.price}
                    key={product.masterName}
                    img={type.toLowerCase()}
                    data={product}
                    onSelect={handleAddMenu}
                    desc={product.longDescriptor_stringText}

                />
            ))}
        </FlexDivider>
    );
};

export default Product;
