import Button from 'components/Button';
import Div from 'components/Div';
import React from 'react';
import styled from 'styled-components';
import { getReferenceContext } from 'contexts/reference';
import {AiFillCheckCircle} from 'react-icons/ai'
import { formatNumber } from 'utils/vanillaFunctions';

const Cardbox = ({ name, price, desc, img, children, onClick, isCombo, checked, onSelect, data }) => {
    const {getTranslation, menuImages} = React.useContext(getReferenceContext)
    const [imageFound, setImageFound] = React.useState(null)
    const handleClick = () =>{
        onClick && onClick()
        if(onSelect) onSelect(data)
    }
    React.useEffect(() => {
        const clonedMenuImages = [...menuImages]
        const imageInstance = clonedMenuImages.find(x => x.name === name)
        if(imageInstance) setImageFound({...imageInstance})
    }, [])
    return (
        <Container className='cardbox' isCombo={isCombo} onClick={handleClick}>
            {imageFound ? <img className='comboImg externalImg' src={imageFound.url}/> : <img className='comboImg' src={`/images/menu-${img}.png`}/>}
            <Div c="name">{getTranslation(name)}</Div>
            {isCombo ? '' : <Div c="price">{formatNumber(price)}₮</Div>}
            {desc}
            {children}
            {isCombo ? '' : <Button tossable onClick={handleClick} bg="color">Сагсанд хийх</Button>}
            {isCombo && checked ? <AiFillCheckCircle className='check'/> : ''}
        </Container>
    );
};

export default Cardbox;

const Container = styled.div `
    padding:15px;
    box-shadow:0 2px 10px rgba(0,0,0,.1);
    border-radius:8px;
    color:rgba(0,0,0,0.5);
    display:flex;
    flex-direction:column;
    gap:15px;
    position:relative;
    ${({isCombo}) => isCombo && `
        transition:0.3s ease;
        &:hover{
            cursor:pointer;
            background:rgba(0,0,0,0.03);
        }
    `};
    .check{
        position:absolute;
        top:15px;
        right:15px;
        font-size:35px;
        background: white;
        border-radius: 50px;
        color: green;
        // color: ${({theme})=>theme.color};
    }
    .comboImg{
        width:100%;
        height:80px;
        object-fit:contain;
        opacity: 0.5;
        padding:10px;
    }
    .externalImg{
        opacity:1;
        object-fit:cover;
        padding:0px;
    }
    .name{
        font-size:14px;
        font-weight:500;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .price{
        font-size:16px;
        font-weight:600;
        color:${({theme})=>theme.color};
    }
    button{
        width:100%;
        margin-top:auto;
    }
`