import RichtextHoc from 'contexts/RichtextHoc';
import React from 'react';

const Richtext = ({ children, required, onChange, onChangeClean, ...props }) => {
    const forwardChange = (e) => {
        onChange && onChange(e)
        onChangeClean && onChangeClean(e)
    }
    return (
        <RichtextHoc onChange={forwardChange} {...props} />
    );
};

export default Richtext;