import Button from 'components/Button';
import Div from 'components/Div';
import Form from 'components/Form';
import Title from 'components/Title';
import React from 'react';
import styled from 'styled-components';
import { AiOutlineSearch, AiFillEdit, AiOutlineEye, AiOutlineCheck } from 'react-icons/ai';
import axios from 'axios';
import { getReferenceContext } from 'contexts/reference';
import Table from 'components/Table';
import { tableOrderRequest } from 'utils/tableModels';
import { NotificationManager } from 'react-notifications';

import Tag from 'components/Tag';
import { getOrderContext } from 'contexts/order';
import { getAuthContext } from 'contexts/auth';
import Req from 'utils/Request';
import Dialog from 'components/Dialog';
import RequestDetail from './RequestDetail';

const SuggestionRequest = () => {
    const { setIsLoading } = React.useContext(getReferenceContext);
    const [data, setData] = React.useState(null);
    const { custPhone } = React.useContext(getOrderContext);
    const [filters, setFilters] = React.useState({
        custPhone: custPhone ?? '',
    });
    const [selectedInstance, setSelectedInstance] = React.useState(null);
    const [formDisabled, setFormDisabled] = React.useState(true);
    const [categoryObject, setCategoryObject] = React.useState(null);
    const formRef = React.useRef();
    const [paginationSetting, setPaginationSetting] = React.useState({
        limit: 10,
        offset: 0,
    });
    const switchCases = ['Шийдвэрлээгүй', 'Шийдвэрлэсэн'];
    const [isSolved, setIsSolved] = React.useState(switchCases[0]);
    const [cancelInstance, setCancelInstance] = React.useState(null);
    const [requestInstance, setRequetInstance] = React.useState(null);

    const { user } = React.useContext(getAuthContext);

    const handleApprove = async () => {
        if (!cancelInstance) return NotificationManager.warning('Цуцлах захиалга сонгоно уу!', '', 3000);
        const body = {
            id: cancelInstance.id,
            employee: user.username,
        };
        const result = await Req({ url: '/order/approveRequest', body });
        if (result.code === 200 && result.success) {
            return NotificationManager.success('Амжилттай хадгаллаа!', '', 3000);
        }
        setCancelInstance(null);
    };
    const handleRequest = async () => {
        if (!requestInstance) return NotificationManager.warning('Цуцлах захиалга сонгоно уу!', '', 3000);
        const body = {
            id: requestInstance.id,
            employee: user.username,
        };
        const result = await Req({ url: '/order/cancelRequest', body });
        if (result.code === 200 && result.success) {
            return NotificationManager.success('Амжилттай хадгаллаа!', '', 3000);
        }
        setRequetInstance(null);
    };

    const handle = () => {
        setCancelInstance(null);
    };
    const fetchData = async () => {
        const body = {};
        const { startDate, endDate, custPhone } = filters;
        if (startDate || endDate || custPhone) {
            if (startDate && endDate && startDate.getTime() > endDate.getTime()) return NotificationManager.warning('Эхлэх огноо дуусах огнооноос их байх боломжгүй', '', 3000);
            if (startDate) body.startDate = startDate;
            if (endDate) body.endDate = endDate;
            if (custPhone) body.custPhone = custPhone;
        }
        const bodyObject = {
            ...body,
            ...paginationSetting,
            sortBy: 'createdAt',
            sortDirection: 'DESC',
        };
        const result = await Req({
            url: '/order/requests',
            body: bodyObject,
            hideSuccess: true,
        });
        let rows = result.data.data.requests.rows;
        rows = result.data.data.requests.rows.filter(item => item.approved === false)
        result.data.data.requests.rows = rows
        setData(result.data.data.requests);
    };
    React.useEffect(() => {
        fetchData();
    }, [paginationSetting, isSolved]);

    return (
        <Container>
            <Title size={20} style={{ marginBottom: 50 }}>
                Захиалга цуцлах хүсэлт
            </Title>
            {/* <SwitchButton selected={isSolved} onChange={setIsSolved} options={switchCases} /> */}
            <Table
                data={data}
                paginationSetting={paginationSetting}
                setPaginationSetting={setPaginationSetting}
                model={{
                    ...tableOrderRequest,
                    custom: {
                        label: '',
                        sticky: true,
                        jsx: ({ thisInstance }) => (
                            <Div style={{ display: 'flex', gap: 15 }}>
                                <Button
                                    bg="blue"
                                    onClick={() => {
                                        setSelectedInstance(thisInstance);
                                        setFormDisabled(true);
                                    }}
                                >
                                    <AiOutlineEye />
                                    Явц харах
                                </Button>
                            </Div>
                        ),
                    },
                    isSolved: {
                        label: 'Төлөв',
                        jsx: ({ thisInstance }) => <Tag color={thisInstance.approved ? 'green' : 'blue'}>{thisInstance.approved ? 'Зөвшөөрсөн' : 'Бүртгэгдсэн'}</Tag>,
                    },
                }}
            />
            {selectedInstance && (
                <Dialog width="80%" title={`Захиалгын дугаар: ${selectedInstance.checkNum}`} onClose={() => setSelectedInstance(null)}>
                    <PopupWrapper>
                    {!selectedInstance.approved &&
                      <Div c="helper_buttons">
                      <Button
                          bg="color"
                          onClick={() => {
                              setCancelInstance({
                                  id: selectedInstance.id,
                              });
                          }}
                      >
                          Захиалга цуцлах
                      </Button>
                      <Button
                          bg="yellow"
                          onClick={() =>
                              setRequetInstance({
                                  id: selectedInstance.id,
                              })
                          }
                      >
                          Цуцлах хүсэлтийг буцаах
                      </Button>
                  </Div>
                    }
                      
                        <RequestDetail processId={selectedInstance.id} />
                    </PopupWrapper>
                </Dialog>
            )}
            {cancelInstance && (
                <Dialog
                    onClose={() => handle()}
                    title={'Энэ захиалгыг цуцлахдаа итгэлтэй байна уу?'}
                    submit={
                        <Button onClick={() => handleApprove()} bg="green">
                            Тийм
                        </Button>
                    }
                >
                    <Container>
                        <Form instance={cancelInstance} listenChange={setCancelInstance} onSubmit={handleApprove} forwardRef={formRef} model={{}} />
                    </Container>
                </Dialog>
            )}
            {requestInstance && (
                <Dialog
                    onClose={() => setRequetInstance(null)}
                    title={'Цуцлах хүсэлтийг буцаахдаа итгэлтэй байна уу?'}
                    submit={
                        <Button onClick={() => handleRequest()} bg="green">
                            Тийм
                        </Button>
                    }
                >
                    <Container>
                        <Form instance={requestInstance} listenChange={setRequetInstance} onSubmit={handleRequest} forwardRef={formRef} model={{}} />
                    </Container>
                </Dialog>
            )}
        </Container>
    );
};

export default SuggestionRequest;

const Container = styled.div`
    .filter_wrap {
        margin-top: 30px;
        display: flex;
        align-items: flex-start;
        gap: 15px;
        margin-bottom: 30px;
    }
    .create_complaint_wrap {
        display: flex;
        & > form {
            flex: 1;
            min-width: 500px;
        }
    }
`;

const PopupWrapper = styled.div`
    .helper_buttons {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        margin-top: 50px;
        margin-bottom: 15px;
    }
`;
