import React from 'react';
import styledComponents from 'styled-components';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { getReferenceContext } from 'contexts/reference';

const Forgot = () => {
  const [email, setEmail] = React.useState('');
  const [authCode, setAuthcode] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const { setIsLoading } = React.useContext(getReferenceContext);
  const [success, setSuccess] = React.useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //   setIsLoading(true);
      const res = await Auth.forgotPassword(email);
      console.log(res, 'res');
      NotificationManager.success(
        'Та мэйл хаягаа шалгана уу.',
        'Амжилттай.',
        3000
      );
      setSuccess(true);
    } catch (e) {
      NotificationManager.error(e.message, 'Error', 3000);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChangePass = async (e) => {
    e.preventDefault();
    if (password !== password2)
      return NotificationManager.warning('Нууц үг таарахгүй байна');
    try {
      await Auth.forgotPasswordSubmit(email, authCode, password);
      NotificationManager.success('Амжилттай шинэчиллээ', 'Амжилттай', 3000);
      navigate('/login');
    } catch (error) {
      NotificationManager.error(error.message, 'Error', 3000);
    }
  };
  //   const handleAuthcode = (code) => {
  //     console.log(code);
  //     setAuthcode(code);
  //   };
  return (
    <Container>
      <img src='/logo.png' />
      <h2>Нууц үг сэргээх</h2>
      {success ? (
        <>
          <input
            required
            value={authCode}
            onChange={(e) => setAuthcode(e.target.value)}
            type='text'
            placeholder='Confirmation code'
            name='code'
          />

          <input
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type='password'
            placeholder='Шинэ нууц үг'
            name='password'
          />
          <input
            required
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            type='password'
            placeholder='Нууц үг давтах'
            name='password2'
          />

          <button onClick={handleChangePass}>Илгээх</button>
        </>
      ) : (
        <>
          <input
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type='text'
            placeholder='И-мэйл хаяг'
            name='email'
          />
          <button onClick={handleSubmit}>Илгээх</button>
        </>
      )}
      <div
        className='or'
        style={{ marginTop: 5, marginBottom: 5, opacity: 0.7, fontSize: 10 }}
      >
        &nbsp;
      </div>
      <button type='button' onClick={() => navigate('/login')}>
        Нэвтрэх
      </button>
    </Container>
  );
};

export default Forgot;

const Container = styledComponents.form`
    display:flex;
    height:100vh;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:200px;
    margin:auto;
    img{
        width:100px;
    }
    input[type="text"],input[type="password"]{
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 8px 12px;
        outline: none;
        transition: all 0.3s ease 0s;
        border-radius: 4px;
        box-sizing: border-box;
        text-overflow: ellipsis;
        margin-bottom:10px;
        width:100%;
        &:focus{
            border-color: rgba(0, 0, 0, 0.8);
            box-shadow: rgba(0 0 0 / 25%) 0px 0px 0px 2px;
        }
    }
    button{
        display:flex;
        align-items:center;
        justify-content:center;
        svg{
            font-size:16px;
            margin-right:5px;
        }
        text-transform:capitalize;
        padding:10px 16px;
        background:${({ theme }) => theme.color};
        color:white;
        border:none;
        border-radius:6px;
        font-weight:500;
        position:relative;
        overflow:hidden;
        cursor:pointer;
        width:100%;
    }
    button[type="button"]{
        background:${({ theme }) => theme.dark};
        margin-top:5px;
    }
`;
