import NewDateHoc from 'contexts/NewDateHoc';
import React from 'react';
import dayjs from 'dayjs';

const SelectDate = ({ value: firstValue, onChange, onChangeClean, ...props }) => {
    const [value, setValue] = React.useState(firstValue ? dayjs(firstValue) : null)
    const forwardChange = (value, dateString) => {
        onChange && onChange(value?.$d ?? '')
        onChangeClean && onChangeClean(value?.$d ?? '')
        setValue(value ? dayjs(value.$d) : '')
    }
    // React.useEffect(() => {
    //     console.log(value, 'value')
    //     if(!value) setValue(dayjs(new Date()))
    // }, [value])
    return (
        <NewDateHoc
            value={value}
            onChange={forwardChange}
            format="YYYY-MM-DD"
            {...props}
        />
    );
};

export default SelectDate;