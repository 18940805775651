import React from 'react';
import styled from 'styled-components';

const Paper = ({ children, ...props }) => {
    return (
        <Container {...props}>
            {children}
        </Container>
    );
};

export default Paper;

const Container = styled.div`
    padding:30px;
    box-shadow:0 2px 12px 0 rgb(0 0 0 / 10%);
    // border:1px solid #ddd;
    margin-top:30px;
    margin-bottom:30px;
`