export const tableProcess = {
    order_number: {
        label: 'Захиалгын дугаар',
        columnFilter: true,
        columnOrder: true,
    },
    branch: { label: 'Салбар' },
    address: { label: 'Хаяг', columnFilter: true },
    channel: { label: 'Суваг' },
    phone: { label: 'Утас', columnOrder: true, columnFilter: true },
    payTypeText: { label: 'Төлбөрийн төрөл' },
    is_paid_text: { label: 'Урьдчилж төлсөн эсэх' },
    invoice_number: { label: 'Гүйлгээний утга' },
    order_status_text: { label: 'Төлөв' },
    operator: { label: 'Оператор' },
    created_at: { label: 'Огноо', sticky: true },
};
export const tableEditAddress = {
    city: { label: 'Хот' },
    district: { label: 'Дүүрэг', columnFilter: true },
    horoo: { label: 'Хороо' },
    bairname: { label: 'Хаяг' },
    podph: { label: 'Под PH' },
};
export const tableComplaint = {
    orderNumber: { label: 'Д/Д' },
    category: { label: 'Төрөл' },
    custComplaint: { label: 'Гомдол' },
    isSolved: { label: 'Шийдвэрлэсэн эсэх' },
    createdAt: { label: 'Огноо', type: 'Datetime' },
    custPhone: { label: 'Утасны дугаар' },
    store: { label: 'Салбар' },
    resolvedNote: { label: 'Шийдвэрлэсэн тайлбар' },
    operator: { label: 'Оператор' },
    resolvedAt: { label: 'Шийдвэрлэсэн огноо', type: 'Datetime' },
};
export const tableEnquiry = {
    category: { label: 'Ангилал' },
    operator: { label: 'Оператор' },
    isSolved: { label: 'Шийдвэрлэсэн эсэх', type: 'Tag' },
};
export const tableSuggestion = {
    category: { label: 'Төрөл' },
    createdAt: { label: 'Огноо', type: 'Datetime' },
    custPhone: { label: 'Утасны дугаар' },
    store: { label: 'Салбар' },
    operator: { label: 'Оператор' },
    isSolved: { label: 'Төлөв' },
};
export const tableStore = {
    storeName: { label: 'Дэлгүүрийн нэр' },
    // haveAdvanced: { label: 'Урьдчилж захиалах' },
    // haveDelivery: { label: 'Хүргэлт' },
    // havePickup: { label: 'Очиж авах' },
    // minDeliveryAmount: { label: 'Хамгийн бага хүргэлтийн дүн' },
    // minPickupAmount: { label: 'Хамгийн бага захиалгын дүн' },
    // freeDelivery: { label: 'Үнэгүй хүргэлттэй эсэх' },
    // isActive: { label: 'status' },
    quickMessage: { label: 'quickmessage' },
    // created_at: { label: 'created_at' },
    // updated_at: { label: 'updated_at' },
};
export const tableStorePod = {
    podCode: { label: 'podcode' },
    storeName: { label: 'storename' },
    isActive: { label: 'is_active' },
    created_at: { label: 'created_at' },
    updated_at: { label: 'updated_at' },
};

export const tableProcessNew = {
    // checkSeq: { label: 'Д/Д' },
    createdAt: { label: 'Огноо', type: 'Datetime' },
    actionText: { label: 'Статус', type: 'Tag' },
    checkNum: { label: 'Захиалгын дугаар' },
    totalsTotalDue: { label: 'Үнийн дүн' },
    custPhone: { label: 'Утас' },
    custPhone2: { label: 'Утас 2' },
    addrFullAddress: { label: 'Хаяг' },
    operator: { label: 'Оператор' },
    storeName: { label: 'Салбар' },
};
export const tableOrderStatuses = {
    checkSeq: { label: 'Д/Д' },
    custPhone: { label: 'Утас' },
    checkNum: { label: 'Захиалгын дугаар' },
    storeName: { label: 'Салбар' },
    addrFullAddress: { label: 'Хаяг' },
    orderType: { label: 'Захиалгын төрөл' },
    operator: { label: 'Оператор' },
    actionText: { label: 'Статус', type: 'Tag' },
    totalsTotalDue: { label: 'Үнийн дүн' },
    createdAt: { label: 'Огноо', type: 'Datetime' },
};

export const tableOrderRequest = {
    checkSeq: { label: 'Д/Д' },
    checkNum: { label: 'Захиалгын дугаар' },
    custPhone: { label: 'Утасны дугаар' },
    orderType: { label: 'Захиалыгн төрөл' },
    createdAt: { label: 'Огноо', type: 'Datetime' },
    storeName: { label: 'Салбар' },
    addrDistrict: { label: 'Хаяг' },
    totalsTotalDue: { label: 'Үнийн дүн' },
};
