import React from 'react';
import styled from 'styled-components';

const Tag = ({ children, ...props }) => {
    return (
        <Container {...props}>
            {children}
        </Container>
    );
};

export default Tag;

const Container = styled.div`
    padding:0px 16px;
    border-radius:4px;
    background: ${({color, theme}) => theme[color] ?? color}20;
    text-align:center;
    border:1px solid rgba(0,0,0,0.1);
    color:${({color, theme}) => theme[color] ?? color};
    user-select:none;
    width:fit-content;
`